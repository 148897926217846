import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/service/login/login.service';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MensajeService {
  private headers: HttpHeaders;

  constructor(private httpApi: HttpClient, private apiAuth: LoginService) { }
  getMensaje():Observable<any>{
    return this.httpApi.get<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/mensajes`,{headers: this.headers})
    .pipe(map(data => data));
  }

  postMensaje(tipoMensaje,descrip,usuario):Observable<any>{
    let httpParams = new HttpParams()
    .append('tipoMensaje', tipoMensaje)
    .append('descri', descrip)
    .append('usuario', usuario);
    return this.httpApi.post<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/mensajes?${httpParams}`,httpParams);
  }
  putMensaje(idMensaje, tipoMensaje, descrip):Observable<any>{
    let httpParams = new HttpParams()
    .append('idMensaje', idMensaje)
    .append('tipoMensaje',tipoMensaje)
    .append('descri',descrip)
    return this.httpApi.put<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/mensajes?${httpParams}`,httpParams);
  }

  deleteMensaje(idMensaje: string):Observable<any>{
    let httpParams = new HttpParams()
    .append('idMensaje', idMensaje);
    return this.httpApi.delete<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/mensajes?${httpParams}`)
  }
}
