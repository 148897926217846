import { Component, OnInit,Input } from '@angular/core';
import { ParadorPensionService, ParadPen} from 'src/app/service/parador_pension/parador-pension.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

import { CanTokenService } from 'src/app/service/guards/can-token.service';

declare var $: any;
declare const jQuery: any;

@Component({
  selector: 'app-parador-pension',
  templateUrl: './parador-pension.component.html',
  styleUrls: ['./parador-pension.component.css']
})
export class ParadorPensionComponent implements OnInit {

  catalogoParaPens:ParadPen[];
  cols:any = [];
  matchModeOptions:any = [];
  tipoParaPen:string=''
  zona:string='';
  nombre: string = '';
  latitud: string= '';
  longitud:string = '';
  modalTitle: string = '';
  idPara_Pen: any='';
  zonaTipo:any = '';
  lat:any='';
  long:any= '';
  nomPara_Pen:any= '';
  datosPara_Pen:any='';
  latitud_Pos:string='';
  longitud_Pos:string='';
  url:any;
  columnDefs:any = [];
  rowData:any;
  dato:any;
  nombreCol:any;
  gridColumnApi:any;
  defaultColDef:any;
  rowSelection:any;
  style = {
    width: '95%',
    height: '100%',
    flex: '1 1 auto'
  };
  id_usuario:string = '';
  filtro_tipo:any = [];
  ageType: any = '';

  constructor(private spinnerService: NgxSpinnerService, private apiParadores: ParadorPensionService, private generalService: CanTokenService) { 
    window.scroll(0, 0);
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      width: 150,
      filter: true,
      floatingFilter: true,
    };
    this.rowSelection = 'multiple';
  }

  @Input() navbar: boolean = false;
  ngOnInit(): void {
    this.id_usuario = localStorage.getItem('id_usuario')
    this.getParaPen();
  }

  getParaPen(){
    this.spinnerService.show();
    this.apiParadores.getParadores().subscribe(datos=>{
      this.columnDefs = [
        {field: "tipo", headerName:"TIPO", sortable: true, width: 280,filter: 'agTextColumnFilter',floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: "zona", headerName:"ZONA", sortable: true, width: 350, filter: 'agTextColumnFilter',floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: "nombre", headerName:"NOMBRE", sortable: true, width: 350,filter: 'agTextColumnFilter', floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: "activo", headerName: "ESTADO", sortable: true, width: 250, floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: 'editar', width: 100, filter: false, cellRenderer: function (params) { return '<i class="far fa-edit" style="color:#BF343F;"></i>' } },
        {field: 'eliminar', width: 120, filter: false, cellRenderer: function (params) { return '<i class="fas fa-minus-circle" style="color:#BF343F;"></i>' } }
      ]
      this.catalogoParaPens = datos;
      this.catalogoParaPens.forEach(datos =>{
        if (datos['activo'] == 1) {
          datos['activo'] =  'ACTIVO'
        }
      })
      this.rowData = this.catalogoParaPens;
      this.spinnerService.hide();
    });
  }
  setWidthAndHeight(width, height) {
    this.style = {
      flex: '20px',
      width: width,
      height: height
    };
  }
  onGridReady(params) {
    this.gridColumnApi = params.columnApi;
    var columnState = JSON.parse(localStorage.getItem('myColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }
  onColumnMoved(e) {
    let columnState = JSON.stringify(e.columnApi.getColumnState());
    localStorage.setItem('myColumnState', columnState);
  }

  onColumnVisible(e) {
    console.log('Event Column Visible', e);
  }
  onCellClicked(e) {
    this.dato = e['data'];
    this.nombreCol = e['colDef']['field'];
    if (this.nombreCol == 'editar') {
      this.openModal(this.dato, 'edit');
      jQuery.noConflict();
      $('#mdl_agregar').modal('show');
      $('#mdl_agregar').draggable({
        handle: ".modal-header"
      });
    }
    if (this.nombreCol == 'eliminar') {
      this.deletPara_Pen(this.dato['id_parador_pension'])
    }
    if (this.nombreCol == 'zona'|| this.nombreCol == 'tipo' || this.nombreCol == 'nombre' ) {
      this.detailPara_Pen(this.dato)
    }
  }
  openModal(parapen,action){
    if(action == 'edit'){
      this.modalTitle = 'EDITAR';
      this.tipoParaPen = parapen.tipo;
      this.idPara_Pen = parapen.id_parador_pension;
      this.nombre = parapen.nombre;
      this.zona = parapen.zona;
      this.latitud = parapen.latitud;
      this.longitud = parapen.longitud;
    }else{
      this.modalTitle = 'PARADORES Y PENSIONES'
      this.tipoParaPen = " ";
      this.nombre = " ";
      this.zona = " ";
      this.latitud = " ";
      this.longitud = " ";
      let idUsuario = this.id_usuario
      let idCompania = '2';
    }
  }
  saveParaPen(){
    if (this.modalTitle == 'EDITAR') {
      let idPara_Pen = this.idPara_Pen;
      let nombre = this.nombre.toUpperCase()
      let zona = this.zona.toUpperCase();
      let latitud = this.latitud;
      let longitud = this.longitud;
      this.apiParadores.putParadores(idPara_Pen,nombre,zona,latitud,longitud).subscribe({
        next: update =>{
          if (update == null) {
            jQuery.noConflict();
            $('#mdl_agregar').modal('hide');
            this.alertParaPen(''+this.tipoParaPen+' creado','Datos guardados correctamente','success')
          }
        },
        error: error => {
          if (localStorage.getItem('token')) {
            if (error.status == 401) this.generalService.refreshToken();
          }
          if(error.status != 401){
            jQuery.noConflict();
            $('#mdl_agregar').modal('hide');
            this.alertParaPen('ERROR','Error al guardar los datos','error')
          }
        }
      });
    }else{
      let nombre = this.nombre.toUpperCase();
      let zona = this.zona.toUpperCase();
      let latitud = this.latitud;
      let longitud = this.longitud;
      let tipo = this.tipoParaPen.toUpperCase();
      let idUsuario = this.id_usuario
      this.apiParadores.postParadores(nombre, zona, latitud, longitud, tipo, idUsuario).subscribe({
        next:para_pen =>{
          if (para_pen['id_nuevo_parador'] != '') {
            jQuery.noConflict();
            $('#mdl_agregar').modal('hide');
            this.alertParaPen(''+this.tipoParaPen+' creado','Datos guardados correctamente','success')
          }
        },
        error: error => {
          if (localStorage.getItem('token')) {
            if (error.status == 401) this.generalService.refreshToken();
          }
          if(error.status != 401){
            jQuery.noConflict();
            $('#mdl_agregar').modal('hide');
            this.alertParaPen('ERROR','Error al guardar los datos','error')
          }
        }
      })
    }
  } 
  detailPara_Pen(detalle:any){
    this.datosPara_Pen = detalle;
    jQuery.noConflict();
    $('#mdl_parad_pen').modal('show');
    $('#mdl_parad_pen').draggable({
      handle: ".modal-header"
    });
  }
  deletPara_Pen(id: any) {
    Swal.fire({
      title: '¿Desea dar de baja el parador/pension?',
      showCancelButton: true,
      confirmButtonText: `SI`,
      confirmButtonColor: '#004680',
      cancelButtonText: 'CANCELAR',
      cancelButtonColor: ' #BF343F'
    }).then((result) => {
      if (result.isConfirmed) {
        this.apiParadores.deleteParadores(id).subscribe(borrar => {
          if(borrar == null){
            this.alertParaPen('BAJA EXITOSA', '', 'success')
          }else{
            this.alertParaPen('ERROR', 'Error al actualizar los datos', 'error')
          }
        })
      }
    })
  }
  posicion(){
    this.latitud_Pos = this.datosPara_Pen.latitud;
    this.longitud_Pos = this.datosPara_Pen.longitud;
    this.url ='https://www.google.com/maps/search/?api=1&query='+this.latitud_Pos+','+this.longitud_Pos
  }

  alertParaPen(title, text, icon) {
    Swal.fire({
      title: title,
      icon: icon,
      text: text,
      customClass: {
        confirmButton: 'btn btn-success',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.getParaPen();
      }
    });
  }

  filtroTipoParaPen(){
    let filtro = {};
    this.filtro_tipo = []
    $(document).ready(function () {
      $('.dropdown-menu').on('click', function (e) {
        e.stopPropagation();
      });
    });
    if ($('#dp_pension').prop('checked') == true) {
      filtro = filtro === {} ? {tipo: 'PENSION'} :this.filtro_tipo.push({tipo: 'PENSION'})
    }
    if ($('#dp_parador').prop('checked') == true) {
      filtro = filtro === {} ? {tipo: 'PARADOR'} : this.filtro_tipo.push({tipo: 'PARADOR'})
    }
    this.ageType = this.filtro_tipo;
    this.filtro(this.ageType)
  }

  filtro(datos){
    let paraPenFiltro = [];
    let paraPen = datos;
    let cont = 0;
    let tipoParaPen = '';
    let totalParaPen = paraPen.length;
     for (cont; cont < totalParaPen; cont++) {
       let flota = paraPen[cont];
       tipoParaPen = flota.tipo;
       this.rowData = [];
       this.catalogoParaPens.forEach(datos => {
        if (datos.tipo == tipoParaPen) {
          paraPenFiltro.push(datos);
        }
      });
     }
     if (paraPenFiltro.length == 0) {
       this.rowData = this.catalogoParaPens;
     }else{
       this.rowData = paraPenFiltro; 
     }
  }
}
