<header>
    <!-- navbar -->
    <nav class="navbar navbar-expand-lg fixed-top bg-light flex-lg-nowrap p-1 border-1">
        <button class="navbar-toggler bg-secondary" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fal fa-bars color-white"></i>
        </button>
        <div class="collapse navbar-collapse" [ngClass]="{'pl-menu': !bandera}" id="navbarNav">
            <!-- <div class="col-md-2">
                &nbsp;
            </div> -->
            <ul class="nav navbar-nav">
                <li class="nav-item">
                    <a class="nav-link color-a" (click)="comportamientoNav()" style="cursor: pointer;" ><i class="fas fa-bars"></i></a>
                </li>
            </ul>
            <h3 class="mx-auto font-weight-bold">{{app}}</h3>
            <ul class="nav navbar-nav">
                <li class="nav-item">
                    <a class="nav-link color-a" href="#"><i class="fas fa-bell"></i></a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle color-a" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-user">{{usuario}}</i> &nbsp;
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                        <a class="dropdown-item" href="#">Perfil</a>
                    </div>
                </li>
                <li class="nav-item">
                    <a class="nav-link color-a" (click)="salir()" style="cursor: pointer;"><i class="far fa-power-off"></i>&nbsp;Salir</a>
                </li>
            </ul>
        </div>
    </nav>
</header>