import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroAccBloqueo'
})
export class FiltroAccBloqueoPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    const resultadoTractor = [];
    for(const info of value){
      if(info.no_cdc.indexOf(arg) > -1){
        resultadoTractor.push(info);
      };
    };
    return resultadoTractor;
  }

}
