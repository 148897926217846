<div class="row">
    <!--TOOLBAR-->
    <div class="form-inline col-12">
        <div class="input-group input-group-sm col-2 float-left">
            <button type="submit" class="btn btn-primary" (click)="generarReporte()">EXPORTAR REPORTE</button>
        </div>
    </div>
    <!--/TOOLBAR-->
</div>
<div class="col-12 mt-5">
    <div [ngStyle]="style">
        <ag-grid-angular #agGrid_mensaje class="ag-theme-alpine"
            [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowData]="rowData"
            [sideBar]="false"
            (columnMoved)="onColumnMoved($event)" (gridReady)="onGridReady($event)" (rowClicked)="onRowClicked($event)">
        </ag-grid-angular>
    </div>
</div>
<app-form-incidencia [informacionReporteIncidencia]="informacionReporteIncidencia" [accion]="accion" *ngIf="mostrarFormularioIncidencia == true" (ocultarModal)="mostrarFormularioIncidencia = false;"></app-form-incidencia>