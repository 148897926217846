import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/service/login/login.service';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReporteService {
  private headers: HttpHeaders;

  constructor(private httpApi: HttpClient, private apiAuth: LoginService) { }

  getReporte():Observable<any>{
    return this.httpApi.get<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/reportes`)
    .pipe(map(data => data));
  }

  postReporte(body):Observable<any>{
    return this.httpApi.post<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/reportes`, body)
    .pipe(map(data => data));
  }
  putReporte(idRepAccBloq,asunto, descripcion,enlace_externo, entre_punto1, entre_punto2, id_ciudad_punto1,id_estado_punto1,id_pais_punto1, id_ciudad_punto2,id_estado_punto2,id_pais_punto2,notificacion_area, unidad_inv, operador, status): Observable<any>{
    let httpParams = new HttpParams()
    .append('idRepAccBloq', idRepAccBloq)
    .append('asunto', asunto)
    .append('descripcion', descripcion)
    .append('enlace_externo', enlace_externo)
    .append('entre_punto1', entre_punto1)
    .append('entre_punto2', entre_punto2)
    .append('id_ciudad_punto1', id_ciudad_punto1)
    .append('id_estado_punto1', id_estado_punto1)
    .append('id_pais_punto1', id_pais_punto1)
    .append('id_ciudad_punto2', id_ciudad_punto2)
    .append('id_estado_punto2', id_estado_punto2)
    .append('id_pais_punto2', id_pais_punto2)
    .append('notificacion_area', notificacion_area)
    .append('unidad', unidad_inv)
    .append('id_empleado', operador)
    .append('ind_estado', status)
    return this.httpApi.put<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/reportes?${httpParams}`, httpParams)
    .pipe(map( data => data));
  }

  deleteReporte(id:string): Observable<any>{
    let httpParams = new HttpParams()
    .append('idRepAccBloq',id);
    return this.httpApi.delete<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/reportes?${httpParams}`)
    .pipe(map(data => data));
  }
  getCiudad(): Observable<any>{
    return this.httpApi.get<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/estado`)
    .pipe(map(data => data));
  }
}
