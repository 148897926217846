import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/service/login/login.service';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ViajeService {
  private headers: HttpHeaders;

  constructor(private httpApi: HttpClient, private apiAuth: LoginService) {}

  getUsuario(usid){
    return this.httpApi.get<any>(`${environment.URL_SERVIDOR}/api-interna/administracion/usuarios/${usid}`)
    .pipe(map(data => data))
  }

  getViajes(body:any): Observable<any>{
    return this.httpApi.post<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/viajes`, body)
    .pipe(map(data => data));
  }
  getParada(viaje:string,cia:string,cveParada:string): Observable<any>{
    let httpParams =  new HttpParams()
    .append('noPedido', viaje)
    .append('paradInter', cveParada)
    .append('cveCia', cia);
    return this.httpApi.get<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/paradas?${httpParams}`)
    .pipe(map(data => data));
  }

  postActualizarRegistrosViaje(body): Observable<any>{
    return this.httpApi.post<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/actualizarRegistro`,body).
    pipe(map(data=>data));
  }

  obtenerDetalleViaje(viaje,cia): Observable<any>{
    let httpParams = new HttpParams()
    .append('numeroViaje', viaje)
    .append('cia', cia)
    return this.httpApi.post<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/detalleViaje`,httpParams).
    pipe(map(data=>data));
  }

  obtenerFlotas(): Observable<any>{
    return this.httpApi.get<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/flotas`).
    pipe(map(data=>data));
  }

  obtenerViajesTransfrontera():Observable<any> {
    return this.httpApi.get<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/viajes-trans`).
    pipe(map(data=>data));
  }
}
