import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/service/login/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import { CanTokenService } from 'src/app/service/guards/can-token.service';
import { ViajeService } from 'src/app/service/viaje/viaje.service'; 

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  usuario : string = '';
  bandera: boolean = false;
  usid='';
  @Input() app: string;
  @Output() menu = new EventEmitter();

  constructor(
    private cookieService: CookieService,
    private generalService: CanTokenService,
    private apiViajes: ViajeService) {
   }

  ngOnInit() {
    const USID = this.cookieService.get('USID') || this.usid
    this.apiViajes.getUsuario(USID).subscribe(datos =>{
      localStorage.setItem('id_usuario', datos.id_usuario)
      this.usuario = localStorage.getItem('id_usuario').toUpperCase();
    });
  }
  salir() {
    localStorage.clear();
    this.cookieService.delete('USID');
    window.location.href = environment.loginUrl;
  }

  comportamientoNav(){
    this.bandera = !this.bandera;
    this.menu.emit(this.bandera);
  }

}
