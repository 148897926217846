<main role="main" class="col-12" [ngClass]="{'pl-menu': !navbar}">
    <h5 class="mt-2">ALERTAS</h5>
    <div class="row">
        <!--TOOLBAR-->
        <div class="form-inline  col-12">
            <div class="dropdown">
                <button class="btn frio-btn-toolbar dropdown-toggle btn-sm mr-2" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    FLOTA REFRIGERADO
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_flota1_ref"
                                (change)="filtroflotaAlerta()">
                            <label class="custom-control-label" for="dp_flota1_ref">FLOTILLA (1) RAFAEL ROBLES</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_flota2_ref"
                                (change)="filtroflotaAlerta()">
                            <label class="custom-control-label" for="dp_flota2_ref">FLOTILLA (2) GERARDO QUEZADA</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_flota3_ref"
                                (change)="filtroflotaAlerta()">
                            <label class="custom-control-label" for="dp_flota3_ref">FLOTILLA (3) SALVADOR
                                MARTINEZ</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_flota4_ref"
                                (change)="filtroflotaAlerta()">
                            <label class="custom-control-label" for="dp_flota4_ref">FLOTILLA (4) GERARDO
                                GUERRERO</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_flota5_ref"
                                (change)="filtroflotaAlerta()">
                            <label class="custom-control-label" for="dp_flota5_ref">FLOTILLA (5) MIGUEL DAVILA</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_flota6_ref"
                                (change)="filtroflotaAlerta()">
                            <label class="custom-control-label" for="dp_flota6_ref">FLOTILLA (6) JULIO CESAR
                                LARA</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_flota7_ref"
                                (change)="filtroflotaAlerta()">
                            <label class="custom-control-label" for="dp_flota7_ref">FLOTILLA (7) FRANCISCO LOPEZ</label>
                        </div>
                    </a>
                </div>
            </div>
            <div class="dropdown">
                <button class="btn frio-btn-toolbar dropdown-toggle btn-sm mr-2" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    FLOTA SECO
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_flota1_sec"
                                (change)="filtroflotaAlerta()">
                            <label class="custom-control-label" for="dp_flota1_sec">FLOTILLA (11) ENRIQUE
                                TALAMANT</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_flota2_sec"
                                (change)="filtroflotaAlerta()">
                            <label class="custom-control-label" for="dp_flota2_sec">FLOTILLA (12) EDGAR PADILLA</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_flota3_sec"
                                (change)="filtroflotaAlerta()">
                            <label class="custom-control-label" for="dp_flota3_sec">FLOTILLA (13) EDGAR DE LUNA</label>
                        </div>
                    </a>
                </div>
            </div>
            <button type="button" class="btn btn-outline-secondary" data-toggle="modal"
                data-target="#alertaAtendidas">Alertas Atendidas <i class="far fa-file-excel"></i></button>
        </div>
        <!--/TOOLBAR-->
    </div>
    <div class="col-12 mt-5">
        <div>
            <ag-grid-angular #agGrid_alerta  domLayout='autoHeight' class="ag-theme-alpine"  [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef" [rowData]="rowData" (rowClicked)="onRowClicked($event)"
                [sideBar]="false" (columnMoved)="onColumnMoved($event)" (gridReady)="onGridReady($event)"
                domLayout='autoHeight'
                [pagination]="true"
                [paginationPageSize]="13">
            </ag-grid-angular>
        </div>
    </div>
</main>
<div class=" fade modal-alert" id="mdl_alerta_viaje" tabindex="-1" role="dialog" aria-labelledby="#mdl_alerta_viaje"
    aria-hidden="true" data-backdrop="static" style="z-index: 10000;">
    <div class="modal-dialog" role="document" style="width:390px;">
        <div class="modal-content">
            <div class="modal-header bg-primary d-block">
                <button type="button" class="close color-white float-right" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title color-white text-center">ALERTA</h5>
            </div>
            <div class="modal-body" [ngStyle]="styleAlert">
                <div class="container-fluid">
                    <form>
                        <div class="form-group row">
                            <label class="label-alerta">TIPO:</label>
                            <input id="txt_tipo_alerta" type="text" class="form-control form-control-sm text-uppercase"
                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                [(ngModel)]="tipo_alerta" name="tipo_alerta" disabled>
                        </div>

                        <div class="form-group row">
                            <label class="label-alerta">UNIDAD:</label>
                            <input id="txt_no_tracto" type="text" class="form-control form-control-sm"
                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                [(ngModel)]="unidad_alerta" name="unidad_alerta">
                        </div>
                        <div class="form-group row">
                            <label class="label-alerta">FLOTA:</label>
                            <input id="txt_flota" type="text" class="form-control form-control-sm" aria-label="Username"
                                aria-describedby="basic-addon1" autocomplete="off" [(ngModel)]="flota_alerta"
                                name="flota_alerta">
                        </div>
                        <div class="form-group row">
                            <label class="label-alerta">OPERADOR:</label>
                            <input id="txt_operador" type="text" class="form-control form-control-sm"
                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                [(ngModel)]="operador_alerta" name="operador_alerta">
                        </div>
                        <div class="form-group">
                            <label class="label-alerta row">UBICACION:</label>
                            <div class="row form-inline">
                                <input id="txt_ubicacion" type="text" class="form-control form-control-sm col-8"
                                    aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                    [(ngModel)]="ubicacion" name="ubicacion" disabled>
                                <div class="input-group input-group-sm col-sm">
                                    <a href="{{this.url}}" target="_blank"><button type="button" class="btn btn-light"
                                            (click)="posicion()"><i class="fas fa-map-marker-alt"
                                                style="color:#BF343F;"></i>
                                            MAPA</button></a>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <label class="label-alerta">APLICA SANCIÓN: </label>
                            <div class="input-group col-sm-4 input-group-sm">
                                <select class="custom-select" [(ngModel)]="aplica_sancion" name="aplica_sancion">
                                    <option *ngFor="let apli_sancion of sancion" value="{{apli_sancion.valor}}">
                                        {{apli_sancion.aplica}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="aplica_sancion == 'S'">
                            <label class="label-alerta">MOTIVO SANCIÓN:</label>
                            <select class="custom-select form-control-sm" id="inputGroupSelect01"
                                [(ngModel)]="motivo_sancion" name="motivo_sancion"
                                (change)="obtenerIdSancion(motivo_sancion)">
                                <option *ngFor="let sanciones of lista_sanciones">{{sanciones.nombre}}</option>
                            </select>
                        </div>
                        <div class="row" *ngIf="aplica_sancion == 'S'">
                            <label class="label-alerta">NOTIFICAR A: </label>
                        </div>
                        <div class="col-12 row" *ngIf="aplica_sancion == 'S'" style="margin: 0px; padding: 0vh;">
                            <div class="row">
                                <div class="form-group form-check-inline form-control-sm">
                                    <input type="checkbox" class="form-check-input" id="ch_operaciones"
                                        (change)="asignarCorreos()">
                                    <label class="form-check-label label-check" for="ch_operaciones">OPERACION
                                        REFRIGERADO</label>
                                </div>
                                <div class="form-group form-check-inline form-control-sm">
                                    <input type="checkbox" class="form-check-input" id="ch_rh"
                                        (change)="asignarCorreos()">
                                    <label class="form-check-label label-check" for="ch_rh">RH</label>
                                </div>
                                <div class="form-group form-check-inline form-control-sm">
                                    <input type="checkbox" class="form-check-input" id="ch_operaciones_seco"
                                        (change)="asignarCorreos()">
                                    <label class="form-check-label label-check" for="ch_operaciones_seco">OPERACION
                                        SECO</label>
                                </div>
                                <div class="form-group form-check-inline form-control-sm">
                                    <input type="checkbox" class="form-check-input" id="ch_mantenimiento"
                                        (change)="asignarCorreos()">
                                    <label class="form-check-label label-check"
                                        for="ch_mantenimiento">MANTENIMIENTO</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group form-check-inline form-control-sm">
                                    <input type="checkbox" class="form-check-input" id="ch_atenc_clientes"
                                        (change)="asignarCorreos()">
                                    <label class="form-check-label label-check" for="ch_atenc_clientes">ATENCION A
                                        CLIENTES</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="label-alerta">COMENTARIOS</label>
                            <textarea id="txt_comentarios" class="form-control" rows="2" [(ngModel)]="comentario"
                                name="comentario"></textarea>
                        </div>
                        <div class="row justify-content-around" style="margin-bottom: 0px;">
                            <div class="form-group">
                                <button type="button" class="btn btn-primary" data-dismiss="modal"
                                    aria-label="Close">Cerrar</button>
                            </div>
                            <div class="form-group">
                                <a *ngIf="aplica_sancion == 'S'" href="mailto:{{this.correoSancion}}" target="_blank">
                                    <button type="button" class="btn btn-primary"
                                        (click)="actualizarDatosAlerta()">GUARDAR</button></a>
                                <a *ngIf="aplica_sancion != 'S' || this.correoSancion == ''">
                                    <button type="button" class="btn btn-primary"
                                        (click)="actualizarDatosAlerta()">GUARDAR</button></a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="alertaAtendidas" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document" style="width: auto; height: auto;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title text-center" id="exampleModalLabel">RANGO DE FECHAS</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form (ngSubmit)="reporteAlertasAtendidas(f)"  #f="ngForm" class="validate-form">
                    <span *ngIf="f.submitted && f.controls.fechaInicial.invalid"
                    class="text-danger animated fadeIn">INGRESE UN RANDO DE FECHAS</span>
                    <div class="form-inline">
                        <label>Fecha Inicial: </label>
                        <div class="input-group input-group-sm col-sm text-uppercase">
                            <input type="date" class="form-control form-control-sm" aria-label="Username"
                                aria-describedby="basic-addon1" autocomplete="off" [(ngModel)]="fechaInicial"
                                name="fechaInicial" required>
                        </div>
                        <label>Fecha Final: </label>
                        <div class="input-group input-group-sm col-sm text-uppercase">
                            <input type="date" class="form-control form-control-sm" aria-label="Username"
                                aria-describedby="basic-addon1" autocomplete="off" [(ngModel)]="fechaFin"
                                name="fechaFin" required>
                        </div>
                    </div>
                    <br>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="cerrarModal(f)">Close</button>
                        <button type="submit" class="btn btn-primary">Exportar Reporte</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>