import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule} from '@angular/router';
import { MenuComponent } from './components/menu/menu.component';

const routes: Routes =[
  { path: '', redirectTo: 'viajes', pathMatch: 'full' },
  { path: 'viajes', component:MenuComponent},
]


@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, { useHash: true }), CommonModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
