import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner'
import { SancionService } from 'src/app/service/sancion/sancion.service';
import { AlertaService } from 'src/app/service/alertas/alertas.service';
import { ViajeService } from 'src/app/service/viaje/viaje.service';
import { CanTokenService } from 'src/app/service/guards/can-token.service';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
declare var $: any;
declare const jQuery: any;

@Component({
  selector: 'app-form-incidencia',
  templateUrl: './form-incidencia.component.html',
  styleUrls: ['./form-incidencia.component.css']
})
export class FormIncidenciaComponent implements OnInit {

  @Input() informacionReporteIncidencia;
  @Input() accion;
  @Input() modalTitle;
  @Output() ocultarModal = new EventEmitter
  listaSancion:any = [];
  formularioSancion: FormGroup;
  notificar_area;
  correoDestino;
  listaCorreos;
  listaFlotas:any
  id_usuario
  id_sancion
  nombre_sancion
  descripcion_sancion
  correoSancion:any;
  notificacion:any;
  correoNotificacion: string = '';
  correoDestinoFlota = '';

  constructor(
    private apiSancion: SancionService,
    private fb: FormBuilder,
    private apiAlert: AlertaService,
    private spinnerService: NgxSpinnerService,
    private apiViaje: ViajeService,
    private generalService: CanTokenService) {
    this.spinnerService.show()
    this.crearFormulario()
    let peticionesInformacion = [
      this.obtenerCatalogoSancion(),
      this.obtenerCorreos(),
      this.obtenerFlotas()
    ]
    if (this.accion == 'detalle') {
      this.formularioSancion.patchValue(this.informacionReporteIncidencia);
      this.spinnerService.hide();
      this.modalTitle = 'DETALLE INCIDENCIA';
      jQuery.noConflict();
      $('#mdl_alta_sancion').modal('show');
      $('#mdl_alta_sancion').draggable({
        handle: '.modal-dialog',
      });
      return;
    }
    forkJoin(peticionesInformacion).subscribe({
      next: () => {
        this.crearIncidencia()
        this.spinnerService.hide();
      },
      error: error => {
        this.spinnerService.hide();
      }
    });
  }

  ngOnInit(): void {
  }

  obtenerCatalogoSancion(){
    return new Promise((resolve, reject) => {
      this.apiSancion.getSanciones().subscribe({
        next: sancion =>{
          this.listaSancion = sancion;
          resolve('ok')
        },
        error: error =>{
          reject(error)
        }
      })
    })
  }

  obtenerCorreos() {
    return new Promise((resolve, reject) => {
      this.apiAlert.getCorreosAlertas().subscribe({
        next: correos =>{
          this.listaCorreos = correos;
          this.listaCorreos.forEach((proceso) => {
            if (proceso['proceso'] == 'NOTIFICACION-INCIDEN') {
              this.listaCorreos.push(proceso);
            }
          });
          resolve('ok')
        },
        error: error =>{
          reject(error)
        }
      });
    })
  }

  crearFormulario(){
    this.formularioSancion = this.fb.group({
      'motivo_sancion': new FormControl('', Validators.required),
      'nombre_operador': new FormControl('',Validators.required),
      'no_pedido': new FormControl('', Validators.required),
      'no_cdc': new FormControl('', Validators.required),
      'flotilla_oper': new FormControl('',Validators.required),
      'no_oper': new FormControl('', Validators.required),
      'nombre_flota': new FormControl('',Validators.required),
      'notificar_area': new FormControl(''),
      'reporte': new FormControl('', Validators.required),
    })
  }
  obtenerFlotas(): Promise<unknown>{
    return new Promise((resolve, reject) => {
      this.apiViaje.obtenerFlotas().subscribe({
        next: listaFlotas =>{
          this.listaFlotas = listaFlotas
          resolve('ok')
        },
        error: error =>{
          if (localStorage.getItem('token')) {
            if (error.status == 401) this.generalService.refreshToken();
          }
          reject(error);
        }
      })
    })
  }
  obtenerIdSancion() {
    const result = this.listaSancion.find(
      (sanciones) => sanciones.nombre === this.formularioSancion.value.motivo_sancion
    );
    if (result != null) {
      this.id_sancion = result.id_sancion;
      this.nombre_sancion = result.nombre;
      this.descripcion_sancion = result.descripcion;
    }
  }
  
  crearIncidencia(){
      if (this.accion != 'incidencia') {
        this.formularioSancion.controls.motivo_sancion.clearValidators();
        this.formularioSancion.controls.motivo_sancion.updateValueAndValidity();
      }
      this.formularioSancion.patchValue(this.informacionReporteIncidencia);
      const nombreFlota = this.listaFlotas.find(
        (idFlota) => idFlota.id === this.formularioSancion.controls['flotilla_oper'].value
      );
      if (nombreFlota != null) {
        this.formularioSancion.get('nombre_flota').setValue(nombreFlota.nombre)
      }
      jQuery.noConflict();
      $('#mdl_alta_sancion').modal('show');
      $('#mdl_alta_sancion').draggable({
        handle: '.modal-dialog',
      });
  }

  guardarReporteSancion() {
    this.id_usuario = localStorage.getItem('id_usuario');
    if (this.formularioSancion.invalid) {
      return;
    } else if (this.modalTitle == 'REPORTE DE NOTIFICACION') {
      this.redactarCorreoNotificacion();
      jQuery.noConflict();
      const correoFlota = this.listaCorreos.find((correos) => correos);
      $('#mdl_alta_sancion').modal('hide');
      Swal.fire({
        title: 'Reporte generado correctamente',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      });
    } else {
      let reporte = this.formularioSancion.value.reporte;
      let id_viaje = this.formularioSancion.value.no_pedido;
      let id_sancion = this.id_sancion;
      let id_usuario_alta = this.id_usuario;
      let id_alerta_viaje = '';
      let unidad = this.formularioSancion.value.no_cdc;
      let id_empleado_operador = this.formularioSancion.value.no_oper;
      let operador = this.formularioSancion.value.nombre_operador;
      let id_flota = this.formularioSancion.value.flotilla_oper;
      let flota = this.formularioSancion.value.nombre_flota;
      this.redactarCorreoSancion();
      this.apiSancion.postReporteSancion(reporte, id_viaje, id_sancion, id_usuario_alta, id_alerta_viaje, unidad, id_empleado_operador, operador, id_flota, flota).subscribe({
        next: (idSancion) => {
          this.formularioSancion.reset();
          jQuery.noConflict();
          $('#mdl_alta_sancion').modal('hide');
          Swal.fire({
            title: 'Reporte generado correctamente',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });
        },
        error: (error) => {
          jQuery.noConflict();
          $('#mdl_alta_sancion').modal('hide');
          if (localStorage.getItem('token')) {
            this.spinnerService.hide();
            if (error.status == 401) {
              this.modalError('La sesion a caducado', '', '401');
            } else {
              Swal.fire({
                title: 'Error al generar la sancion',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success'
                },
              });
            }
          }
        },
      });
    }
  }
  cerrarModal() {
    this.formularioSancion.reset()
    this.notificar_area = '';
    this.correoDestino = [];
    this.ocultarModal.emit()
  }
  asignarCorreos() {
    let correo = '';
    this.correoDestino = [];
    if ($('#ch_atenc_oper_seco').prop('checked') == true) {
      correo = correo === '' ? `${this.listaCorreos[14]['correo']}` : correo + `, ${this.listaCorreos[14]['correo']}`;
    }
    if ($('#ch_atenc_oper').prop('checked') == true) {
      correo = correo === '' ? `${this.listaCorreos[15]['correo']}` : correo + `, ${this.listaCorreos[15]['correo']}`;
    }
    if ($('#ch_rh').prop('checked') == true) {
      correo = correo === '' ? `${this.listaCorreos[16]['correo']}` : correo + `, ${this.listaCorreos[16]['correo']}`;
    }
    if ($('#ch_mantenimiento').prop('checked') == true) {
      correo = correo === '' ? `${this.listaCorreos[17]['correo']}` : correo + `, ${this.listaCorreos[17]['correo']}`;
    }
    if ($('#ch_atenc_clientes').prop('checked') == true) {
      correo =  correo === '' ? `${this.listaCorreos[18]['correo']}`: correo + `, ${this.listaCorreos[18]['correo']}`;
    }
    this.correoDestino = correo;
  }

  redactarCorreoSancion() {
    
    if (this.formularioSancion.value.notificar_area == 'SI') {
      this.correoSancion = `${this.correoDestino}?cc=${this.listaCorreos[0]['correo']}, ${this.listaCorreos[1]['correo']}, ${this.listaCorreos[2]['correo']}, ${this.listaCorreos[3]['correo']}, ${this.correoDestinoFlota}&subject=REPORTE%20DE%20INCIDENCIA%20UNIDAD: ${this.formularioSancion.value.no_cdc}/${this.formularioSancion.value.motivo_sancion}
    &body=VIAJE: ${this.formularioSancion.value.no_pedido} %0DUNIDAD: ${this.formularioSancion.value.no_cdc} %0DOPERADOR: ${this.formularioSancion.value.nombre_operador} %0DJEFE DE FLOTA: ${this.formularioSancion.value.nombre_flota} %0DINCIDENCIA: ${this.nombre_sancion}, ${this.formularioSancion.value.reporte}`;
    } else {
      this.correoSancion = `${this.correoDestinoFlota}?cc=${this.listaCorreos[0]['correo']}, ${this.listaCorreos[1]['correo']}, ${this.listaCorreos[2]['correo']}, ${this.listaCorreos[3]['correo']}&subject=REPORTE%20DE%20INCIDENCIA%20UNIDAD: ${this.formularioSancion.value.no_cdc}/${this.formularioSancion.value.motivo_sancion}
      &body=VIAJE: ${this.formularioSancion.value.no_pedido} %0DUNIDAD: ${this.formularioSancion.value.no_cdc} %0DOPERADOR: ${this.formularioSancion.value.nombre_operador} %0DJEFE DE FLOTA: ${this.formularioSancion.value.nombre_flota} %0DINCIDENCIA: ${this.nombre_sancion}, ${this.formularioSancion.value.reporte}`;
    }
  }
  redactarCorreoNotificacion() {
    this.notificacion = `${this.correoDestinoFlota}?cc=${this.listaCorreos[0]['correo']}, ${this.listaCorreos[1]['correo']}, ${this.listaCorreos[2]['correo']}, ${this.listaCorreos[3]['correo']}&subject=REPORTE%20DE%20NOTIFICACIÓN%20UNIDAD:%20${this.formularioSancion.value.no_cdc}/${this.formularioSancion.value.nombre_flota}
    &body=VIAJE: ${this.formularioSancion.value.no_pedido} %0DUNIDAD: ${this.formularioSancion.value.no_cdc} %0DOPERADOR: ${this.formularioSancion.value.nombre_operador} %0DJEFE DE FLOTA: ${this.formularioSancion.value.nombre_flota} %0DREPORTE: ${this.formularioSancion.value.reporte}`;
  }

  obtenerCorreoFlota(flota) {
    this.correoNotificacion = flota;
    let correoFlota = '';
    this.correoDestinoFlota = '';
    if (this.correoNotificacion == '02') {
      correoFlota = this.listaCorreos[0]['correo'];
    }
    if (this.correoNotificacion == '03') {
      correoFlota = this.listaCorreos[2]['correo'];
    }
    if (this.correoNotificacion == '04') {
      correoFlota = this.listaCorreos[3]['correo'];
    }
    if (this.correoNotificacion == '10') {
      correoFlota = this.listaCorreos[4]['correo'];
    }
    if (this.correoNotificacion == '11') {
      correoFlota = this.listaCorreos[5]['correo'];
    }
    if (this.correoNotificacion == '16') {
      correoFlota = this.listaCorreos[1]['correo'];
    }
    if (this.correoNotificacion == '27') {
      correoFlota = this.listaCorreos[6]['correo'];
    }
    if (this.correoNotificacion == '22') {
      correoFlota = this.listaCorreos[7]['correo'];
    }
    if (this.correoNotificacion == '23') {
      correoFlota = this.listaCorreos[8]['correo'];
    }
    if (this.correoNotificacion == '25') {
      correoFlota = this.listaCorreos[9]['correo'];
    }
    this.correoDestinoFlota = correoFlota;
  }

  modalError(titulo, mensaje, codigoError) {
    this.spinnerService.hide();
    //Configuracion del diseño del modal
    var alerta = Swal.mixin({
      imageUrl: '/assets/general/img/icon/error.svg',
      imageWidth: 85,
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false,
    });
    alerta.fire({
        title: titulo, //Titulo del modal
        text: mensaje, //Mensaje del modal
        allowOutsideClick: false, //Desactiva el backdrop, para no poder ocultar el modal hasta dar en el boton 'OK'
      }).then((resultado) => {
        //Si el error fue 401(No Autorizado) por vencimiento de token, cierra la sesion
        if (resultado.value && codigoError === '401') {
          this.salir(); //Cerrar la sesion del usuario
        }
      });
  }
  salir() {
    this.generalService.refreshToken();
  }

}
