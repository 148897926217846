import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { LoginService } from 'src/app/service/login/login.service';
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SancionService {

  private headers: HttpHeaders;

  constructor(private httpApi: HttpClient, private apiAuth: LoginService) { }

  getSanciones(): Observable<any>{
    return this.httpApi.get<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/sanciones`)
    .pipe(map(data => data));
  }

  getReporteSancion(): Observable<any>{
    return this.httpApi.get<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/reporte-sancion`)
    .pipe(map(data => data));
  }

  postReporteSancion(reporte, id_viaje, id_sancion,id_usuario_alta, id_alerta_viaje, id_unidad, id_operador, operador, id_flota, flota): Observable<any>{
    let httpParams = new HttpParams()
    .append('reporte', reporte)
    .append('id_viaje', id_viaje)
    .append('id_sancion', id_sancion)
    .append('id_usuario_alta', id_usuario_alta)
    .append('id_alerta_viaje', id_alerta_viaje)
    .append('id_tractor', id_unidad)
    .append('id_empleado_operador', id_operador)
    .append('operador', operador)
    .append('id_flota', id_flota)
    .append('flota', flota);
    return this.httpApi.post<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/reporte-sancion?${httpParams}`, httpParams)
    .pipe(map(data => data));
  }
}
