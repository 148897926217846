import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SocketwebService } from 'src/app/service/socketweb/socketweb.service';
import { take } from 'rxjs/operators';
import { AlertaService } from 'src/app/service/alertas/alertas.service';
import { SancionService } from 'src/app/service/sancion/sancion.service';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { CanTokenService } from 'src/app/service/guards/can-token.service';
import { ViajeService } from 'src/app/service/viaje/viaje.service';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { RouterLink } from '@angular/router';

declare var $: any;
declare const jQuery: any;
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent  implements OnInit {
//export class MenuComponent extends Socket implements OnInit{
  aplicacion: string = 'FRIO EXPRESS S.A. de C.V.';
  hijo:any;
  inicio: boolean = true;
  alerta: boolean = false;
  reporte_Incid: boolean = false;
  nota: boolean = false;
  pendiente: boolean = false;
  accid_bloqueos: boolean = false;
  agenda_permiso: boolean = false;
  catalogo:boolean = false;
  caja: boolean = false;
  operador: boolean = false;
  tracto: boolean = false;
  origen_destino: boolean = false;
  paradores_pension: boolean = false;
  poliza: boolean = false;
  mensaje: boolean = false;
  navBar: boolean = false;
  listaHijos: any[] = [ ];
  alertas:any = [];
  tipo_alerta:string = '';
  unidad_alerta:string = '';
  flota_alerta:string = '';
  operador_alerta:string = '';
  ubicacion:string = '';
  aplica_sancion:string = '';
  prioridad_alerta: string = '';
  url: string = '';
  comentario: string = '';
  id_alerta: string = '';
  motivo_sancion: any = [];
  lista_sanciones: any ='';
  envio_reporte: string = '';
  id_sancion:string = '';
  tiempo_alerta: any = '';
  link_posicion:string = '';
  datos_alertas:any = [];
  usid = '';
  id_viaje:any = '';
  lista_alertas_posponer:any = [];
  sancion:any = [
   {
    aplica: 'SI',
    valor: 'S'
   },
   {
     aplica: 'NO',
     valor: 'N'
   }
  ];
  listaNav: any[] = [
    {
      nombre: 'Viajes',
      clase: 'fad fas fa-map-marked-alt',
      componente: 'viaje',
      active: 1
    },
    {
      nombre: 'Alertas',
      clase: 'fad far fa-exclamation-triangle feather',
      componente: 'alerta',
      active: 0
    },
    {
      nombre: 'Reportes incidencias',
      clase: 'fad fas fa-file-chart-pie feather',
      componente: 'reporteInc',
      active: 0
    },
    {
      nombre: 'Pendientes',
      clase: 'fad far fa-file-invoice feather',
      componente: 'pendiente',
      active: 0
    },
    {
      nombre: 'Accidentes y Bloqueos',
      clase: 'fad fas fa-lock feather',
      componente: 'accidente-bloqueo',
      active: 0
    },
    {
      nombre: 'Agenda de permisos',
      clase: 'fad far fa-address-book feather',
      componente: 'agenda-permiso',
      active: 0
    },
    {
      nombre: 'Catalogos',
      clase: 'fad fas fa-book feather',
      componente: 'catalogo',
      active: 0,
      hijos: [
        {
          nombre: 'Cajas',
          clase: 'fad fas fa-truck feather',
          componente: 'caja',
          active: 0
        },
        {
          nombre: 'Operadores',
          clase: 'fad far fa-address-card feather',
          componente: 'operador',
          active: 0
        },
        {
          nombre: 'Tractos',
          clase: 'fad far fa-truck-moving feather',
          componente: 'tracto',
          active: 0
        },
        {
          nombre: 'Origenes y Destino',
          clase: 'fad far fa-route feather',
          componente: 'origen-destino',
          active: 0
        },
        {
          nombre: 'Paradores y Pensiones Autorizadas',
          clase: 'fad far fa-garage-car feather',
          componente: 'paradores-pension',
          active: 0
        },
        {
          nombre: 'Mensajes',
          clase: 'fad far fa-envelope feather',
          componente: 'mensaje',
          active: 0
        }
      ]
    }
  ];
  correosIncidencias: any = [];
  correoDestino: any = [];
  correoSancion: any = '';
  datosAlerta: any = [];
  nombre_sancion:string = '';
  descripcion_sancion:string = '';
  id_usuario:string = '';
  styleAlert:any;
  id:any = 0;
  toastrId:any = '';
  time_alert:any = '';
  toastrAlta:any;
  datosAlertas:any =[];
  listaAlerta:any = [];
  rowData:any;
  intervalAlert:any;
  id_flota:string = '';
  id_operador: string = '';
  operadorSancion:string = '';
  correoFlota:any = [];
  correoNotificacion:string = '';
  correoDestinoFlota:any = [];
  posponer_todas:boolean = false;
  posponer:boolean = false;
  listaAlertasPosponer = [];
  alertPospuesta:any = '';
  @Output() mostrarButtonAlertas = new EventEmitter();

  constructor(private toastr: ToastrService, 
    private socket: SocketwebService, 
    private apiAlert: AlertaService, 
    private apiSancion: SancionService,  
    private cookieService: CookieService,
    private generalService: CanTokenService,
    private spinnerService: NgxSpinnerService,
    private apiViajes: ViajeService) {
    window.scroll(0, 0);
    if (!localStorage.getItem('token')) {
      if (!this.cookieService.check('USID') && !this.usid) {
        window.location.href = environment.loginUrl;
      }
      const USID = this.cookieService.get('USID') || this.usid;
      this.generalService.getUserToken(USID, environment.appId).subscribe((data: any) => {
        localStorage.setItem('token', data.token);
        const statusAlertas = localStorage.getItem('alertas:');
        this.getDatos();
        if ( statusAlertas !== 'Activadas' ) {
          if (localStorage.getItem('timer') == null) {
            this.activarAlerta();
          }
        } else{
          this.getDatos()
          this.obtenerCatalogoSanciones();
          this.obtenerCorreos();
          this.obtenerAlertas();
        }
      });
    }else{
      this.getDatos();
      const statusAlertas = localStorage.getItem('alertas:');
      const fecha_actual = moment().format('YYYY-MM-DD HH:mm');
      if ( statusAlertas != 'Activadas') {
        if (localStorage.getItem('timer') == null) {
          this.activarAlerta()
          this.obtenerCorreos();
        }else if (localStorage.getItem('timer') <= fecha_actual) {
          this.getDatos()
          this.obtenerAlertas();
          this.obtenerCatalogoSanciones();
          this.obtenerCorreos();
          let statusAlertas = 'Activadas';
          localStorage.setItem('alertas:', statusAlertas)
        }
      } else{
        this.getDatos();
        this.obtenerAlertas();
        this.obtenerCatalogoSanciones();
        this.obtenerCorreos();
      }
    }
  }

  ngOnInit() {
    this.alertPospuesta = setInterval(() => {
      this.obtenerAlertasPendientes()
    },60000)
  }
  getDatos(){
    const USID = this.cookieService.get('USID') || this.usid
    this.apiViajes.getUsuario(USID).subscribe(datos =>{
      localStorage.setItem('id_usuario', datos.id_usuario)
    })
  }
  actualizaVista(componente) {
    //Si el componente seleccionado es inicio, muestra el componente y oculta los demas componentes
    if (componente === 'viaje') {
      this.seccionActiva(componente);//Comportamiento de diseño para los componentes activos y no activos
      this.inicio = true;
      this.alerta = false;
      this.reporte_Incid = false;
      this.nota = false;
      this.pendiente = false;
      this.accid_bloqueos= false;
      this.agenda_permiso = false;
      this.catalogo = false;
      this.caja = false;
      this.operador =false;
      this.tracto = false;
      this.origen_destino = false;
      this.paradores_pension = false;
      this.mensaje = false;
      clearInterval(this.intervalAlert)
    }
    if (componente === 'alerta') {
      this.seccionActiva(componente);
      this.alerta = true;
      this.inicio = false;
      this.reporte_Incid = false;
      this.nota = false;
      this.pendiente = false;
      this.accid_bloqueos= false;
      this.agenda_permiso = false;
      this.catalogo = false;
      this.caja = false;
      this.operador = false;
      this.tracto = false;
      this.origen_destino = false;
      this.paradores_pension = false;
      this.mensaje = false;
      this.obtenerCatalogoSanciones();
        this.intervalAlert = setInterval(() => {
          this.apiAlert.getAlert().subscribe({
            next: data => {
              this.listaAlerta = data;
              this.listaAlerta.forEach(alerta => {
                const result = this.listaAlerta.find(alertaDatos => alertaDatos.id_alerta_viaje === alerta.id_alerta_viaje);
                if (result != null) {
                  alerta.fec_alta = moment.utc(result.fec_alta).format('YYYY-MM-DD HH:mm');
                }
                const nombre_incidencia = this.lista_sanciones.find(sancion => sancion.id_sancion === alerta.id_sancion)
                if (nombre_incidencia != null) {
                  alerta.id_sancion = nombre_incidencia.nombre
                }
              });
              this.rowData = this.listaAlerta;
            },
            error: error => {
              if(localStorage.getItem('token')) {
                if (error.status == 401) {
                  this.generalService.refreshToken();
                }else{
                  this.modalError('Error al obtener las alertas', 'Por favor comuniquese con el area de sistemas', error.status)
                } 
              }else{
                this.modalError('Error al obtener las alertas', 'Por favor comuniquese con el area de sistemas', error.status)
              }
            }
        })
      },60000);
    }
    if (componente === 'reporteInc') {
      this.seccionActiva(componente);
      this.alerta = false;
      this.inicio = false;
      this.reporte_Incid = true;
      this.nota = false;
      this.pendiente = false;
      this.accid_bloqueos= false;
      this.agenda_permiso = false;
      this.catalogo = false;
      this.caja = false;
      this.operador = false;
      this.tracto = false;
      this.origen_destino = false;
      this.paradores_pension = false;
      this.mensaje = false;
      clearInterval(this.intervalAlert)
    }
    if (componente === 'pendiente') {
      this.seccionActiva(componente);
      this.inicio =false;
      this.alerta = false;
      this.reporte_Incid = false;
      this.nota = false;
      this.pendiente = true;
      this.accid_bloqueos= false;
      this.agenda_permiso = false;
      this.catalogo = false;
      this.caja = false;
      this.operador = false;
      this.tracto = false;
      this.origen_destino = false;
      this.paradores_pension = false;
      this.mensaje = false;
      clearInterval(this.intervalAlert)
    }
    if (componente === 'accidente-bloqueo') {
      this.seccionActiva(componente);
      this.inicio =false;
      this.alerta = false;
      this.reporte_Incid = false;
      this.nota = false;
      this.pendiente = false;
      this.accid_bloqueos= true;
      this.agenda_permiso = false;
      this.catalogo =false;
      this.caja = false;
      this.operador = false;
      this.tracto = false;
      this.origen_destino = false;
      this.paradores_pension = false;
      this.mensaje = false;
      clearInterval(this.intervalAlert)
    }
    if (componente === 'agenda-permiso') {
      this.seccionActiva(componente);
      this.inicio =false;
      this.alerta = false;
      this.reporte_Incid = false;
      this.nota = false;
      this.pendiente = false;
      this.accid_bloqueos= false;
      this.agenda_permiso = true;
      this.catalogo =false;
      this.caja = false;
      this.operador = false;
      this.tracto = false;
      this.origen_destino = false;
      this.paradores_pension = false;
      this.mensaje = false;
      clearInterval(this.intervalAlert)
    }
    if (componente ==='caja') {
      this.seccionCatalogo(componente);
      this.inicio =false;
      this.alerta = false;
      this.reporte_Incid = false;
      this.nota = false;
      this.pendiente = false;
      this.accid_bloqueos= false;
      this.agenda_permiso = false;
      this.caja = true;
      this.operador = false;
      this.tracto = false;
      this.origen_destino = false;
      this.paradores_pension = false;
      this.mensaje = false;
      clearInterval(this.intervalAlert)
    }
    if (componente === 'operador') {
      this.seccionCatalogo(componente);
      this.inicio =false;
      this.alerta = false;
      this.reporte_Incid = false;
      this.nota = false;
      this.pendiente = false;
      this.accid_bloqueos= false;
      this.agenda_permiso = false;
      this.caja = false;
      this.operador = true;
      this.tracto = false;
      this.origen_destino = false;
      this.paradores_pension = false;
      this.mensaje = false;
      this.catalogo = false;
      clearInterval(this.intervalAlert)
    }
    if (componente === 'tracto') {
      this.seccionActiva(componente);//Comportamiento de diseño para los componentes activos y no activos
      this.inicio =false;
      this.alerta = false;
      this.reporte_Incid = false;
      this.nota = false;
      this.pendiente = false;
      this.accid_bloqueos= false;
      this.agenda_permiso = false;
      this.caja = false;
      this.operador = false;
      this.tracto = true;
      this.origen_destino = false;
      this.paradores_pension = false;
      this.mensaje = false;
      clearInterval(this.intervalAlert)
    }
    if (componente === 'origen-destino') {
      this.seccionActiva(componente);//Comportamiento de diseño para los componentes activos y no activos
      this.inicio =false;
      this.alerta = false;
      this.reporte_Incid = false;
      this.nota = false;
      this.pendiente = false;
      this.accid_bloqueos= false;
      this.agenda_permiso = false;
      this.caja = false;
      this.operador = false;
      this.tracto = false;
      this.origen_destino = true;
      this.paradores_pension = false;
      this.mensaje = false;
      clearInterval(this.intervalAlert)
    }
    if (componente === 'paradores-pension') {
      this.seccionActiva(componente);//Comportamiento de diseño para los componentes activos y no activos
      this.inicio =false;
      this.alerta = false;
      this.reporte_Incid = false;
      this.nota = false;
      this.pendiente = false;
      this.accid_bloqueos= false;
      this.agenda_permiso = false;
      this.caja = false;
      this.operador = false;
      this.tracto = false;
      this.origen_destino = false;
      this.paradores_pension = true;
      this.mensaje = false;
      clearInterval(this.intervalAlert)
    }
    if (componente === 'mensaje') {
      this.seccionActiva(componente);//Comportamiento de diseño para los componentes activos y no activos
      this.inicio =false;
      this.alerta = false;
      this.reporte_Incid = false;
      this.nota = false;
      this.pendiente = false;
      this.accid_bloqueos= false;
      this.agenda_permiso = false;
      this.caja = false;
      this.operador = false;
      this.tracto = false;
      this.origen_destino = false;
      this.paradores_pension = false;
      this.mensaje = true;
      clearInterval(this.intervalAlert)
    }
  }
  /**
  * @description Controla el diseño de las secciones del navbar lateral, para poner las secciones activar y no activas
  * @method seccionActiva
  * @param parametros -> Componente seleccionado
  */
  seccionActiva(componente) {
    this.listaNav.map((dato) => {
      if (dato['componente'] === componente) {
        dato['active'] = 1;
        dato['clase'] = dato['clase'].toString().replace(' feather', '');
      }
      if (dato['componente'] !== componente) {
        dato['active'] = 0;
        dato['clase'] = dato['clase'].toString().replace(' feather', '');
        dato['clase'] = dato['clase'].toString() + ' feather';
      }
    });
  }
  seccionCatalogo(componente){
    this.listaNav.map((dato) => {
      if (dato['componente'] === componente) {
        dato['active'] = 0;
        dato['clase'] = dato['clase'].toString().replace(' feather', '');
      }
      if (dato['componente'] !== componente) {
        dato['active'] = 0;
        dato['clase'] = dato['clase'].toString().replace(' feather', '');
        dato['clase'] = dato['clase'].toString() + ' feather';
      }
    });
  }
  /**
 * @description Actualiza la variable navbar, para el comportamiento del menu lateral (Ocultar y mostrar).
 * @method actualizaMenu
 */
  actualizaMenu(bandera) {
    this.navBar = bandera;
  }

  alertasViajes(datosAlerta, idtoastr) {
    this.id_usuario = localStorage.getItem('id_usuario');
    this.datosAlerta = datosAlerta;
    let timerPosponer = moment().format('YYYY-MM-DD HH:mm');
    let timerActivo =  localStorage.getItem('timer');
    let id_alerta = localStorage.getItem('Numero_de_alerta');
    if (timerActivo != null) {
        if(timerPosponer <= timerActivo){
          let alerta = 0;
          alerta++;
        }else{
          this.mostrarAlerta(datosAlerta, idtoastr);
        }
    }else{
      this.mostrarAlerta(datosAlerta, idtoastr);
    }
  }

  mostrarAlerta(datosAlerta, idtoastr){
    this.datosAlerta = [];
    this.prioridad_alerta = datosAlerta['estado_alerta'];
    const tituloAlerta = datosAlerta['id_alerta'];
    this.link_posicion = datosAlerta['enlace_google'];
    this.alertas.push(datosAlerta)
    if (this.prioridad_alerta == 'Alta') {
      this.toastrAlta = this.toastr.error('',tituloAlerta , {
        disableTimeOut:true,
        closeButton: true,
        tapToDismiss: false,
      }).onTap.pipe(take(10)).subscribe(() => this.toasterClickedHandler(datosAlerta, this.toastrAlta.toastId));
    }else if (this.prioridad_alerta == 'Baja') {
      this.toastrAlta = this.toastr.warning('', tituloAlerta, {
        disableTimeOut:true,
        titleClass: 'toast-title-info',
        closeButton: true,
        tapToDismiss: false
      }).onTap.pipe(take(10)).subscribe(() => this.toasterClickedHandler(datosAlerta, this.toastrAlta.toastId));  
    }else{
      this.toastrAlta = this.toastr.info('', tituloAlerta, {
        disableTimeOut:true,
        titleClass: 'toast-title-info',
        closeButton: true,
        tapToDismiss: false,
      }).onTap.pipe(take(10)).subscribe(() => this.toasterClickedHandler(datosAlerta,this.toastrAlta.toastId));  
    }
  }

  toasterClickedHandler(datosAlerta, idtoastr) {
    this.posponer_todas = false;
    this.posponer = false;
    this.datosAlerta = datosAlerta;
    this.id_alerta = datosAlerta['id'];
    this.id_viaje = datosAlerta['id_viaje'];
    this.tipo_alerta =  datosAlerta['id_alerta'] ;
    this.flota_alerta =  datosAlerta['flota'];
    this.operador_alerta = datosAlerta['id_operador'] +" "+datosAlerta['nombre_operador'];
    this.ubicacion = datosAlerta['location'];
    this.unidad_alerta = datosAlerta['id_tractor'];
    this.id_flota = datosAlerta['id_flota'];
    this.id_operador = datosAlerta['id_operador'];
    this.operadorSancion = datosAlerta['nombre_operador'];
    this.toastrId = idtoastr;
    this.comentario = '';
    this.aplica_sancion = '';
    this.envio_reporte = '';
    if (this.datosAlerta['estado_alerta'] == 'Alta') {
      this.styleAlert = {
        background: '#BF343F',
        color: '#ffff'
      };
    }else if (this.datosAlerta['estado_alerta'] == 'Baja') {
      this.styleAlert = {
        background: '#FFCD02',
        color: '#000000'
      };
    }else{
      this.styleAlert = {
        background: '#51A351',
        color: '#ffff'
      }
    }
    window.open('https://telematica.cttmx.com/?lang=es', '_blank');
    $(document).ready(function () {
      jQuery.noConflict();
      $('#mdl_ALERTA').modal('show');
      $("#mdl_ALERTA").draggable({
        handle: ".modal-header"
      });
    })

  }

  obtenerIdSancion(sancion){
    const result = this.lista_sanciones.find(sanciones =>sanciones.nombre === sancion);
    if (result != null) {
      this.id_sancion = result.id_sancion;
      this.nombre_sancion = result.nombre;
      this.descripcion_sancion = result.descripcion
    }
  }
  
  guardarAlerta(){
    this.id_usuario = localStorage.getItem('id_usuario');
    if (this.correoDestino != '') {
      this.envio_reporte = 'S'
      this.redactarCorreoSancion(this.comentario);
    }else{
      this.envio_reporte = 'N'
    }
    this.id_usuario = localStorage.getItem('id_usuario')
    let body = {
      'id_alerta': this.id_alerta,
      'comentario': this.comentario,
      'envio_reporte': this.envio_reporte,
      'id_usuario': this.id_usuario,
      'id_sancion': this.id_sancion,
      'inc_operador': this.aplica_sancion,
      'status': 'R'
    }
    this.apiAlert.updateAlert(body).subscribe(datos =>{
      if (datos == null) {
        if (body['inc_operador'] !=  "N" ) {
            let reporte = this.comentario
            let id_viaje =  this.id_viaje
            let id_sancion = this.id_sancion
            let id_usuario_alta = this.id_usuario
            let id_alerta_viaje = this.id_alerta;
            let id_unidad = this.unidad_alerta
            let id_oper = this.id_operador;
            let id_flota =  this.id_flota
            let flota = this.flota_alerta;
            let operador = this.operadorSancion;
            this.obtenerCorreoFlota(this.id_flota);
            this.redactarCorreoSancion(this.comentario)
          this.apiSancion.postReporteSancion(reporte, id_viaje, id_sancion,id_usuario_alta, id_alerta_viaje, id_unidad,id_oper, id_flota, flota, operador).subscribe({
            next: idSancion => {
              jQuery.noConflict();
              $('#mdl_ALERTA').modal('hide');
              Swal.fire({
                title: 'Alerta atendida correctamente',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            },
            error: error => {
              jQuery.noConflict();
              $('#mdl_ALERTA').modal('hide');
              if (localStorage.getItem('token')) {
                this.spinnerService.hide()
                if (error.status == 401) {
                  this.modalError('La sesion a caducado', '', '401');
                } else {
                  Swal.fire({
                    title: 'Error al generar la sancion',
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                }
              }
            }
          })
        }else{
          $(document).ready(function () {
            jQuery.noConflict();
            $('#mdl_ALERTA').modal('hide');
          })
          Swal.fire({
            title: 'Alerta atendida correctamente',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
        return this.alertas.filter(e=> e.id!== body.id_alerta);
      }
    });
  }

  posicion() {
    this.url = this.link_posicion;
  }

  posponerAlerta(){
    this.id_usuario = localStorage.getItem('id_usuario')
    let alerta = {
      'id_alerta': this.id_alerta,
      'comentario': this.comentario,
      'envio_reporte': this.envio_reporte,
      'id_usuario': this.id_usuario,
      'id_sancion': this.id_sancion,
      'inc_operador': this.aplica_sancion,
      'status': 'P'
    }
    if(this.posponer_todas == true){
      this.toastr.clear();
      this.desactivarAlerta();
    }else if(this.posponer == true){
      let fecha_posponer = moment().format('YYYY-MM-DD');
      let timer = moment(this.time_alert, 'HH:mm').format('HH:mm');
      let timerAlerta = fecha_posponer +' '+ timer;
      let startTimer = moment().format('YYYY-MM-DD HH:mm')
      let posponer_alerta = {
        'id_alerta': this.id_alerta,
        'comentario': this.comentario,
        'envio_reporte': this.envio_reporte,
        'id_usuario': this.id_usuario,
        'id_sancion': this.id_sancion,
        'inc_operador': this.aplica_sancion,
        'status': 'P',
        'timer': timerAlerta,
        'startTimer':startTimer
      }
      this.listaAlertasPosponer.push(posponer_alerta);
      localStorage.setItem('alertas_pospuestas', JSON.stringify(this.listaAlertasPosponer));
    }
    this.apiAlert.updateAlert(alerta).subscribe(datos =>{
      if (datos == null) {
        $(document).ready(function () {
          jQuery.noConflict();
          $('#mdl_ALERTA').modal('hide');
          $('#posponer_alert').modal('hide');
        })
      }
    })
  }

  obtenerAlertasPendientes() {
    let startTimer = localStorage.getItem('startTimer');
    let fecha_actual = moment().format('YYYY-MM-DD HH:mm');
    let endTimer = localStorage.getItem('timer');
    let alertas_pospuestas = localStorage.getItem('alertas_pospuestas');
    if (alertas_pospuestas != null) {
      let arrayAlertas = JSON.parse(alertas_pospuestas);
      for (let alertas = 0; alertas < arrayAlertas.length; alertas++) {
        const datos = arrayAlertas[alertas];
        let startTimerPosponer = datos['startTimer'];
        if (datos['timer'] <= fecha_actual) {
          let datosAlert = {};
          this.apiAlert.getAlert().subscribe({
            next: data => {
              this.datosAlertas = data;
              this.datosAlertas.forEach(alerta => {
                alerta.fec_alta = moment(alerta.fec_alta).tz("America/Mexico_City").format('YYYY-MM-DD HH:mm');
                if (alerta.fec_alta > startTimerPosponer && alerta.fec_alta < fecha_actual) {
                  datosAlert = {
                    'id': alerta.id_alerta_viaje,
                    'estado_alerta' : alerta.estado_alerta,
                    'id_alerta': alerta.nombre_alerta,
                    'enlace_google': 'https://www.google.com/maps/search/?api=1&query='+alerta.latitud +','+ alerta.longitud,
                    'id_operador': alerta.id_empleado,
                    'nombre_operador': alerta.operador,
                    'location': alerta.latitud +','+ alerta.longitud,
                    'flota': alerta.flota,
                    'id_tractor': alerta.id_tractor
                  }
                  let toastrId = this.id++;
                  this.mostrarAlerta(datosAlert, toastrId)
                }
              });
            },
            error: error => {
              if (localStorage.getItem('token')) {
                if (error.status == 401) this.generalService.refreshToken();
              }
            }
          })
        }
        
      } 
      clearInterval(this.alertPospuesta);
      localStorage.removeItem('alertas_pospuestas');
    }else if(endTimer <= fecha_actual){
      let datosAlert = {};
      this.apiAlert.getAlert().subscribe({
        next: data => {
          this.datosAlertas = data;
          this.datosAlertas.forEach(alerta => {
            alerta.fec_alta = moment.utc(alerta.fec_alta).format('YYYY-MM-DD HH:mm');
            if (startTimer <= alerta.fec_alta  && alerta.fec_alta <= fecha_actual) {
              datosAlert = {
                'id': alerta.id_alerta_viaje,
                'estado_alerta' : alerta.estado_alerta,
                'id_alerta': alerta.nombre_alerta,
                'enlace_google': 'https://www.google.com/maps/search/?api=1&query='+alerta.latitud +','+ alerta.longitud,
                'id_operador': alerta.id_empleado,
                'nombre_operador': alerta.operador,
                'location': alerta.latitud +','+ alerta.longitud,
                'flota': alerta.flota,
                'id_tractor': alerta.id_tractor
              }
              let toastrId = this.id++;
              this.mostrarAlerta(datosAlert, toastrId)
            }
          });
          localStorage.removeItem('timer');
          localStorage.removeItem('startTimer');
          let statusAlertas = 'Activadas';
          localStorage.setItem('alertas:', statusAlertas)
          this.obtenerAlertas();
        },
        error: error => {
          if (localStorage.getItem('token')) {
            if (error.status == 401) this.generalService.refreshToken();
          }
        }
      })
    }else if (endTimer == null || endTimer == ''){
      clearInterval(this.alertPospuesta);
    }
  }

  obtenerCatalogoSanciones(){
    this.apiSancion.getSanciones().subscribe({
      next: sanciones => {
        this.lista_sanciones = sanciones;
      },
      error: error => {
        if(localStorage.getItem('token')) {
          if (error.status == 401) this.generalService.refreshToken();
        }
      }
    })
  }
  asignarCorreos(){
    let correo = '';
    if ($('#ch_atenc_oper_seco').prop('checked') == true) {
      correo = correo === '' ? `${this.correosIncidencias[14]['correo']}` : correo + `, ${this.correosIncidencias[14]['correo']}`;
    }
    if($('#ch_atenc_oper').prop('checked') == true){
      correo = correo === '' ? `${this.correosIncidencias[15]['correo']}` : correo + `, ${this.correosIncidencias[15]['correo']}`;
    }
    if ($('#ch_rh').prop('checked') == true) {
      correo = correo === '' ? `${this.correosIncidencias[16]['correo']}` : correo + `, ${this.correosIncidencias[16]['correo']}`;
    }
    if ($('#ch_mantenimiento').prop('checked') == true) {
      correo = correo === '' ? `${this.correosIncidencias[17]['correo']}` : correo + `, ${this.correosIncidencias[17]['correo']}`;
    }
    if ($('#ch_atenc_clientes').prop('checked') == true) {
      correo = correo === '' ? `${this.correosIncidencias[18]['correo']}` : correo + `, ${this.correosIncidencias[18]['correo']}`;
    }
    this.correoDestino = correo;
  }

  redactarCorreoSancion(reporte) {
    if(this.correoDestino.length != 0){
      this.correoSancion = `${this.correoDestino}?cc=${this.correosIncidencias[0]['correo']}, ${this.correosIncidencias[1]['correo']}, ${this.correosIncidencias[2]['correo']}, ${this.correosIncidencias[3]['correo']}, ${this.correoDestinoFlota}&subject=REPORTE%20DE%20INCIDENCIA%20UNIDAD:%20${this.unidad_alerta}/${this.nombre_sancion}
    &body=VIAJE: ${this.id_viaje} %0DUNIDAD: ${this.unidad_alerta} %0DOPERADOR: ${this.datosAlerta['id_operador']} %0DJEFE DE FLOTA: ${this.flota_alerta} %0DINCIDENCIA: ${this.nombre_sancion}, %20${reporte}`;
    }else{
      this.correoSancion = `${this.correoDestinoFlota}?cc=${this.correosIncidencias[0]['correo']}, ${this.correosIncidencias[1]['correo']}, ${this.correosIncidencias[2]['correo']}, ${this.correosIncidencias[3]['correo']}&subject=REPORTE%20DE%20INCIDENCIA%20UNIDAD:%20${this.unidad_alerta}/${this.nombre_sancion}
      &body=VIAJE: ${this.id_viaje} %0DUNIDAD: ${this.unidad_alerta} %0DOPERADOR: ${this.datosAlerta['id_operador']} %0DJEFE DE FLOTA: ${this.flota_alerta} %0DINCIDENCIA: ${this.nombre_sancion}, ${reporte}`;
    }
  }

  obtenerCorreos(){
    this.apiAlert.getCorreosAlertas().subscribe(correos =>{
      this.correosIncidencias = correos
      this.correosIncidencias.forEach(proceso => {
        if (proceso['proceso'] == 'NOTIFICACION-INCIDEN') {
          this.correoFlota.push(proceso);
        }
      });
    })
  }
  activarAlerta() {
    Swal.fire({
      title: '¿Desea activar las alertas?',
      showCancelButton: true,
      confirmButtonText: `SI`,
      confirmButtonColor: '#004680',
      cancelButtonText: 'NO',
      cancelButtonColor: '#BF343F'
    }).then((result) => {
      if (result.isConfirmed) {
        this.getDatos()
        this.obtenerCorreos()
        this.obtenerCatalogoSanciones();
        let statusAlertas = 'Activadas';
        localStorage.setItem('alertas:', statusAlertas)
        localStorage.removeItem('timer');
        localStorage.removeItem('startTimer');
        this.mostrarButtonAlertas.emit(false);
        this.obtenerAlertas();
      }else{
        this.mostralModal();
      }
    })
  }

  mostralModal(){
    $(document).ready(function () {
      jQuery.noConflict();
      $('#activarAlerta').modal('show');
    })
  }
  
  desactivarAlerta() {
    let fecha_posponer = moment().format('YYYY-MM-DD');
    let timer = moment(this.time_alert, 'HH:mm').format('HH:mm');
    let startTimer = moment().format('YYYY-MM-DD HH:mm')
    localStorage.setItem('timer', fecha_posponer + ' ' + timer);
    localStorage.setItem('startTimer', startTimer);
    this.mostrarButtonAlertas.emit(true);
    if(timer != ''){
      $(document).ready(function () {
        jQuery.noConflict();
        $('#activarAlerta').modal('hide');
      })
    }
  }
  modalError(titulo, mensaje, codigoError) {
    this.spinnerService.hide()
    //Configuracion del diseño del modal
    var alerta = Swal.mixin({
      imageUrl: '/assets/general/img/icon/error.svg',
      imageWidth: 85,
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    });
    alerta.fire(
      {
        title: titulo, //Titulo del modal
        text: mensaje, //Mensaje del modal
        allowOutsideClick: false //Desactiva el backdrop, para no poder ocultar el modal hasta dar en el boton 'OK'
      }
    ).then((resultado) => {
      //Si el error fue 401(No Autorizado) por vencimiento de token, cierra la sesion
      if (resultado.value && codigoError === '401') {
        this.salir();//Cerrar la sesion del usuario
      }
    });
  }
  salir() {
    this.generalService.refreshToken()
  }
  obtenerCorreoFlota(flota){
    this.correoNotificacion = flota
    let correoFlota = '';
    this.correoDestinoFlota = '';
    if (this.correoNotificacion == '02') {
      correoFlota = this.correoFlota[0]['correo']
    }
    if (this.correoNotificacion == '03') {
      correoFlota = this.correoFlota[2]['correo']
    }
    if (this.correoNotificacion == '04') {
      correoFlota = this.correoFlota[3]['correo']
    }
    if (this.correoNotificacion == '10') {
      correoFlota = this.correoFlota[4]['correo']
    }
    if (this.correoNotificacion == '11') {
      correoFlota = this.correoFlota[5]['correo']
    }
    if (this.correoNotificacion =='16') {
      correoFlota = this.correoFlota[1]['correo']
    }
    if (this.correoNotificacion == '27') {
      correoFlota = this.correoFlota[6]['correo']
    }
    if (this.correoNotificacion == '22') {
      correoFlota = this.correoFlota[7]['correo']
    }
    if (this.correoNotificacion == '23') {
      correoFlota = this.correoFlota[8]['correo']
    }
    if (this.correoNotificacion == '25') {
      correoFlota = this.correoFlota[9]['correo']
    }
    this.correoDestinoFlota = correoFlota;
  }
  opcionPosponerAlerta(){
    if($('#ch_posponer_todas').prop('checked') == true){
      this.posponer_todas = true;
      this.posponer = false;
    }if($('#ch_posponer').prop('checked') == true){
      this.posponer_todas = false;
      this.posponer = true
    }
  }
  obtenerAlertas(){
    this.socket.getData().subscribe(data =>{
      let toastrId = this.id++;
      this.alertasViajes(data, toastrId);
    })
  }
  activarAlertasManual(event){
    if(event == true){
      this.activarAlerta()
    }
  }
}
