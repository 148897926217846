<nav class="sidebar" [ngStyle]="{'left': navbar ? '-300px' : '0'}" (mostrarButtonAlertas)="mostrarBoton()">
  <div class="sidebar-sticky">
    <img src="../../../../assets/general/img/frio-logo-w.png" class="img-fluid img-90p p-3 mx-auto d-block"
      alt="Frio Logo">
    <ul class="nav flex-column mt-3">
      <li *ngFor="let lista of listaNavBar;" class="nav-item">
        <a class="nav-link" style="color:white;" (click)="seccionComponente(lista.componente)">
          <i [ngClass]="lista.clase"></i>
          <span [ngClass]="{'active': lista.active === 1}" style="cursor: pointer;"> {{lista.nombre}}</span>
          <li *ngFor="let hijo of lista.hijos " class="nav-item">
            <a class="nav-link" style="color:white;" (click)="seccionComponente(hijo.componente)">
              <i [ngClass]="hijo.clase"></i>
              <span [ngClass]="{'active': hijo.active === 1}" style="cursor: pointer;"> {{hijo.nombre}}</span>
            </a>
          </li>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" style="color:white;" *ngIf="estadoAlerta">
          <div class="input-group input-group-sm">
            <button type="submit" class="btn btn-danger" style="font-size: small;" (click)="activarAlertas()">ACTIVAR ALERTAS</button>
          </div>
        </a>
      </li>
    </ul>
    <div class="fixed-bottom">
      <ul class="nav flex-column mb-3 ml-16">
        <li class="nav-item">
          <a [routerLink]="['/menu']" class="nav-link">
            <i class="fas fa-th"></i>
            <span> Aplicaciones</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<!-- </div>
        </div> -->