import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ReporteService } from 'src/app/service/reporte/reporte.service';
import { CanTokenService } from 'src/app/service/guards/can-token.service';
import { AlertaService } from 'src/app/service/alertas/alertas.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { TractorService } from '../../service/tractor/tractor.service';
import { Pipe, PipeTransform } from '@angular/core';

declare var jQuery: any;
declare var $: any;
@Component({
  selector: 'app-accidente-bloqueo',
  templateUrl: './accidente-bloqueo.component.html',
  styleUrls: ['./accidente-bloqueo.component.css'],
})
export class AccidenteBloqueoComponent implements OnInit {
  @Input() navbar: boolean = false;
  listaAccid_Bloq: any = [];
  asunto: string = '';
  unidad_invo: string = '';
  enlace_ext: string = '';
  descripcion: string = '';
  modalTitle: string = '';
  idAccBloqueo: string = '';
  notificar: string = '';
  areaNotificar: any;
  id_ciudad1: string = '';
  id_ciudad2: string = '';
  ciudad_uno: string = '';
  ciudad_dos: string = '';
  estado_uno: string = '';
  id_estado1: any = '';
  estado_dos: string = '';
  id_estado2: any = '';
  pais_uno: string = '';
  id_pais1: any = '';
  pais_dos: string = '';
  id_pais2: any = '';
  area: any = '';
  columnDefs: any = [];
  rowData: any;
  dato: any;
  nombreCol: any;
  gridColumnApi: any;
  defaultColDef: any;
  rowSelection: any;
  listaEstado: any = [];
  correos: any = [];
  correoDestino: any = '';
  correoAccidenteBloqueo;
  id_usuario: string = '';
  style = {
    width: '95%',
    height: '100%',
    flex: '1 1 auto',
  };
  operador: string = '';
  status: string = '';
  numOper: boolean = false;
  input: any;
  contUnidad: any = '';
  listaTractor: any[];
  statusTracto: string = `'A'`;
  noCdc: string = '';
  cveCia: string = '';
  filtro = '';
  unidadesInvolucradas = [];

  @ViewChild('compon', { static: false }) comp: ElementRef; //Para generar los elementos dinamicos e incluirlos en el HTML
  constructor(
    private apiReporte: ReporteService,
    private apiAlerta: AlertaService,
    private generalService: CanTokenService,
    private servicioTractor: TractorService
  ) {
    window.scroll(0, 0);
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      width: 150,
      filter: true,
    };
    this.rowSelection = 'multiple';
  }

  ngOnInit() {
    this.getReportes();
    this.getCiudades();
    this.obtenerCorreos();
    this.obtenerListaTractores();
    this.id_usuario = localStorage.getItem('id_usuario');
    $('#ch_operaciones').prop( "checked", true );
    $('#ch_operaciones_seco').prop( "checked", true )
  }

  getCiudades() {
    this.apiReporte.getCiudad().subscribe((data) => {
      this.listaEstado = data;
    });
  }

  obtenerListaTractores() {
    this.servicioTractor
      .getTracto(this.noCdc, this.cveCia, this.statusTracto)
      .subscribe({
        next: (tractores) => {
          this.listaTractor = tractores;
        },
        error: (err) => {
          Swal.fire({
            title: err.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });
        },
      });
  }

  obtenerIdCiudadUno(ciudadNombre) {
    const result = this.listaEstado.find(
      (ciudad) => ciudad.nombre === ciudadNombre
    );
    if (result != null) {
      this.estado_uno = result.nombre;
      this.id_estado1 = result.id_estado;
      this.id_pais1 = result.id_pais;
      this.pais_uno = result.pais;
    }
  }

  obtenerIdCiudadDos(ciudadNombre) {
    const result = this.listaEstado.find(
      (ciudad) => ciudad.nombre === ciudadNombre
    );
    if (result != null) {
      this.estado_dos = result.nombre;
      this.id_estado2 = result.id_estado;
      this.id_pais2 = result.id_pais;
      this.pais_dos = result.pais;
    }
  }

  obtenerNombreEstado(idEstado) {
    const result = this.listaEstado.find(
      (ciudad) => ciudad.id_estado === idEstado
    );
    if (result != null) {
      this.estado_dos = result.nombre;
      this.id_estado2 = result.id_estado;
      this.id_pais2 = result.id_pais;
      this.pais_dos = result.pais;
    }
  }
  getReportes() {
    this.apiReporte.getReporte().subscribe((data) => {
      this.listaAccid_Bloq = data;
      for (let accid_bloqueo of this.listaAccid_Bloq) {
        accid_bloqueo.fec_alta = moment
          .utc(accid_bloqueo.fec_alta)
          .format('DD-MM-YYYY HH:mm');
        if (accid_bloqueo.ind_estado == 'PV') {
          accid_bloqueo.ind_estado = 'PENDIENTE DE VERIFICAR';
        } else {
          accid_bloqueo.ind_estado = 'ACTIVO';
        }
      }
      this.columnDefs = [
        { field: 'fec_alta', headerName: 'FECHA', sortable: true, width: 200, floatingFilterComponentParams: { suppressFilterButton: true }},
        { field: 'asunto', headerName: 'ASUNTO', sortable: true, width: 300, floatingFilterComponentParams: { suppressFilterButton: true }},
        { field: 'id_caja',headerName: 'UNIDADES INVOLUCRADAS', sortable: true, width: 275, floatingFilterComponentParams: { suppressFilterButton: true }},
        { field: 'descripcion',  headerName: 'DESCRIPCION',  sortable: true,  width: 375,  floatingFilterComponentParams: { suppressFilterButton: true }},
        { field: 'ind_estado',  headerName: 'ESTATUS', sortable: true, width: 200, floatingFilterComponentParams: { suppressFilterButton: true }},
        { field: 'id_usuario_alta', headerName: 'USUARIO ALTA', sortable: true, width: 350, floatingFilterComponentParams: { suppressFilterButton: true }},
        { field: 'editar', width: 165, filter: false,cellRenderer: function (params) { return '<i class="far fa-edit" style="color:#BF343F;"></i>';}},
        { field: 'eliminar', width: 165, filter: false, cellRenderer: function (params) { return '<i class="fas fa-minus-circle" style="color:#BF343F;"></i>';}},
      ];
      this.rowData = this.listaAccid_Bloq;
    });
  }

  openModal(reporte, accion) {
    this.correoDestino = `${this.correos[15]['correo']} , ${this.correos[14]['correo']}`
    if (accion == 'edit') {
      this.modalTitle = 'Actualizar Reporte';
      this.idAccBloqueo = reporte.id_reporte_acc;
      this.asunto = reporte.asunto;
      this.descripcion = reporte.descripcion;
      this.unidad_invo = reporte.id_tractor;
      this.enlace_ext = reporte.enlace_externo;
      this.notificar = reporte.notificacion_area;
      this.ciudad_uno = reporte.entre_punto1;
      this.ciudad_dos = reporte.entre_punto2;
      let id_estado_uno = reporte.id_estado_punto1;
      if (id_estado_uno != '') {
        const result = this.listaEstado.find(
          (estado) => estado.id_estado === id_estado_uno
        );
        if (result != null) {
          this.estado_uno = result.nombre;
          this.id_estado1 = result.id_estado;
          this.id_pais1 = result.id_pais;
          this.pais_uno = result.pais;
        }
      }
      this.status = reporte.ind_estado;
      let id_estado_dos = reporte.id_estado_punto2;
      if (id_estado_dos != '') {
        const result = this.listaEstado.find(
          (estado) => estado.id_estado === id_estado_dos
        );
        if (result != null) {
          this.estado_dos = result.nombre;
          this.id_estado2 = result.id_estado;
          this.id_pais2 = result.id_pais;
          this.pais_dos = result.pais;
        }
      }
    } else if (accion == 'agregar') {
      this.modalTitle = 'Nuevo Reporte';
      this.asunto = '';
      this.unidad_invo = '';
      this.enlace_ext = '';
      this.descripcion = '';
      this.notificar = '';
      this.ciudad_uno = '';
      this.ciudad_dos = '';
      this.estado_uno = '';
      this.estado_dos = '';
      this.pais_uno = '';
      this.pais_dos = '';
    }
  }

  notificarArea() {
    let area = '';
    let correo = '';
    if ($('#ch_operaciones').prop('checked') == true) {
      area = area === '' ? 'OPERACIONES REFRIGERADO' : area + ', OPERACIONES REFRIGERADO';
      correo =  correo === ''  ? `${this.correos[15]['correo']}` : correo + `, ${this.correos[15]['correo']}`;
    }
    if ($('#ch_rh').prop('checked') == true) {
      area = area === '' ? 'RH' : area + ', RH';
      correo =  correo === '' ? `${this.correos[16]['correo']}`  : correo + `, ${this.correos[16]['correo']}`;
    }
    if ($('#ch_mant').prop('checked') == true) {
      area = area === '' ? 'MANTENIMIENTO' : area + ', MANTENIMIENTO';
      correo =  correo === ''  ? `${this.correos[17]['correo']}` : correo + `, ${this.correos[17]['correo']}`;
    }
    if ($('#ch_atClientes').prop('checked') == true) {
      area = area === '' ? 'AT. A CLIENTES' : area + ', AT. A CLIENTE';
      correo =  correo === ''  ? `${this.correos[18]['correo']}` : correo + `, ${this.correos[18]['correo']}`;
    }
    if ($('#ch_operaciones_seco').prop('checked') == true) {
      area = area === '' ? 'OPERACIONES SECO' : area + ', OPERACIONES SECO';
      correo =  correo === '' ? `${this.correos[14]['correo']}`  : correo + `, ${this.correos[14]['correo']}`;
    }
    this.area = area;
    this.areaNotificar = this.area;
    this.correoDestino = correo;
  }

  setWidthAndHeight(width, height) {
    this.style = {
      flex: '20px',
      width: width,
      height: height,
    };
  }
  onGridReady(params) {
    this.gridColumnApi = params.columnApi;
    var columnState = JSON.parse(localStorage.getItem('myColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }
  onColumnMoved(e) {
    let columnState = JSON.stringify(e.columnApi.getColumnState());
    localStorage.setItem('myColumnState', columnState);
  }

  onColumnVisible(e) {
    console.log('Event Column Visible', e);
  }
  onCellClicked(e) {
    this.dato = e['data'];
    this.nombreCol = e['colDef']['field'];
    if (this.nombreCol == 'editar') {
      this.openModal(this.dato, 'edit');
      jQuery.noConflict();
      $('#mdl_generar_repo').modal('show');
      $('#mdl_generar_repo').draggable({
        handle: '.modal-header',
      });
    }
    if (this.nombreCol == 'eliminar') {
      this.deletReporte(this.dato['id_reporte_acc']);
    }
    if (this.nombreCol == 'tipo' || this.nombreCol == 'descripcion') {
      //this.detailMenssage(this.dato)
    }
  }

  saveReporte() {
    let unidades = []
    
    this.unidadesInvolucradas.forEach(unidad =>{
      const result = this.listaTractor.find(
        (tractor) => tractor.no_cdc === unidad.id_unidad
      );
      if (result != null) {
        const arrayUnidades = {
          numero_unidad: result.no_cdc.trim(),
          id_compania: result.cia 
        }
        unidades.push(arrayUnidades);
      }
    })
    if (this.modalTitle == 'Actualizar Reporte') {
      let idRepAccBloq = this.idAccBloqueo;
      let asunto = this.asunto;
      let descripcion = this.descripcion;
      let enlace_externo = this.enlace_ext;
      let entre_punto1 = this.ciudad_uno.toUpperCase();
      let entre_punto2 = this.ciudad_dos.toUpperCase();
      let id_ciudad_punto1 = this.id_ciudad1;
      let id_estado_punto1 = this.id_estado1;
      let id_pais_punto1 = this.id_pais1;
      let id_ciudad_punto2 = this.id_ciudad2;
      let id_estado_punto2 = this.id_estado2;
      let id_pais_punto2 = this.id_pais2;
      let notificacion_area = this.areaNotificar;
      let unidad_invo = localStorage.getItem('id_usuario');
      let operador = this.operador;
      let ind_estado = this.status;
      if (ind_estado == 'PENDIENTE DE VERIFICAR') {
        ind_estado = 'PV';
      } else {
        ind_estado = 'A';
      }
      this.redactarCorreo();
      this.apiReporte.putReporte(idRepAccBloq, asunto, descripcion, enlace_externo, entre_punto1, entre_punto2, id_ciudad_punto1, id_estado_punto1,
          id_pais_punto1, id_ciudad_punto2, id_estado_punto2, id_pais_punto2, notificacion_area, unidad_invo, operador, ind_estado).subscribe((datos) => {
            if (datos == null) {
              $(document).ready(function () {
                jQuery.noConflict();
                $('#mdl_generar_repo').modal('hide');
              });
              Swal.fire({
                title: 'REPORTE CREADO CORRECTAMENTE',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  this.getReportes();
                }
              });
            }
          });
        } else {
          let body = {
            'asunto': this.asunto,
            'descripcion': this.asunto,
            'enlace_externo': this.enlace_ext,
            'entre_punto1':this.ciudad_uno ? this.ciudad_uno: null,
            'entre_punto2': this.ciudad_dos ?this.ciudad_dos: null,
            'id_ciudad_punto1': this.id_ciudad1 ? this.id_ciudad1 : null,
            'id_estado_punto1': this.id_estado1 ? this.id_estado1 : null,
            'id_pais_punto1':  this.id_pais1 ? this.id_pais1 : null,
            'id_ciudad_punto2': this.id_ciudad2 ? this.id_ciudad2 : null,
            'id_estado_punto2': this.id_estado2 ? this.id_estado2 : null,
            'id_pais_punto2': this.id_pais2 ? this.id_pais2 : null,
            'notificacion_area': this.areaNotificar,
            'id_usuario_alta': localStorage.getItem('id_usuario'),
            'unidad': unidades
          }
          this.redactarCorreo();
          this.apiReporte.postReporte(body).subscribe({
            next: (data) => {
              if (data['id_nuevo_reporte'] != ' ') {
                $(document).ready(function () {
                  jQuery.noConflict();
                  $('#mdl_generar_repo').modal('hide');
                });
                Swal.fire({
                  title: 'REPORTE CREADO CORRECTAMENTE',
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.getReportes();
                  }
                });
              }
            },
            error: (error) => {
              if (localStorage.getItem('token')) {
                if (error.status == 401) {
                  this.generalService.refreshToken();
                } else {
                  $(document).ready(function () {
                    jQuery.noConflict();
                    $('#mdl_generar_pen').modal('hide');
                  });
                  this.alertReporte('ERROR', 'Error al generar el reporte', 'error');
                }
              }
            },
          });
         }
        }
        
  deletReporte(id: string) {
    this.apiReporte.deleteReporte(id).subscribe((dato) => {
      if (dato == null) {
        Swal.fire({
          title: 'REPORTE ELIMINADO CORRECTAMENTE',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.getReportes();
          }
        });
      }
    });
  }
  obtenerCorreos() {
    this.apiAlerta.getCorreosAlertas().subscribe((correos) => {
      this.correos = correos;
    });
  }

  alertReporte(title, text, icon) {
    Swal.fire({
      title: title,
      icon: icon,
      text: text,
      customClass: {
        confirmButton: 'btn btn-success',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.getReportes();
      }
    });
  }

  redactarCorreo() {
    if (this.correoDestino != '' && this.unidad_invo != '') {
      this.correoAccidenteBloqueo = `${this.correoDestino}?cc=${this.correos[2]['correo']}, ${this.correos[0]['correo']}, ${this.correos[1]['correo']}, ${this.correos[3]['correo']}&subject=REPORTE%20DE%20ACCIDENTE%20Y/O%20BLOQUEO:%20${this.asunto}
      &body=DESCRIPCION: ${this.descripcion} %0DENLACE(S) EXTERNO(S): ${this.enlace_ext} %0DUNIDAD(ES) INVOLUCRADA(S): ${this.unidad_invo} %0DNUMERO DE OPERADOR: ${this.operador} %0DUBICACION DEL ACCIDENTE Y/O BLOQUEO%0DPUNTO%201:%0DCIUDAD: ${this.ciudad_uno} %0DESTADO: ${this.estado_uno} %0DPAIS: ${this.pais_uno} %0DPUNTO%202:%0DCIUDAD: ${this.ciudad_dos} %0DESTADO: ${this.estado_dos} %0DPAIS: ${this.pais_dos}`;
    } else if (this.correoDestino != '' && this.unidad_invo == '') {
      this.correoAccidenteBloqueo = `${this.correoDestino}?cc=${this.correos[2]['correo']}, ${this.correos[0]['correo']}, ${this.correos[1]['correo']}, ${this.correos[3]['correo']}&subject=REPORTE%20DE%20ACCIDENTE%20Y/O%20BLOQUEO:%20${this.asunto}
      &body=DESCRIPCION: ${this.descripcion} %0DENLACE(S) EXTERNO(S): ${this.enlace_ext} %0DUBICACION DEL ACCIDENTE Y/O BLOQUEO%0DPUNTO%201:%0DCIUDAD: ${this.ciudad_uno} %0DESTADO: ${this.estado_uno} %0DPAIS: ${this.pais_uno} %0DPUNTO%202:%0DCIUDAD: ${this.ciudad_dos} %0DESTADO: ${this.estado_dos} %0DPAIS: ${this.pais_dos}`;
    } else if (this.correoDestino == '' && this.unidad_invo == '') {
      this.correoAccidenteBloqueo = `${this.correos[2]['correo']}?cc=${this.correos[0]['correo']}, ${this.correos[1]['correo']}, ${this.correos[3]['correo']}&subject=REPORTE%20DE%20ACCIDENTE%20Y/O%20BLOQUEO:%20${this.asunto}
      &body=DESCRIPCION: ${this.descripcion} %0DENLACE(S) EXTERNO(S): ${this.enlace_ext} %0DUBICACION DEL ACCIDENTE Y/O BLOQUEO%0DPUNTO%201: %0DCIUDAD: ${this.ciudad_uno} %0DESTADO: ${this.estado_uno} %0DPAIS: ${this.pais_uno} %0DPUNTO%202: %0DCIUDAD: ${this.ciudad_dos} %0DESTADO: ${this.estado_dos} %0DPAIS: ${this.pais_dos}`;
    } else if (this.correoDestino == '' && this.unidad_invo != '') {
      this.correoAccidenteBloqueo = `${this.correos[2]['correo']}?cc=${this.correos[0]['correo']}, ${this.correos[1]['correo']}, ${this.correos[3]['correo']}&subject=REPORTE%20DE%20ACCIDENTE%20Y/O%20BLOQUEO:%20${this.asunto}
      &body=DESCRIPCION: ${this.descripcion} %0DENLACE(S) EXTERNO(S): ${this.enlace_ext} %0DUNIDAD(ES) INVOLUCRADA(S): ${this.unidad_invo} %0DNUMERO DE OPERADOR: ${this.operador} %0DUBICACION DEL ACCIDENTE Y/O BLOQUEO%0DPUNTO%201: %0DCIUDAD: ${this.ciudad_uno} %0DESTADO: ${this.estado_uno} %0DPAIS: ${this.pais_uno} %0DPUNTO%202: %0DCIUDAD: ${this.ciudad_dos} %0DESTADO: ${this.estado_dos} %0DPAIS: ${this.pais_dos}`;
    }
  }

  unidadInvolucrada() {
    let tractores = this.listaTractor.length;
    let listaTractor = '';
    let numeroTractor = '';
    this.unidadesInvolucradas = []
    let listaUnidades = {}
    $(document).ready(function () {
      $('.dropdown-menu').on('click', function (e) {
        e.stopPropagation();
      });
    });
    for (let i = 0; i < tractores; i++) {
      if ($('#dm_unidad-' + i).prop('checked')) {
        numeroTractor = $('#dm_unidad-' + i).val();
        listaTractor = listaTractor === '' ? $('#dm_unidad-' + i).val(): listaTractor + '' + $('#dm_unidad-' + i).val();
        listaUnidades = listaUnidades === '' ? {id_unidad: $('#dm_unidad-' + i).val()} : this.unidadesInvolucradas.push({id_unidad: $('#dm_unidad-' + i).val()});
      }
      this.unidad_invo = listaTractor;
    }
  }
}