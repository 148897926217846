<main role="main" class="col-12" [ngClass]="{'pl-menu': !navbar}">
    <h5 class="mt-2">CATALOGO - MENSAJES</h5>
    <div class="row">
        <!--TOOLBAR-->
        <div class="form-inline">
            
        </div>
    </div>
    <div class="col-12 mt-5">
        <div class="row">
            <div class="input-group input-group-sm">
                <button type="submit" class="btn btn-primary" data-toggle="modal" data-target="#mdl_agr_men" (click)="openModal('','add')">AGREGAR NUEVO MENSAJE</button>
            </div>
        </div>
        <br>
        <div [ngStyle]="style">
            <ag-grid-angular #agGrid_mensaje class="ag-theme-alpine"
                [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowData]="rowData"
                (cellClicked)="onCellClicked($event)" [sideBar]="false"
                (columnMoved)="onColumnMoved($event)" (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
</main>
<!--Modal detalle mensaje-->
<div class="modal fade" id="mdl_det_mensaje" tabindex="-1" role="dialog" aria-labelledby="mdl_det_mensaje" aria-hidden="true" data-backdrop="static" style="z-index: 10000;" >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header bg-primary d-block">
                <button type="button" class="close color-white float-right" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title color-white text-center" id="mdl_det_mensaje">MENSAJE</h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <fieldset disabled>
                    <div class="form-group row form-inline justify-content-start">
                        <label>Nombre:</label>
                        <div class="input-group input-group-md col">
                             <input id="txt_nom_mensaje" type="text" class="form-control"  aria-label="Username" aria-describedby="basic-addon1" autocomplete="off" value="{{this.datosMensaje.tipo}}">
                        </div>          
                    </div>
                    <div class="form-group row form-inline justify-content-start" >
                        <label>STATUS:</label>
                        <div class="input-group input-group-md col">
                             <input id="txt_status" type="text" class="form-control text-uppercase"  aria-label="Username" aria-describedby="basic-addon1" autocomplete="off" value="{{this.datosMensaje.activo}}">
                        </div>
                        <label>USUARIO:</label>
                        <div class="input-group input-group-md col">
                             <input id="txt_usuario" type="text" class="form-control text-uppercase"  aria-label="Username" aria-describedby="basic-addon1" autocomplete="off" value="{{this.datosMensaje.id_usuario_alta}}">
                        </div>
                    </div>
                    <div class="form-group text-center">
                        <label>DESCRIPCIÓN</label>
                        <textarea id="txt_comentarios" class="form-control input-frio-caja" rows="6" style="white-space: pre-line;">{{this.datosMensaje.descripcion}}</textarea>
                    </div>
                </fieldset>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Modal Agregar Mensaje-->
<div class="modal fade" id="mdl_agr_men" tabindex="-1" role="dialog" aria-labelledby="mdl_agr_men" aria-hidden="true" data-backdrop="static" style="z-index: 10000;" >
    <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header bg-primary d-block">
                <button type="button" class="close color-white float-right" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title color-white text-center" id="mdl_agr">{{this.modalTitle}}</h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <form>
                        <div class="form-group row justify-content-start">
                            <label>Nombre: </label>
                            <div class="input-group input-group-sm">
                                  <input id="txt_tipo_mensaje" [(ngModel)]="selectTipoMens" name="selectTipoMens" type="text" class="form-control text-uppercase"  aria-label="Username" aria-describedby="basic-addon1" autocomplete="off">
                            </div>          
                        </div>
                        <div class="form-group row justify-content-start">
                            <label>DESCRIPCIÓN</label>
                            <textarea id="txt_comentarios" class="form-control input-frio-caja" rows="6" [(ngModel)]="descrip" name="descrip" style="white-space: pre-line;">
                            </textarea>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-dismiss="modal">Cancelar</button>
                            <button type="submit" class="btn btn-primary" (click)="saveMensaje()">Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>