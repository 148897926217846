import { Component, OnInit, Input } from '@angular/core';
import { CajaService } from 'src/app/service/caja/caja.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CanTokenService } from 'src/app/service/guards/can-token.service';

declare var $: any;
declare const jQuery: any;

@Component({
  selector: 'app-caja',
  templateUrl: './caja.component.html',
  styleUrls: ['./caja.component.css']
})
export class CajaComponent implements OnInit {

  cajaList: any = [];
  noCaja: string = '';
  cia: string = '';
  linTrans: string = '1';
  cajaInfo: any = [];
  lineaTransCaja: any = [];
  lineaCaja: any;
  lineaTrans_caja: any;
  ageType: any = '1';
  columnDefs: any = [];
  rowData: any;
  dato: any;
  nombreCol: any;
  gridColumnApi: any;
  defaultColDef: any;
  rowSelection: any;
  sideBar: any
  style = {
    width: '100%',
    height: '100%',
    flex: '1 1 auto'
  };
  daysValuesProvidedFilterParams: any = '';
  lineaFiltroCajas: any = [];
  gridApi: any;
  nombreLineaFiltro:any =''

  constructor(private apiCaja: CajaService,
    private spinnerService: NgxSpinnerService,
    private generalService: CanTokenService) {
    window.scroll(0, 0);
    this.defaultColDef = {
      resizable: true,
      width: 150,
      filter: true,
      sortable: true,
      floatingFilter: true,
    };
    this.rowSelection = 'multiple';
    this.sideBar = 'filters'
  }


  @Input() navbar: boolean = false;
  ngOnInit() {
    this.getCaja();
    this.lineaCajas();
  }
  getCaja() {
    let id_linea_caja = this.ageType.id_linea;
    if (!id_linea_caja) {
      id_linea_caja = '01'
    }
    this.spinnerService.show();
    this.apiCaja.getCaja(this.noCaja, this.cia, id_linea_caja, this.ageType.nombre_linea).subscribe({
      next: caja => {
        this.cajaList = caja;
        this.cajaList.sort(function (a, b){
          return a.status.localeCompare(b.status, 'ACTIVO', { numeric: true })
        })
        this.columnDefs = [
          { field: "division", headerName: "DIVISION", width: 300, floatingFilter: false, },
          { field: "no_caja", headerName: "NO.CAJA", sortable: true, filter: 'agTextColumnFilter', width: 300, floatingFilterComponentParams: { suppressFilterButton: true } },
          { field: "status", headerName: "STATUS", width: 250, floatingFilter: false, },
          { field: "no_serie", headerName: "NO.SERIE", sortable: true, filter: 'agTextColumnFilter', width: 300, floatingFilterComponentParams: { suppressFilterButton: true } },
          { field: "nombre", headerName: "LINEA TRANSPORTISTA", width: 300, floatingFilter: false, valueFormatter: this.printFilterModel, filterParams: this.daysValuesProvidedFilterParams },
          { field: 'poliza', width: 165, filter: false, cellRenderer: function (params) { return '<i class="far fa-edit" style="color:#BF343F;"></i>' } },
        ]
        this.rowData = this.cajaList
        this.spinnerService.hide()
      },
      error: error => {
        if (localStorage.getItem('token')) {
          if (error.status == 401) this.generalService.refreshToken();
        }
      }
    })
  }

  setWidthAndHeight(width, height) {
    this.style = {
      flex: '20px',
      width: width,
      height: height
    };
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    var columnState = JSON.parse(localStorage.getItem('myColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }
  onColumnMoved(e) {
    let columnState = JSON.stringify(e.columnApi.getColumnState());
    localStorage.setItem('myColumnState', columnState);
  }

  onColumnVisible(e) {
    console.log('Event Column Visible', e);
  }

  onCellClicked(e) {
    this.dato = e['data'];
    this.nombreCol = e['colDef']['field'];
    if (this.nombreCol == 'no_caja') {
      this.infoModal(this.dato);
      jQuery.noConflict();
      $('#mdl_info_caja').modal('show');
      $('#mdl_info_caja').draggable({
        handle: ".modal-header"
      });
    }
    if (this.nombreCol == 'eliminar') {
    }
  }

  infoModal(dato: any) {
    let datos = JSON.parse(JSON.stringify(dato))
    let cia;
    if (dato['division'] == 'REFRIGERADO') {
      cia = '01';
    } else {
      cia = '16';
    }
    let noCaja = dato['no_caja'].trim();
    let idLinTrans = dato['lin_tra'];
    this.spinnerService.show();
    this.apiCaja.getCaja(noCaja, cia, idLinTrans).subscribe(info => {
      this.cajaInfo = info;
      this.spinnerService.hide();
    });
  }

  lineaCajas() {
    this.apiCaja.getLineaCaja().subscribe(lineaCaja => {
      this.lineaTransCaja = lineaCaja;
      let numeroCajas = lineaCaja.length
      let linea_caja = [];
      for (let i = 0; i < numeroCajas; i++) {
        let lineas = lineaCaja[i]
        let idLinasCajas = lineas.nombre;
        linea_caja = linea_caja.length > 1 ? [idLinasCajas] : this.lineaFiltroCajas.push([idLinasCajas]);
      }
      this.daysValuesProvidedFilterParams = {
        values: this.lineaFiltroCajas,
        suppressSorting: false,
        defaultToNothingSelected: true,
        showTooltips: true,
      }
    });
  }
  countryValueFormatter = (params) => {
    let filterModel = this.gridApi.getFilterModel();
  }
  getFilterInstance() {
    return this.gridApi.getFilterInstance('nombre');
  }

  filtrarLineaCaja() {
    let numeroCajas = this.lineaTransCaja.length
    let lineaCaja = '';
    $(document).ready(function () {
      //Para que no se cierre el dropdown al selecciona una opcion
      $('.dropdown-menu').on('click', function (e) {
        e.stopPropagation();
      });
    })
    for (let i = 0; i < numeroCajas; i++) {
      if ($('#dm_linea_prime-' + i).prop('checked')) {
        lineaCaja = lineaCaja === '' ? $('#dm_linea_prime-' + i).val() : lineaCaja + ',' + $('#dm_linea_prime-' + i).val();
      }
    }
    this.nombreLineaFiltro = lineaCaja;
    console.log(this.nombreLineaFiltro)
    let idLineaFiltro = this.lineaTransCaja.filter(linea =>{
      if (linea.nombre === this.nombreLineaFiltro) {
        return linea.cve_lineatras
      }
    })
    idLineaFiltro = idLineaFiltro[0] || {};
    this.ageType ={
      nombre_linea: this.nombreLineaFiltro,
      id_linea: idLineaFiltro.cve_lineatras
    }
    this.getCaja()
  }
  onFirstDataRendered(params) {
    let filterModel = this.gridApi.getFilterModel();
    params.api.getToolPanelInstance('filters');
  }
  printFilterModel = () => {
    let filterModel = this.gridApi.getFilterModel();
  }
}
