import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/service/login/login.service';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermisosService {
  private headers: HttpHeaders;

  constructor(private httpApi: HttpClient, private apiAuth: LoginService) { }

  getPermisos(fecha:any):Observable<any>{
    let httpParams = new HttpParams()
    .append("fecha", fecha);
    return this.httpApi.get<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/permisos?${httpParams}`)
    .pipe(map(data => data));
  }
}
