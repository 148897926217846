<main role="main" class="col-12" [ngClass]="{'pl-menu': !navbar}">
    <h5 class="mt-2">PENDIENTES</h5>
    <div class="row">
        <!--TOOLBAR-->
        <div class="form-inline col-12">
            <div class="dropdown">
                <button class="btn frio-btn-toolbar dropdown-toggle btn-sm mr-2" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    TURNO
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_turno_matu" (change)="filtroTurno()">
                            <label class="custom-control-label" for="dp_turno_matu">MATUTINO</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_turno_ves" (change)="filtroTurno()">
                            <label class="custom-control-label" for="dp_turno_ves">VESPERTINO</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_turno_noc" (change)="filtroTurno()">
                            <label class="custom-control-label" for="dp_turno_noc">NOCTURNO</label>
                        </div>
                    </a>
                </div>
            </div>
            <div class="dropdown">
                <button class="btn frio-btn-toolbar dropdown-toggle btn-sm mr-2" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    FLOTA REFRIGERADO
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_flota1_ref"
                                (change)="filtroflotaPendiente()">
                            <label class="custom-control-label" for="dp_flota1_ref">FLOTILLA (1)  RAFAEL ROBLES</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_flota2_ref"
                                (change)="filtroflotaPendiente()">
                            <label class="custom-control-label" for="dp_flota2_ref">FLOTILLA (2) GERARDO QUEZADA</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_flota3_ref"
                                (change)="filtroflotaPendiente()">
                            <label class="custom-control-label" for="dp_flota3_ref">FLOTILLA (3) SALVADOR MARTINEZ</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_flota4_ref"
                                (change)="filtroflotaPendiente()">
                            <label class="custom-control-label" for="dp_flota4_ref">FLOTILLA (4) GERARDO GUERRERO</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_flota5_ref"
                                (change)="filtroflotaPendiente()">
                            <label class="custom-control-label" for="dp_flota5_ref">FLOTILLA (5) MIGUEL DAVILA</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_flota6_ref"
                                (change)="filtroflotaPendiente()">
                            <label class="custom-control-label" for="dp_flota6_ref">FLOTILLA (6) JULIO CESAR LARA</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_flota7_ref"
                                (change)="filtroflotaPendiente()">
                            <label class="custom-control-label" for="dp_flota7_ref">FLOTILLA (7) FRANCISCO LOPEZ</label>
                        </div>
                    </a>
                </div>
            </div>
            <div class="dropdown">
                <button class="btn frio-btn-toolbar dropdown-toggle btn-sm mr-2" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    FLOTA SECO
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_flota1_sec"
                                (change)="filtroflotaPendiente()">
                            <label class="custom-control-label" for="dp_flota1_sec">FLOTILLA (11) ENRIQUE TALAMANT</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_flota2_sec"
                                (change)="filtroflotaPendiente()">
                            <label class="custom-control-label" for="dp_flota2_sec">FLOTILLA (12) EDGAR PADILLA</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_flota3_sec" (change)="filtroflotaPendiente()">
                            <label class="custom-control-label" for="dp_flota3_sec">FLOTILLA (13) EDGAR DE LUNA</label>
                        </div>
                    </a>
                </div>
            </div>
            <div class="row" style="margin: 10px;">
                <div class="form-group form-inline">
                    <label class="form-group" style="color:#004680 ;" for="ch_viaje_alerta">ULTIMA ALERTA: </label>
                    <input class="input-group input-group-sm col-sm-4" style="margin-left: 2px;" type="text" [(ngModel)]="ultima_alerta" name="ultima_alerta" readonly>
                </div>
            </div>
            <div class="row">
                <div class="input-group input-group-sm mr-2">
                    <button type="submit" class="btn btn-primary" style="font-size: small;" data-toggle="modal" data-target="#mdl_generar_pen"
                        (click)="openModal('','add')">GENERAR PENDIENTE</button>
                </div>
            </div>
        </div>
        <!--/TOOLBAR-->
    </div>
    <div class="col-12 mt-3">
        <br>
        <div [ngStyle]="style">
            <ag-grid-angular #agGrid_pendiente class="ag-theme-alpine" [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef" [rowData]="rowData" (cellClicked)="onCellClicked($event)"
                [sideBar]="false" (columnMoved)="onColumnMoved($event)" (gridReady)="onGridReady($event)"
                [getRowStyle]="rowClassRules" (filterChanged)="onFilterChanged($event)" (firstDataRendered)="onFirstDataRendered($event)">
            </ag-grid-angular>
        </div>
    </div>
</main>
<!--Modal para agregar pendiente-->
<div class="modal fade" id="mdl_generar_pen" tabindex="-1" role="dialog" aria-labelledby="mdl_generar_pen"
    aria-hidden="true" data-backdrop="static" style="z-index: 10000;">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header bg-primary d-block">
                <button type="button" class="close color-white float-right" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title color-white text-center" id="mdl_generar_pen">{{this.modalTitle}}</h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <form>
                        <div class="form-group row justify-content-start">
                            <div class="col-12">
                                <h5>DATOS GENERALES DEL PENDIENTE</h5>
                                <hr>
                                <div class="row">
                                    <div class="form-group col-sm-6" >
                                        <label>TURNO:</label>
                                        <div class="input-group input-group-sm">
                                            <select class="custom-select" id="inp_turno" [(ngModel)]="turno" [disabled]="this.modalTitle == 'Detalle del pendiente' || this.modalTitle == 'Editar Pendiente'"
                                                name="turno">
                                                <option value="MATUTINO">MATUTINO</option>
                                                <option value="VESPERTINO">VESPERTINO</option>
                                                <option value="NOCTURNO">NOCTURNO</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group col-sm-6">
                                        <label>TIPO PENDIENTE:</label>
                                        <div class="input-group input-group-sm">
                                            <select class="custom-select" id="inp_pendiente" [disabled]="this.modalTitle == 'Detalle del pendiente' || this.modalTitle == 'Editar Pendiente'"
                                                [(ngModel)]="tipo_pendiente" name="tipo_pendiente">
                                                <option value="VIAJE">VIAJE</option>
                                                <option value="OPERADOR">OPERADOR</option>
                                                <option value="GENERAL">GENERAL</option>
                                                <option value="SEGUIMIENTO ESPECIAL">SEGUIMIENTO ESPECIAL</option>
                                                <option value="ALERTA">ULTIMA ALERTA</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="tipo_pendiente != 'ALERTA'">
                                    <div class="form-group col-sm-4">
                                        <label>NO.VIAJE:</label>
                                        <div class="input-group input-group-sm ">
                                            <input id="txt_no_viaje" type="text" class="form-control"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off" [disabled]="this.modalTitle == 'Detalle del pendiente' || this.modalTitle == 'Editar Pendiente'"
                                                [(ngModel)]="no_viaje" name="no_viaje">
                                        </div>
                                    </div>
                                    <div class="form-group col-sm-4">
                                        <label>NO.OPERADOR:</label>
                                        <div class="input-group input-group-sm ">
                                            <input id="txt_no_oper" type="text" class="form-control"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off" [disabled]="this.modalTitle == 'Detalle del pendiente' || this.modalTitle == 'Editar Pendiente'"
                                                [(ngModel)]="no_operador" name="no_operador">
                                        </div>
                                    </div>
                                    <div class="form-group col-sm-4" >
                                        <label>NO.TRACTOR:</label>
                                        <div class="input-group input-group-sm ">
                                            <input id="txt_no_oper" type="text" class="form-control" 
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off" [disabled]="this.modalTitle == 'Detalle del pendiente' || this.modalTitle == 'Editar Pendiente'"
                                                [(ngModel)]="no_unidad" name="no_unidad">
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="tipo_pendiente != 'ALERTA'">
                                    <div class="form-group col-sm" >
                                        <label>FLOTA:</label>
                                        <div class="input-group input-group-sm ">
                                            <select class="custom-select" id="inp_pendiente" [disabled]="this.modalTitle == 'Detalle del pendiente' || this.modalTitle == 'Editar Pendiente'"
                                                [(ngModel)]="no_flota" name="no_flota"  (change)="obtenerIdFlota(no_flota)">
                                                <option value = "ESPECIAL">ESPECIAL</option>
                                                <option *ngFor="let flota of flotas">{{flota.flota}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-start" *ngIf="this.modalTitle == 'Editar Pendiente' || this.modalTitle == 'Detalle del pendiente'">
                                    <div class="col-4 align-self-start">
                                        <div class="form-group">
                                            <label>STATUS:</label>
                                            <div class="input-group input-group-sm ">
                                                <select class="custom-select" id="inp_status" [(ngModel)]="estatus" [disabled]="this.modalTitle == 'Detalle del pendiente' "
                                                    name="estatus">
                                                    <option value="Pendiente">PENDIENTE</option>
                                                    <option value="Resuelto">RESUELTO</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-8" *ngIf="estatus == 'Resuelto'">
                                        <div class="form-group">
                                            <label>TURNO QUE ATENDIO EL PENDIENTE:</label>
                                            <div class="input-group input-group-sm">
                                                <select class="custom-select" id="inp_turno_resolvio"
                                                    [(ngModel)]="turno_resolvio" name="turno_resolvio" [disabled]="this.modalTitle == 'Detalle del pendiente' ">
                                                    <option value="MATUTINO">MATUTINO</option>
                                                    <option value="VESPERTINO">VESPERTINO</option>
                                                    <option value="NOCTURNO">NOCTURNO</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h5 *ngIf="tipo_pendiente != 'ALERTA'">DESCRIPCIÓN</h5>
                                <hr *ngIf="tipo_pendiente != 'ALERTA'">
                                <div class="form-group" *ngIf="tipo_pendiente != 'ALERTA'">
                                    <textarea id="txt_comentarios" class="form-control input-group input-group-sm" rows="6" [disabled]="this.modalTitle == 'Detalle del pendiente'"
                                        [(ngModel)]="descripcion" name="descripcion">
                                    </textarea>
                                </div>
                                <label  *ngIf="tipo_pendiente == 'ALERTA'">NUMERO DE ALERTA:</label>
                                    <div class="input-group input-group-sm col-sm-6"  *ngIf="tipo_pendiente == 'ALERTA'">
                                        <input id="txt_no_oper" type="text" class="form-control" 
                                        aria-label="Username" aria-describedby="basic-addon1" autocomplete="off" [disabled]="this.modalTitle == 'Detalle del pendiente' || this.modalTitle == 'Editar Pendiente'"
                                        [(ngModel)]="no_unidad" name="no_unidad">
                                    </div>
                            </div>
                        </div>
                        <div class="modal-footer" *ngIf="this.modalTitle != 'Detalle del pendiente'">
                            <button type="button" class="btn btn-danger" data-dismiss="modal">Cancelar</button>
                            <button type="submit" class="btn btn-primary" (click)="addPendiente()">Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>