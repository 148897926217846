import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpUrlEncodingCodec } from 'src/app/service/login/parameterCodec';
import { map } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CanTokenService{
  private loadingSubject = new Subject<any>();
  private hideLoadingSubject = new Subject<any>();

  constructor(private httpApi: HttpClient, private cookieService: CookieService) { }

  showLoading() {
    this.loadingSubject.next();
  }

  onShowLoading(): Observable<any> {
    return this.loadingSubject.asObservable();
  }

  hideLoading() {
    this.hideLoadingSubject.next();
  }

  onHideLoading(): Observable<any> {
    return this.hideLoadingSubject.asObservable();
  }

  getUserToken(userId: string, appCode: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' });
    let httpParams = new HttpParams({ encoder: new HttpUrlEncodingCodec() })
      .append('USID', userId)
      .append('APPID', appCode);
    return this.httpApi.post(`${environment.URL_SERVIDOR}/api-interna/autenticacion/obtener-token `, httpParams, { headers: headers })
      .pipe(map(data => data));
  }

  refreshToken() {
    localStorage.clear()
    this.cookieService.delete('USID');
    window.location.href = environment.loginUrl;
  }
}
