import { Component, OnInit,Input } from '@angular/core';
import { TractorService } from 'src/app/service/tractor/tractor.service'; 
import { NgxSpinnerService } from 'ngx-spinner';
import { CanTokenService } from 'src/app/service/guards/can-token.service';

declare var $: any;
declare const jQuery: any;

@Component({
  selector: 'app-tracto',
  templateUrl: './tracto.component.html',
  styleUrls: ['./tracto.component.css']
})
export class TractoComponent implements OnInit {

  tractoList:any=[];
  tractoInfo:any=[];
  noCdc:string = '';
  cveCia:string = '';
  columnDefs:any = [];
  rowData:any;
  dato:any;
  nombreCol:any;
  gridColumnApi:any;
  defaultColDef:any;
  rowSelection:any;
  sideBar:any;
  style = {
    width: '100%',
    height: '100%',
    flex: '1 1 auto'
  };
  statusTracto:string = `'A'`

  constructor(
    private apiTracto: TractorService,
    private spinnerService: NgxSpinnerService,
    private generalService: CanTokenService ) { 
      window.scroll(0, 0);
      this.defaultColDef = {
        resizable: true,
        width: 150,
        filter: true,
      sortable: true,
      floatingFilter: true,
      };
      this.rowSelection = 'multiple';
    }
  
  @Input() navbar: boolean = false;
  ngOnInit(): void {
    this.getTracto(this.statusTracto);    
  }

  getTracto(status:string){
    this.spinnerService.show();
    this.statusTracto = status
    if(this.statusTracto == ''){
      this.statusTracto = `'A'`
    }
    this.apiTracto.getTracto(this.noCdc, this.cveCia, this.statusTracto).subscribe({
      next: tracto => {
        this.tractoList = tracto;
        this.columnDefs = [
          {field: "division", headerName:"DIVISION", sortable:true ,filter: 'agTextColumnFilter'  , width: 300, floatingFilterComponentParams: { suppressFilterButton: true }},
          {field: "no_cdc", headerName:"NO.TRACTOR", sortable: true,filter: 'agTextColumnFilter'  , width: 300, floatingFilterComponentParams: { suppressFilterButton: true }},
          {field: "status", headerName:"STATUS", sortable: true, filter: 'agTextColumnFilter' ,width: 250, floatingFilterComponentParams: { suppressFilterButton: true }},
          {field: "no_tmc", headerName: "NO.TMC", sortable: true,filter: 'agTextColumnFilter' ,width: 300, floatingFilterComponentParams: { suppressFilterButton: true }},
          {field: "placas", headerName: "PLACAS", sortable: true, filter: 'agTextColumnFilter',width: 300, floatingFilterComponentParams: { suppressFilterButton: true }},
          {field: "no_serie", headerName: "NO.SERIE", sortable: true,filter: 'agTextColumnFilter', width: 300, floatingFilterComponentParams: { suppressFilterButton: true }},
          {field: "razon_social", headerName: "COMPAÑIA", sortable: true,filter: 'agTextColumnFilter', width: 300, floatingFilterComponentParams: { suppressFilterButton: true }},
          {field: "poliza", width: 165, filter: false, cellRenderer: function (params) { return '<i class="far fa-edit" style="color:#BF343F;"></i>' } },
        ]
        this.rowData = this.tractoList;
        this.spinnerService.hide();
      },
      error: error => {
        if(localStorage.getItem('token')) {
          if (error.status == 401) this.generalService.refreshToken();
        }
      }
    })
  }
  setWidthAndHeight(width, height) {
    this.style = {
      flex: '20px',
      width: width,
      height: height
    };
  }
  onGridReady(params) {
    this.gridColumnApi = params.columnApi;
    var columnState = JSON.parse(localStorage.getItem('myColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }
  onColumnMoved(e) {
    let columnState = JSON.stringify(e.columnApi.getColumnState());
    localStorage.setItem('myColumnState', columnState);
  }

  onColumnVisible(e) {
    console.log('Event Column Visible', e);
  }

  onCellClicked(e) {
    this.dato = e['data'];
    this.nombreCol = e['colDef']['field'];
    if (this.nombreCol == 'no_cdc') {
      this.mostrarModal(this.dato);
      jQuery.noConflict();
      $('#mdl_info_tracto').modal('show');
      $('#mdl_info_tracto').draggable({
        handle: ".modal-header"
      });
    }
    if (this.nombreCol == 'poliza') {
    }
  }
  
  mostrarModal(dato:any){
    let cveCia;
    if (dato['division'] == 'REFRIGERADO') {
      cveCia = '01';
    }else{
      cveCia = '16';
    }
    let  noCdc = dato['no_cdc'];
    let status = ''
    this.apiTracto.getTracto(noCdc, cveCia, status).subscribe(tracto =>{
      this.tractoInfo = tracto;
    })
  }
  filtroStatusTractor(){
      let status = '';
      $(document).ready(function () {
        $('.dropdown-menu').on('click', function (e) {
          e.stopPropagation();
        });
      });
      if ($('#dp_accidentado').prop('checked') == true) {
        status = status === '' ? `'ACC'` : status + `,'ACC'`;
      }
      if ($('#dp_activo').prop('checked') == true) {
        status = status === '' ? `'A'` : status+ `,'A'`;
      }
      if ($('#dp_a_para_venta').prop('checked') == true) {
        status = status === '' ? `'APV'` : status + `,'APV'`;
      }
      if ($('#dp_inactivo').prop('checked') == true) {
        status = status === '' ? `'IN'` : status + `,'IN'`;
      }
      if ($('#dp_nuevo').prop('checked') == true) {
        status = status === '' ? `'N'` : status + `,'N'`;
      }
      if ($('#dp_para_venta').prop('checked') == true) {
        status = status === '' ? `'P'` : status + `,'P'`;
      }
      if ($('#dp_patio').prop('checked') == true) {
        status = status === '' ? `'PA'` : status + `,'PA'`;
  
      }
      if ($('#dp_servicio_ded').prop('checked') == true) {
        status = status === '' ? `'SD'` : status + `,'SD'`;
  
      }
      if ($('#dp_sin_oper').prop('checked') == true) {
        status = status === '' ? `'S'` : status + `,'S'`;
  
      }
      if ($('#dp_taller').prop('checked') == true) {
        status = status === '' ? `'T'` : status + `,'T'`;
  
      }
      this.statusTracto = status;
      this.getTracto(this.statusTracto);
  }
}
