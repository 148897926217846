import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  @Input() listaNavBar: any;
  @Input() navbar: boolean = false;
  @Output() seccion = new EventEmitter();
  @Output() alertaActivada = new EventEmitter();
  estadoAlerta: any;
  constructor(
  ) { 
  }

  ngOnInit() {
    let alertasActivadas = localStorage.getItem('alertas:');
    if (!alertasActivadas) {
      this.estadoAlerta = true;
    }else{
      this.estadoAlerta = false;
    }
  }


  seccionComponente(componente){
    this.seccion.emit(componente);
  }

  activarAlertas(){
    this.alertaActivada.emit(true)
  }

  mostrarBoton(){
  }
}
