import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/service/login/login.service';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HistoricoService {

  constructor(private httpApi: HttpClient, private apiAuth: LoginService) { }

  getHistoricos(tipo:string, cve_cia:string, valor:string, fechaIni:string, fechaFin:string, noDias:string):Observable<any>{
    let httpParams = new HttpParams()
    .append('tipo', tipo)
    .append('cveCia', cve_cia)
    .append('valor', valor)
    .append('fechaIni', fechaIni)
    .append('fechaFin', fechaFin)
    .append('noDias', noDias)
    return this.httpApi.get<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/historico?${httpParams}`);
  }
  setDatosHistoricos(tipoHistorico, cveCia, datoHistorico){
    localStorage.setItem("tipoHistorico", tipoHistorico);
    localStorage.setItem("cveCia", cveCia);
    localStorage.setItem("datoHistorico", datoHistorico);
  }
  getDatosHistoricos(){
    localStorage.getItem("tipoHistorico");
    localStorage.getItem("cveCia");
    return localStorage.getItem("datoHistorico");
  }
}
