import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LicenseManager } from 'ag-grid-enterprise';

if (environment.production) {
  enableProdMode();
}

LicenseManager.setLicenseKey("CompanyName=Frio Express S.A. de C.V.,LicensedGroup=frioexpress,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-016059,ExpiryDate=1_June_2022_[v2]_MTY1NDAzODAwMDAwMA==c8f2a8743e7a0979e632300973f63c38")

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
