<main role="main" class="col-12" [ngClass]="{'pl-menu': !navbar}">
  <h5 class="mt-2">ACCIDENTES Y BLOQUEOS</h5>
  <div class="row mt-2">
    <!--TOOLBAR-->
    <div class="input-group input-group-sm col-2">
      <button type="submit" class="btn btn-primary" data-toggle="modal" data-target="#mdl_generar_repo"
        (click)="openModal('','agregar')">GENERAR REPORTE</button>
    </div>
    <!--/TOOLBAR-->
  </div>
  <div class="col-12 mt-3">
    <br>
    <div [ngStyle]="style">
      <ag-grid-angular #agGrid_acc_bloqueo class="ag-theme-alpine" [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef" [rowData]="rowData" (cellClicked)="onCellClicked($event)" [sideBar]="false"
        (columnMoved)="onColumnMoved($event)" (gridReady)="onGridReady($event)"
       >
      </ag-grid-angular>
    </div>
  </div>
</main>
<!--Modal para agregar pendiente-->
<div class="modal fade" id="mdl_generar_repo" tabindex="-1" role="dialog" aria-labelledby="mdl_generar_pen"
  aria-hidden="true" data-backdrop="static" style="z-index: 10000;">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary d-block">
        <button type="button" class="close color-white float-right" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title color-white text-center" id="mdl_generar_repo">{{this.modalTitle}}</h5>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <form>
            <div class="form-row">
              <div class="form-group col-12 col-md-6 col-lg-4 input-group-sm">
                <label>ASUNTO:</label>
                <input id="txt_unidad" type="text" class="form-control form-control-sm" aria-label="Username"
                  aria-describedby="basic-addon1" autocomplete="off" [(ngModel)]="asunto" name="asunto">
              </div>
              <div class="form-group col-12 col-md-6 col-lg-4 input-group-sm">
                <label>ENLACES EXTERNOS:</label>
                <input id="txt_estatus" type="text" class="form-control form-control-sm" aria-label="Username"
                  aria-describedby="basic-addon1" autocomplete="off" [(ngModel)]="enlace_ext" name="asunto">
              </div>
              <div class="form-group col-12 col-md-6 col-lg-4 input-group-sm">
                <div class="dropdown">
                  <button class="btn frio-btn-toolbar dropdown-toggle btn-sm mr-2" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Unidad(es) Involucrada(s)
                  </button>
                  <div aria-labelledby="dropdownMenuButton">
                    <div class="dropdown-menu">
                      <input type="text" [(ngModel)]="filtro" name="filtro" class="form-control" style="height:auto; width:auto">
                      <div class="scrollable-menu">
                        <a class="dropdown-option">
                          <div *ngFor="let tractor of listaTractor | filtroAccBloqueo: filtro; let i = index ">
                            <label class="custom-control custom-checkbox float-left">
                              <input type="checkbox" id="dm_unidad-{{i}}" value="{{tractor.no_cdc}}"
                                (change)="unidadInvolucrada()" />
                                {{tractor.no_cdc}}
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <input id="txt_estatus" type="text" class="form-control form-control-sm" aria-label="Username"
                    aria-describedby="basic-addon1" autocomplete="off" [(ngModel)]="unidad_invo" name="unidad_invo" disabled>
                </div>
              </div>
              <div class="form-group col-12 col-md-6 col-lg-4 input-group-sm">
                <div class="dropdown">
                  <button class="btn frio-btn-toolbar dropdown-toggle btn-sm mr-2" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Notificar a:
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item frio-btn-toolbar">
                      <div class="custom-control custom-checkbox float-left">
                        <input type="checkbox" class="form-check-input" id="ch_operaciones" (change)=" notificarArea()">
                        <label for="ch_operaciones" class="form-check-label">OPERACIONES REFRIGERADO</label>
                      </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                      <div class="custom-control custom-checkbox float-left">
                        <input type="checkbox" class="form-check-input" id="ch_operaciones_seco"
                          (change)=" notificarArea()">
                        <label for="ch_operaciones_seco" class="form-check-label">OPERACIONES SECO</label>
                      </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                      <div class="custom-control custom-checkbox float-left">
                        <input type="checkbox" class="form-check-input" id="ch_atClientes" (change)=" notificarArea()">
                        <label for="ch_atClientes" class="form-check-label">ATENCION A
                          CLIENTES</label>
                      </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                      <div class="custom-control custom-checkbox float-left">
                        <input type="checkbox" class="form-check-input" id="ch_mant" (change)="notificarArea()">
                        <label for="ch_mant" class="form-check-label ">MANTENIMIENTO</label>
                      </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                      <div class="custom-control custom-checkbox float-left">
                        <input type="checkbox" class="form-check-input" id="ch_rh" (change)="notificarArea()">
                        <label for="ch_rh" class="form-check-label">RECURSOS HUMANOS</label>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group form-group col-12 col-md-6 col-lg-4 input-group-sm"
                *ngIf="this.modalTitle == 'Actualizar Reporte'">
                <label> STATUS:</label>
                <div class="input-group input-group-sm col-sm text-uppercase">
                  <select class="custom-select form-control-sm" id="estado_uno" [(ngModel)]="status" name="status">
                    <option>PENDIENTE DE VERIFICAR</option>
                    <option>ACTIVO</option>
                  </select>
                </div>
              </div>
            </div>
            <h5>UBICACION DEL ACCIDENTE O BLOQUEO</h5>
            <label>PUNTO 1:</label>
            <hr>
            <div class="form-row">
              <div class="form-group col-12 col-md-6 col-lg-4 input-group-sm">
                <label>CIUDAD: </label>
                <input id="txt_fecha_caducidad" type="text" class="form-control form-control-sm text-uppercase"
                  aria-label="Username" aria-describedby="basic-addon1" autocomplete="off" [(ngModel)]="ciudad_uno"
                  name="ciudad_uno" value="{{this.ciudad_uno}}">
              </div>
              <div class="form-group col-12 col-md-6 col-lg-4 input-group-sm"
                *ngIf="ciudad_uno != '' || modalTitle != 'Actualizar Reporte'">
                <label>ESTADO:</label>
                <select class="custom-select form-control-sm" id="estado_uno" [(ngModel)]="estado_uno" name="estado_uno"
                  (change)="obtenerIdCiudadUno(estado_uno)">
                  <option *ngFor="let estado of listaEstado">{{estado.nombre}}</option>
                </select>
              </div>
              <div class="form-group col-12 col-md-6 col-lg-4 input-group-sm"
                *ngIf="ciudad_uno != '' || modalTitle != 'Actualizar Reporte'">
                <label>PAIS: </label>
                <input id="txt_numero_factura" type="text" class="form-control" aria-label="Username"
                  aria-describedby="basic-addon1" autocomplete="off" [(ngModel)]="pais_uno" name="pais_uno"
                  value="{{this.pais_uno}}">
              </div>
            </div>
            <label>PUNTO 2:</label>
            <div class="form-row">
              <br>
              <div class="form-group col-12 col-md-6 col-lg-4 input-group-sm">
                <label>CIUDAD: </label>
                <input id="txt_fecha_caducidad" type="text" class="form-control text-uppercase" aria-label="Username"
                  aria-describedby="basic-addon1" autocomplete="off" [(ngModel)]="ciudad_dos" name="ciudad_dos"
                  value="{{this.ciudad_dos}">
              </div>
              <div class="form-group col-12 col-md-6 col-lg-4 input-group-sm"
                *ngIf="ciudad_uno != '' || modalTitle != 'Actualizar Reporte'">
                <label>ESTADO:</label>
                <select class="custom-select form-control-sm" id="estado_dos" [(ngModel)]="estado_dos" name="estado_dos"
                  (change)="obtenerIdCiudadDos(estado_dos)">
                  <option *ngFor="let estado of listaEstado">{{estado.nombre}}</option>
                </select>
              </div>
              <div class="form-group col-12 col-md-6 col-lg-4 input-group-sm"
                *ngIf="ciudad_uno != '' || modalTitle != 'Actualizar Reporte'">
                <label>PAIS: </label>
                <input id="txt_numero_factura" type="text" class="form-control" aria-label="Username"
                  aria-describedby="basic-addon1" autocomplete="off" [(ngModel)]="pais_dos" name="pais_dos"
                  value="{{this.pais_dos}">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group text-center col">
                <label>DESCRIPCIÓN:</label>
                <textarea id="txt_estatus" type="text" class="form-control form-control-sm" aria-label="Username"
                  aria-describedby="basic-addon1" autocomplete="off" [(ngModel)]="descripcion" name="descripcion"
                  rows="5"></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Cancelar</button>
              <a href="mailto:{{this.correoAccidenteBloqueo}}" target="_blank">
                <button type="button" class="btn btn-primary" (click)="saveReporte()">GUARDAR</button></a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>