<app-header [app]="aplicacion" (menu)="actualizaMenu($event)"></app-header>
<!-- [app] -> Se manda el nombre de la aplicacion, (menu) -> Comportamiento del menu lateral (Ocultar y Mostrar) -->
<div class="container-fluid">
    <div>
        <app-navbar (seccion)="actualizaVista($event)" [listaNavBar]="listaNav" [navbar]="navBar" (alertaActivada)="activarAlertasManual($event)"></app-navbar>
        <!-- (seccion) -> Actualiza la vista segun el componente seleccionado, [listaNavBar] -> Lista de las secciones que habrá en el navbar lateral, [navbar] -> Para el comportamiento de la pantalla, si el menu lateral esta oculto o no -->
        <app-inicio *ngIf="inicio" [navbar]="navBar"></app-inicio>
        <!-- [navbar] -> Para el comportamiento de la pantalla, si el menu lateral esta oculto o no -->
        <app-alertas *ngIf="alerta" [navbar]="navBar"></app-alertas>
        <app-principal *ngIf="reporte_Incid" [navbar]="navBar"></app-principal>
        <app-pendiente *ngIf="pendiente" [navbar]="navBar"></app-pendiente>
        <app-accidente-bloqueo *ngIf="accid_bloqueos" [navbar]="navBar"></app-accidente-bloqueo>
        <app-agenda-permiso *ngIf="agenda_permiso" [navbar]="navBar"></app-agenda-permiso>
        <app-caja *ngIf="caja" [navbar]="navBar"></app-caja>
        <app-operador *ngIf="operador" [navbar]="navBar"></app-operador>
        <app-tracto *ngIf="tracto" [navbar]="navBar"></app-tracto>
        <app-origen-destino *ngIf="origen_destino" [navbar]="navBar"></app-origen-destino>
        <app-parador-pension *ngIf="paradores_pension" [navbar]="navBar"></app-parador-pension>
        <app-mensaje *ngIf="mensaje" [navbar]="navBar"></app-mensaje>
    </div>

</div>
<div class=" fade modal-alert" id="mdl_ALERTA"  tabindex="-1" role="dialog" aria-labelledby="#mdl_info_oper"
aria-hidden="true" data-backdrop="static" style="z-index: 10000;">
    <div class="modal-dialog" role="document" style="width:390px;">
        <div class="modal-content">
            <div class="modal-header bg-primary d-block">
                <button type="button" class="close color-white float-right" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title color-white text-center">ALERTA</h5>
            </div>
            <div class="modal-body" [ngStyle]="styleAlert">
                <div class="container-fluid">
                    <form>
                        <div class="form-group row">
                            <label class="label-alerta">TIPO:</label>
                            <input id="txt_tipo_alerta" type="text" class="form-control form-control-sm text-uppercase"
                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                [(ngModel)]="tipo_alerta" name="tipo_alerta" disabled>
                        </div>
                        <div class="form-group row">
                            <label class="label-alerta">UNIDAD:</label>
                            <input id="txt_no_tracto" type="text" class="form-control form-control-sm"
                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                [(ngModel)]="unidad_alerta" name="unidad_alerta">
                        </div>
                        <div class="form-group row">
                            <label class="label-alerta">FLOTA:</label>
                            <input id="txt_flota" type="text" class="form-control form-control-sm"
                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                [(ngModel)]="flota_alerta" name="flota_alerta">
                        </div>
                        <div class="form-group row">
                            <label class="label-alerta">OPERADOR:</label>
                            <input id="txt_operador" type="text" class="form-control form-control-sm"
                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                [(ngModel)]="operador_alerta" name="operador_alerta">
                        </div>
                        <div class="form-group">
                            <label class="label-alerta row">UBICACION:</label>
                            <div class="row form-inline">
                                <input id="txt_ubicacion" type="text" class="form-control form-control-sm col-8"
                                    aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                    [(ngModel)]="ubicacion" name="ubicacion" disabled>
                                <div class="input-group input-group-sm col-sm">
                                    <a href="{{this.url}}" target="_blank"><button type="button" class="btn btn-light"
                                            (click)="posicion()"><i class="fas fa-map-marker-alt"
                                                style="color:#BF343F;"></i>
                                            MAPA</button></a>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <label class="label-alerta">APLICA SANCIÓN: </label>
                            <div class="input-group col-sm-4 input-group-sm">
                                <select class="custom-select" [(ngModel)]="aplica_sancion" name="aplica_sancion">
                                    <option  *ngFor="let apli_sancion of sancion" value="{{apli_sancion.valor}}">{{apli_sancion.aplica}}</option>
                                </select>
                            </div>   
                        </div>
                        <div class="form-group row" *ngIf="aplica_sancion == 'S'">
                            <label class="label-alerta">MOTIVO SANCIÓN:</label>
                            <select class="custom-select form-control-sm" id="motivo_sancion"
                                [(ngModel)]="motivo_sancion" name="motivo_sancion" (change)="obtenerIdSancion(motivo_sancion)">
                                <option *ngFor="let sanciones of lista_sanciones">{{sanciones.nombre}}</option>
                            </select>
                        </div>
                        <div class="row"  *ngIf="aplica_sancion == 'S'">
                            <label class="label-alerta">NOTIFICAR A: </label>
                        </div>
                        <div class="row"  *ngIf="aplica_sancion == 'S'">
                            <div class="col-8 row">
                                <div class="form-group form-check-inline">
                                    <input type="checkbox" class="form-check-input" id="ch_atenc_oper" (change)="asignarCorreos()">
                                    <label class="form-check-label label-check" for="ch_atenc_oper">OPERACION REFRIGERADO</label>
                                </div>
                                <div class="form-group form-check-inline">
                                    <input type="checkbox" class="form-check-input" id="ch_atenc_oper_seco" (change)="asignarCorreos()">
                                    <label class="form-check-label label-check"
                                        for="ch_atenc_oper_seco" >OPERACION SECO</label>
                                </div>
                            </div>
                            <div class="col-4 row">
                                <div class="form-group form-check-inline">
                                    <input type="checkbox" class="form-check-input" id="ch_atenc_clientes" (change)="asignarCorreos()">
                                    <label class="form-check-label label-check" for="ch_atenc_clientes">ATENCION A
                                        CLIENTES</label>
                                </div>
                                <div class="form-group form-check-inline">
                                    <input type="checkbox" class="form-check-input" id="ch_mantenimiento" (change)="asignarCorreos()">
                                    <label class="form-check-label label-check"
                                        for="ch_mantenimiento" >MANTENIMIENTO</label>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="label-alerta">COMENTARIOS</label>
                            <textarea id="txt_comentarios" class="form-control" rows="2" [(ngModel)]="comentario" name="comentario"></textarea>
                        </div>
                        <div class="row justify-content-around">
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary"
                                data-toggle="modal" data-target="#posponer_alert">POSPONER</button>
                            </div>
                            <div class="form-group">
                                <a *ngIf="aplica_sancion == 'S'" href="mailto:{{this.correoSancion}}" target="_blank">
                                    <button type="button" class="btn btn-primary" (click)="guardarAlerta()">GUARDAR</button></a>
                                <a *ngIf="aplica_sancion != 'S'">
                                <button type="button" class="btn btn-primary" (click)="guardarAlerta()">GUARDAR</button></a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="fade modal-posponer" id="posponer_alert" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" style="z-index: 10000;">
    <div class="modal-dialog  modal-lg modal-dialog-centered" role="document" >
      <div class="modal-content" >
        <div class="modal-header d-block">
            <button type="button" class="close color-white float-right" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="form-group row">
                <label class="label-alerta">Posponer la(s) alerta(s):</label>
                <input id="txt_tipo_alerta" type="time" class="form-control form-control-sm text-uppercase"
                    aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                    [(ngModel)]="time_alert" name="time_alert">
            </div>
            <div>
                <div class="row">
                    <div class="form-group form-check-inline">
                        <input type="checkbox" class="form-check-input" id="ch_posponer" [(ngModel)] ="posponer" name="posponer" (change)="opcionPosponerAlerta()">
                        <label class="form-check-label" for="ch_posponer">Posponer alerta</label>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group form-check-inline">
                        <input type="checkbox" class="form-check-input" id="ch_posponer_todas" [(ngModel)] ="posponer_todas" name="posponer_todas"  (change)="opcionPosponerAlerta()">
                        <label class="form-check-label" for="ch_atenc_clientes">Posponer todas las alertas</label>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" (click)="posponerAlerta()">POSPONER</button>
        </div>
      </div>
    </div>
  </div>
  <div class="fade modal-posponer" id="activarAlerta" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" style="z-index: 10000;">
    <div class="modal-dialog  modal-lg modal-dialog-centered" role="document" >
      <div class="modal-content" >
        <div class="modal-header d-block">
            <button type="button" class="close float-right" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="form-group row">
                <label class="label-alerta">Activar las alertas despues de:</label>
                <input id="txt_tipo_alerta" type="time" class="form-control form-control-sm text-uppercase"
                    aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                    [(ngModel)]="time_alert" name="time_alert">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" (click)="desactivarAlerta()">POSPONER</button>
        </div>
      </div>
    </div>
</div>
