import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, ReactiveFormsModule} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ViajeService } from 'src/app/service/viaje/viaje.service';
import { OperadoresService } from 'src/app/service/operadores/operadores.service';
import { CajaService } from 'src/app/service/caja/caja.service';
import { TractorService } from 'src/app/service/tractor/tractor.service';
import { OrigenDestinoService } from 'src/app/service/origen-destino/origen-destino.service';
import { NotasService } from 'src/app/service/notas/notas.service';
import { HistoricoService } from 'src/app/service/historico/historico.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { CanTokenService } from 'src/app/service/guards/can-token.service';
import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { SancionService } from 'src/app/service/sancion/sancion.service';
import { AlertaService } from 'src/app/service/alertas/alertas.service';
import { Workbook } from 'exceljs';
import { SocketwebService } from 'src/app/service/socketweb/socketweb.service';
import { forkJoin } from 'rxjs';
import { Viajes } from '../interfaces/viajes';
import { IGetRowsParams } from 'ag-grid-community';


declare var $: any;
declare const jQuery: any;

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css'],
})
export class InicioComponent implements OnInit {
  nombreCol: string = '';
  dato: string = '';
  private gridApi;
  private gridColumnApi;
  rowData: any;
  itemsPerPage: number;
  defaultColDef: any;
  rowSelection: any;
  ageType: any = '';
  idFlotillas: any = '';
  arFlota: any = [];
  flota: any;
  fecha_ini: any = '';
  fecha_fin: any = '';
  cveCia: string = '';
  origenDestino: string = '';
  tipoHistorico: string = '';
  modalTitle: any = '';
  dataViaje: any;
  parada: any = [];
  tablaHistoricos: boolean;
  btnConsultar: boolean = false;
  detallesParaInter: any = [];
  operData: any = [];
  cajaInfo: any = [];
  tractoInfo: any = [];
  reporteCajaInfo: any = [];
  reporteTractoInfo: any = [];
  datosHistoricos: any = '';
  no_pedido: any = '';
  descripNota: any = '';
  datosNota: any = '';
  datosOrigenDestino: any = [];
  url: any;
  usid = '';
  fotoOper: any;
  imagenOperador = '';
  imagen: boolean;
  avatar: boolean;
  noDias: any;
  tipo_alerta: string = '';
  num_viaje: string = '';
  listaNotasViaje: any = [];
  listaNota: any = [];
  edo_nota: string = '';
  id_nota: any = '';
  columnDefsNota: any = [];
  rowDataNota: any;
  pendiente: any = '';
  tipo_pendiente;
  turno: string = '';
  unidad_alerta: string = '';
  styleNota = {
    width: '100%',
  };
  columnDefs = [];
  columnDefsHistorico = [];
  rowDataHistorico: any;
  rowDataParadaInter: any;
  columnDefsParaInter: any = [];
  id_usuario: string = '';
  menuBtnDerecho: any;
  filtroFlotas: any = ' ';
  lista_sanciones: any = [];
  id_sancion: string = '';
  nombre_sancion: string = '';
  descripcion_sancion: string = '';
  nombre_flota: string = '';
  intervalViaje: any = '';
  flotas:any = [];
  flota_ref: any = [];
  flota_seco:any = [];
  id_operador: string = '';
  correosIncidencias: any = [];
  correoDestino: any = [];
  correoSancion: any = '';
  correoFlota: any = [];
  correoDestinoFlota: any = [];
  notificar_area: string = '';
  actualizar_registros: any = [];
  mdlViaje = true;
  mdlCaja = false;
  mdlTractor = false;
  mdlOrigenDestino = false;
  mdlOperador = false;
  datosDetalleViaje = [];
  datosBitacora = [];
  formularioSancion: FormGroup;
  remoteGridBinding = this;
  totalViajes
  infoViajes: Viajes[];
  gridOptions;
  cacheOverflowSize
  maxConcurrentDatasourceRequests
  infiniteInitialRowCount;
  gridParams:any
  filtros:any;
  mostrarViajesFrio = true;
  mostrarViajesTransf = false;
  informacionReporteIncidencia:any
  accionIncidencia:any = '';
  mostrarFormularioIncidencia;
  datosTemporalesIncidencias

  constructor(
    private fb: FormBuilder,
    private apiViaje: ViajeService,
    private spinnerService: NgxSpinnerService,
    private apiOper: OperadoresService,
    private apiCaja: CajaService,
    private apiTracto: TractorService,
    private apiOrigenDestino: OrigenDestinoService,
    private apiSancion: SancionService,
    private apiNotas: NotasService,
    private apiHistoricos: HistoricoService,
    private generalService: CanTokenService,
    private apiAlert: AlertaService,
    private socket: SocketwebService
  ) {
    this.spinnerService.show()
    window.scroll(0, 0);
    this.defaultColDef = {
      resizable: true,
      suppressKeyboardEvent: false
    };
    let peticionesInformacion = [
      this.obtenerNotas(),
      this.obtenerFlotas(),
      this.obtenerCorreos()
    ]
    forkJoin(peticionesInformacion).subscribe({
      next: () => {
        this.spinnerService.hide()
      },
      error: error => {
        this.spinnerService.hide()
        Swal.fire({
          title: 'Error al obtener  la informacion',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
      }
    })
  }

  @Input() navbar: boolean = false;
  ngOnInit() {
    this.crearFormularioSanciones();
    this.gridOptions = {
      cacheBlockSize: 100,
      rowModelType: 'serverSide',
      serverSideStoreType: 'partial'
    };
    this.cacheOverflowSize = 2;
    this.maxConcurrentDatasourceRequests = 2;
    this.infiniteInitialRowCount = 2;
  }
  crearColumnas(){
    this.columnDefs=[
      { field: 'no_pedido', headerName: 'VIAJE', sortable: true, minWidth: 60, maxWidth:91,floatingFilter: true ,filter: 'text', floatingFilterComponentParams: { suppressFilterButton: true }, cellStyle: this.paradasInter, suppressMenu:true },
      { field: 'nickname', headerName: 'CLIENTE', sortable: true, minWidth: 75, maxWidth: 100, floatingFilter: true ,filter: 'text',floatingFilterComponentParams: { suppressFilterButton: true }, cellStyle: this.cellStyle, suppressMenu:true },
      { field: 'zona', headerName: 'ZONA', hide: true,  enableValue: true, sortable: true, minWidth: 50, maxWidth:90, floatingFilterComponentParams: { suppressFilterButton: true }, suppressMenu:true },
      { field: 'fecha_carga', headerName: 'FECHA CARGA', sortable: true,  minWidth: 90, maxWidth:130,floatingFilterComponentParams: { suppressFilterButton: true }, suppressMenu:true},
      { field: 'fecha_descarga', headerName: 'FECHA DESC', sortable: true, minWidth: 90, maxWidth:130,floatingFilterComponentParams: { suppressFilterButton: true }, suppressMenu:true},
      { field: 'producto', headerName: 'PRODUCTO', sortable: true, minWidth: 60, maxWidth:90, floatingFilterComponentParams: { suppressFilterButton: true }, suppressMenu:true},
      { field: 'circu_serv', headerName: 'CIRCULO', sortable: true, minWidth: 60, maxWidth:90, floatingFilterComponentParams: { suppressFilterButton: true }},
      { field: 'no_oper', headerName: 'OPER', sortable: true, floatingFilter: true ,filter: 'text', width: 70, floatingFilterComponentParams: { suppressFilterButton: true }, suppressMenu:true },
      { field: 'no_caja', headerName: 'CAJA', sortable: true, floatingFilter: true ,filter: 'text',width: 70, floatingFilterComponentParams: { suppressFilterButton: true }, suppressMenu:true },
      { field: 'origen', headerName: 'ORIGEN', sortable: true,floatingFilter: true ,filter: 'text', minWidth: 95, maxWidth: 110, floatingFilterComponentParams: { suppressFilterButton: true }},
      { field: 'destino', headerName: 'DESTINO', sortable: true, floatingFilter: true ,filter: 'text',minWidth: 95, maxWidth: 110, floatingFilterComponentParams: { suppressFilterButton: true }},
      { field: 'pinteres', headerName: 'ULTIMA POSICION', sortable: true,minWidth: 110, maxWidth:150, floatingFilterComponentParams: { suppressFilterButton: true },suppressMenu:true },
      { field: 'no_cdc', headerName: 'TRACTOR', sortable: true, floatingFilter: true ,filter: 'text', minWidth: 60, maxWidth:80, floatingFilterComponentParams: { suppressFilterButton: true }, suppressMenu:true},
      { field: 'st_unidad', headerName: 'STATUS', sortable: true, width: 146,  hide: true, enableValue: true,floatingFilterComponentParams: { suppressFilterButton: true }, suppressMenu:true},
      { field: 'alerta', headerName: 'ALERTAS', sortable: true, width: 116,  hide: true, enableValue: true, floatingFilterComponentParams: { suppressFilterButton: true }, suppressMenu:true},
      { field: 'fecha_actualizacion_securite', headerName: 'ultHora_Sec', hide: true, enableValue: true, sortable: true, width: 150, floatingFilterComponentParams: { suppressFilterButton: true }, suppressMenu:true},
      { field: 'fecha_actualizacion_enlace', headerName: 'ultHora_Enla', hide: true, enableValue: true, sortable: true, width: 150, floatingFilterComponentParams: { suppressFilterButton: true }, suppressMenu:true },
      { field: 'hultpos', headerName: 'ULTIMA POSICION', hide: true, enableValue: true,  sortable: true, minWidth: 110, maxWidth:150,floatingFilterComponentParams: { suppressFilterButton: true },suppressMenu:true },
      { field: 'nota',  editable: true, resizable: true,headerName: 'COMENTARIOS',minWidth: 110,flex:2, sortable: true,floatingFilter: true ,filter: 'text', floatingFilterComponentParams: { suppressFilterButton: true }, wrapText: true },
      { field: 'flotilla_tractor', headerName: 'FLOTA TRACTOR' ,sortable: true, width: 90 ,floatingFilterComponentParams: { suppressFilterButton: true }, suppressMenu:true },
      { field: 'reporte', minWidth: 90, maxWidth:112, hide: true, enableValue: true, filter: false, cellRenderer: function (params) { return '<i class="far fa-file-download" style="color:#BF343F;"></i>' } }
      ]
  }

  getViaje() {
      this.gridApi.refreshCells();
      this.dataViaje = [];
      this.id_usuario = localStorage.getItem('id_usuario');
      let filtroFlota = localStorage.getItem('flotas');
      if (filtroFlota != null) {
        this.filtroFlotas = localStorage.getItem('flotas');
        this.ageType = this.filtroFlotas;
        let arFlota = localStorage.getItem('arFlotas');
        this.flotasFiltroInicial(arFlota);
      }
      const flota = this.ageType;
      this.spinnerService.show();
      this.filtros.unidAlert = this.unidad_alerta
      this.filtros.flotillas = flota;
      let datasource = {
        getRows: (params: IGetRowsParams) => {
          this.apiViaje.getViajes(this.filtros).subscribe({
            next: (data) => {
              this.dataViaje = data.viajes;
              this.totalViajes = data.totalViajes[0].viajes;
              this.gridOptions.paginationPageSize = data.viajes.length
              this.dataViaje.forEach((datosViaje) => {
                const nombreFlota = this.flotas.find(
                  (idFlota) => idFlota.id === datosViaje.flotilla
                );
                if (!nombreFlota) {
                  datosViaje.flotilla_tractor = "";
                }else{
                  datosViaje.flotilla_tractor = nombreFlota.nombre;
                }
                datosViaje.fecha_carga = datosViaje.fecha_carga === '' ? datosViaje.fecha_carga : moment(datosViaje.fecha_carga,'MM/DD/YYYY HH:mm').format('DD/MM/YYYY HH:mm');
                datosViaje.fecha_descarga = datosViaje.fecha_descarga === '' ? datosViaje.fecha_descarga : moment(datosViaje.fecha_descarga,'MM/DD/YYYY HH:mm').format('DD/MM/YYYY HH:mm');
              });
          
              params.successCallback(this.dataViaje, data['totalViajes'][0].viajes);
              this.spinnerService.hide()
              this.crearColumnas();
              this.getNotas(this.dataViaje);
            },
            error: (error) => {
              this.spinnerService.hide();
              if (localStorage.getItem('token')) {
                this.spinnerService.hide();
                if (error.status == 401) {
                  this.modalError('La sesion a caducado', '', '401');
                } else {
                  Swal.fire({
                    title: 'Error al obtener los viajes',
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  });
                }
              } else {
                this.modalError('La sesion a caducado', '', '401');
              }
            },
          });
        }
      }
      this.gridApi.setServerSideDatasource(datasource);
  }
  flotasFiltroInicial(flotas) {
    this.arFlota = JSON.parse(flotas);
    this.arFlota.forEach((flota) => {
      if (flota.id_flotilla == '02') {
        $('#dp_flota1_ref').prop('checked', true);
      }
      if (flota.id_flotilla == '03') {
        $('#dp_flota2_ref').prop('checked', true);
      }
      if (flota.id_flotilla == '04') {
        $('#dp_flota3_ref').prop('checked', true);
      }
      if (flota.id_flotilla == '10') {
        $('#dp_flota4_ref').prop('checked', true);
      }
      if (flota.id_flotilla == '11') {
        $('#dp_flota5_ref').prop('checked', true);
      }
      if (flotas.id_flotilla == '16') {
        $('#dp_flota6_ref').prop('checked', true);
      }
      if (flota.id_flotilla == '27') {
        $('#dp_flota7_ref').prop('checked', true);
      }
      if (flota.id_flotilla == '22') {
        $('#dp_flota1_sec').prop('checked', true);
      }
      if (flota.id_flotilla == '23') {
        $('#dp_flota2_sec').prop('checked', true);
      }
      if (flota.id_flotilla == '25') {
        $('#dp_flota3_sec').prop('checked', true);
      }
    });
  }

  onGridReady(params){
      $(document).ready(function () {
        $('.dropdownMenuViajesButton').on('click', function (e) {
          e.stopPropagation();
        });
      });
      if ($('#dp_viaje_frio').prop('checked') == true) {
        $('#dp_viaje_trans').prop('checked', false);
      }
      this.mostrarViajesFrio = true;
      this.mostrarViajesTransf = false;
      this.gridParams = params;
      this.gridColumnApi = params.columnApi;
      this.gridApi = params.api;
      this.gridApi.getEditingCells();
      this.gridApi.closeToolPanel();
      this.filtros = {
        unidAlert: this.unidad_alerta  ? this.unidad_alerta : 0,
        flotillas: this.ageType ? this.ageType :  "",
        filters: params.api.getFilterModel(),
        bloqueInformacion: 1
      }
      this.getViaje()
      var columnState = JSON.parse(localStorage.getItem('myColumnState'));
      if (columnState) {
        params.columnApi.setColumnState(columnState);
      }
  }
  
  paradasInter(params) {
    if (params['data']['parinter'] == 'SI') {
      return { backgroundColor: '#E0E0E0' };
    }
  }
  cellStyle(params) {
    if (params['data']['custodia'] == 'SI') {
      return { backgroundColor: '#0D4C73', color: '#F2F2F2' };
    }
    return null;
  }

  filtrarFlotillaRef() {
    let flota = '';
    let arrFlota = {};
    this.ageType = '';
    this.arFlota = [];
    $(document).ready(function () {
      $('.dropdown-menu').on('click', function (e) {
        e.stopPropagation();
      });
    })
    
    for (let i = 0; i < this.flotas.length; i++) {
      if ($('#dm_flotilla_ref_'+i).prop('checked') === true) {
        $('#dm_flotilla_ref_'+ i).prop('checked', true)
        flota = flota === '' ? $('#dm_flotilla_ref_' + i).val() : flota + ',' + $('#dm_flotilla_ref_' + i).val();
        arrFlota = arrFlota === '' ? {'id_flotilla': $('#dm_flotilla_ref_' + i).val()} : this.arFlota.push({id_flotilla: $('#dm_flotilla_ref_' + i).val()})
      }
    }
    localStorage.removeItem('flotas')
    localStorage.removeItem('arFlotas')
    this.idFlotillas = flota;
    this.ageType = this.idFlotillas;
    let flotillas = JSON.stringify(this.arFlota);
    localStorage.setItem('flotas', this.ageType);
    localStorage.setItem('arFlotas', flotillas)
    this.onGridReady(this.gridParams)
  }
  filtrarFlotillaSeco() {
    let flota = '';
    let arrFlota = {};
    this.ageType = '';
    this.arFlota = [];
    $(document).ready(function () {
      $('.dropdown-menu').on('click', function (e) {
        e.stopPropagation();
      });
    })
    
    for (let i = 0; i < this.flotas.length; i++) {
      if ($('#dm_flotilla_seco_'+i).prop('checked') === true) {
        $('#dm_flotilla_seco_'+ i).prop('checked', true)
        flota = flota === '' ? $('#dm_flotilla_seco_' + i).val() : flota + ',' + $('#dm_flotilla_seco_' + i).val();
        arrFlota = arrFlota === '' ? {'id_flotilla': $('#dm_flotilla_seco_' + i).val()} : this.arFlota.push({id_flotilla: $('#dm_flotilla_seco_' + i).val()})
      }
    }
    localStorage.removeItem('flotas')
    localStorage.removeItem('arFlotas')
    this.idFlotillas = flota;
    this.ageType = this.idFlotillas;
    let flotillas = JSON.stringify(this.arFlota);
    localStorage.setItem('flotas', this.ageType);
    localStorage.setItem('arFlotas', flotillas)
    this.onGridReady(this.gridParams)
  }

  filtroUnidadAlert() {
    if ($('#ch_viaje_alerta').prop('checked') == true) {
      this.unidad_alerta = '1';
    } else {
      this.unidad_alerta = '';
    }
    this.filtros.unidAlert = this.unidad_alerta
    this.getViaje()
  }

  onGridReadyParadasInter(params) {
    this.gridColumnApi = params.columnApi;
    this.gridApi = params.api;
    var columnState = JSON.parse(localStorage.getItem('myColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }
  onColumnMoved(e) {
    let columnState = JSON.stringify(e.columnApi.getColumnState());
    localStorage.setItem('myColumnState', columnState);
  }

  onColumnVisible(e) {
    console.log('Event Column Visible', e);
  }
  onCellDoubleClicked(e) {
    this.dato = e['data'];
    this.nombreCol = e['colDef']['field'];
    if (this.nombreCol == 'no_pedido') {
      if (this.dato['no_pedido']) {
        if (this.dato['parinter'] || this.dato['paradas']) {
          this.mdlViaje = true;
          this.getParada(this.dato, this.nombreCol);
        }
        this.mdlViaje = true;
        this.datosDetalleViaje = [];
        this.detallesViaje(this.dato, this.nombreCol);
      }
    }
    if (this.nombreCol == 'no_oper') {
      this.mdlOperador = true;
      this.getOper(this.dato, this.nombreCol);
    }
    if (this.nombreCol == 'no_caja') {
      this.getCaja(this.dato, this.nombreCol);
    }
    if (this.nombreCol == 'origen') {
      this.getOrigenDestino(this.dato, this.nombreCol);
    }
    if (this.nombreCol == 'destino') {
      this.getOrigenDestino(this.dato, this.nombreCol);
    }
    if (this.nombreCol == 'no_cdc' || this.nombreCol == 'no_trac') {
      this.getTracto(this.dato, this.nombreCol);
    }
    if (this.nombreCol == 'nota') {
      this.datosNota = [];
    }
    if (this.nombreCol == 'reporte') {
      this.exportReportRobo(this.dato);
    }
  }

  getContextMenuItems = (params) => {
    var menuBtnDerecho = [
      {
        name: 'Actualizar registo de la unidad: ' + params.node.data.no_cdc,
        shortcut: '',
        action: () => {
          const unidad = [{
            numeroUnidad: params.node.data.no_cdc,
          }];
          this.actualizarRegistros(unidad, 'viajes');
        },
      },
      {
        name: 'Actualizar Registros',
        shortcut: '',
        action: () => {
          this.actualizar_registros = [];
          for (const unidades of this.dataViaje) {
            const unidad = {
              numeroUnidad: unidades.no_cdc,
            };
            this.actualizar_registros.push(unidad);
          }
          this.separarRegistros(this.actualizar_registros, 'viajes');
        },
      },
      {
        name: 'Generar Reporte Incidencia',
        shortcut: 'I',
        action: () => {
          this.actualizar_registros = [];
          let unidad = {
            numeroUnidad: params.node.data.no_cdc
          };
          this.actualizar_registros.push(unidad)
          this.datosTemporalesIncidencias = params.node.data
          this.separarRegistros(this.actualizar_registros, 'incidencia');
        },
      },
      {
        name: 'Generar Reporte de Notificacion',
        shortcut: 'N',
        action: () => {
          this.actualizar_registros = [];
          let unidad = {
            numeroUnidad: params.node.data.no_cdc
          };
          this.actualizar_registros.push(unidad)
          this.datosTemporalesIncidencias = params.node.data
          this.separarRegistros(this.actualizar_registros, 'notificacion');
        },
      },
      {
        name: 'Exportar Bitacora',
        action: () => {
          this.onBtExport();
        },
      },
      'separator',
      {
        name: 'Historico Caja',
        shortcut: 'C',
        action: () => {
          this.modalTitle = 'HISTORICO DE CAJA ' + params.node.data.no_caja;
          this.tipoHistorico = 'CAJA';
          let cveCia = params.node.data.cia;
          let valor = params.node.data.no_caja;
          let fechaIni = '';
          let fechaFin = '';
          let noDias = '10';
          this.tablaHistoricos = true;
          this.btnConsultar = false;
          this.getHistoricos(this.tipoHistorico, cveCia,valor,fechaFin, fechaIni,noDias);
          this.apiHistoricos.setDatosHistoricos(this.tipoHistorico,cveCia,valor);
        },
      },
      {
        name: 'Historico Tractor',
        shortcut: 'T',
        action: () => {
          this.modalTitle = 'HISTORICO DEL TRACTOR ' + params.node.data.no_cdc;
          this.tipoHistorico = 'TRACTOR';
          let cveCia = params.node.data.cia;
          let valor = params.node.data.no_cdc;
          let fechaIni = '';
          let fechaFin = '';
          let noDias = '10';
          this.tablaHistoricos = true;
          this.btnConsultar = false;
          this.getHistoricos(this.tipoHistorico,cveCia, valor,fechaFin,fechaIni,noDias);
          this.apiHistoricos.setDatosHistoricos(this.tipoHistorico,cveCia,valor);
        },
      },
      {
        name: 'Historico Operador',
        shortcut: 'O',
        action: () => {
          this.modalTitle =
            'HISTORICO DEL OPERADOR ' + params.node.data.no_oper;
          this.tipoHistorico = 'OPERADOR';
          let cveCia = params.node.data.cia;
          let valor = params.node.data.no_oper;
          let fechaIni = '';
          let fechaFin = '';
          let noDias = '10';
          this.tablaHistoricos = true;
          this.btnConsultar = false;
          this.getHistoricos(this.tipoHistorico, cveCia, valor, fechaFin, fechaIni, noDias
          );
          this.apiHistoricos.setDatosHistoricos(this.tipoHistorico, cveCia,valor);
        },
      },
      {
        name: 'Historico Cliente',
        shortcut: 'F',
        action: () => {
          this.modalTitle =
            'HISTORICO DEL CLIENTE ' + params.node.data.nickname;
          this.tipoHistorico = 'CLIENTE';
          let cveCia = params.node.data.cia;
          let valor = params.node.data.nickname;
          let fechaIni = '';
          let fechaFin = '';
          let noDias = '10';
          this.tablaHistoricos = true;
          this.btnConsultar = false;
          this.getHistoricos(this.tipoHistorico,cveCia,valor,fechaFin,fechaIni,noDias);
          this.apiHistoricos.setDatosHistoricos(this.tipoHistorico, cveCia,valor);
        },
      },
      {
        name: 'Historico Origen',
        shortcut: 'R',
        action: () => {
          this.modalTitle = 'HISTORICO DE ORIGEN ' + params.node.data.origen;
          this.tipoHistorico = 'ORIGEN';
          this.cveCia = params.node.data.cia;
          this.origenDestino = params.node.data.origen;
          this.tablaHistoricos = false;
          this.btnConsultar = false;
          this.apiHistoricos.setDatosHistoricos(this.tipoHistorico,this.cveCia, this.origenDestino);
          this.mostrarModal('historicos');
        },
      },
      {
        name: 'Historico Destino',
        shortcut: 'D',
        action: () => {
          this.modalTitle = 'HISTORICO DESTINO ' + params.node.data.destino;
          this.tipoHistorico = 'DESTINO';
          this.cveCia = params.node.data.cia;
          this.origenDestino = params.node.data.destino;
          this.tablaHistoricos = false;
          this.btnConsultar = false;
          this.apiHistoricos.setDatosHistoricos(this.tipoHistorico, this.cveCia, this.origenDestino);
          this.mostrarModal('historicos');
        },
      },
      {
        name: 'Historico por Rango Fechas',
        shortcut: 'H',
        action: () => {
          this.modalTitle = 'HISTORICO POR RANGO DE FECHAS';
          this.tipoHistorico = 'RANGO_FECHAS';
          this.cveCia = params.node.data.cia;
          this.tablaHistoricos = false;
          this.btnConsultar = true;
          this.apiHistoricos.setDatosHistoricos(this.tipoHistorico,this.cveCia,'');
          this.mostrarModal('historicos');
        },
      },
    ];
    return menuBtnDerecho;
  };

  getParada(dato: any, col: any) {
    let viaje = dato['no_pedido'];
    let cia = dato['cia'] || dato['cve_cia'];
    let parada = '';
    this.apiViaje.getParada(viaje, cia, parada).subscribe({
      next: (parada) => {
        this.parada = parada;
        this.rowDataParadaInter = this.parada;
        this.columnDefsParaInter = [
          {field: 'cve_parinter',headerName: 'CVE PARADA',maxWidth: 120,sortable: true,floatingFilterComponentParams: { suppressFilterButton: true }},
          {field: 'nombre', headerName: 'NOMBRE',sortable: true, floatingFilterComponentParams: { suppressFilterButton: true }},
          {field: 'costo', headerName: 'COSTO',sortable: true, maxWidth: 80,floatingFilterComponentParams: { suppressFilterButton: true }},
          {valueGetter: function sumField(params) {return ( params.data.fecha_parinter + ' ' + params.data.hora_parinter);},headerName: 'FECHA', sortable: true, width: 146,floatingFilterComponentParams: { suppressFilterButton: true }},
        ];
        this.no_pedido = this.parada.no_pedido;
        this.mostrarModal(col);
      },
      error: (error) => {
        if (localStorage.getItem('token')) {
          if (error.status == 401) this.generalService.refreshToken();
        }
        this.spinnerService.hide();
      },
    });
  }
  onCellClickedParInt(e) {
    this.dato = e['data'];
    this.nombreCol = e['colDef']['field'];
    if (this.nombreCol == 'cve_parinter') {
      this.obtenerDetallesParadaIntermedia(this.dato);
      jQuery.noConflict();
      $('#mdl_clave').modal('show');
      $('#mdl_clave').draggable({
        handle: '.modal-dialog',
      });
    }
  }
  obtenerDetallesParadaIntermedia(datos: any) {
    let viaje = datos['no_pedido'];
    let cveCia = datos['cve_cia'];
    let cveParada = datos['cve_parinter'];
    this.apiViaje.getParada(viaje, cveCia, cveParada).subscribe((resp) => {
      this.detallesParaInter = resp;
    });
  }
  getOper(dato: any, col: any) {
    let cia = dato['cia'] || dato.cve_cia;
    let noOper = dato['no_oper'];
    this.imagenOperador = '';
    this.apiOper.getOper(noOper, cia).subscribe({
      next: (oper) => {
      const numeroOper = noOper.trim();
      const companiaOper = cia.trim();
      this.apiOper.obtenerFotoOperador(companiaOper, numeroOper).subscribe(datos =>{
        this.avatar = true;
        this.imagen = false;
        if (datos) {
          this.imagenOperador = datos.url_fotografia
          this.imagen = true;
          this.avatar = false
        }
      });
        this.operData = oper
        this.mostrarModal(col);
      },
      error: (error) => {
        if (localStorage.getItem('token')) {
          if (error.status == 401) this.generalService.refreshToken();
        }
        this.spinnerService.hide();
      },
    });
  }

  getCaja(dato: any, col: any) {
    let cia = dato['cia'] || dato['cveCia'];
    let noCaja = dato['no_caja'].trim();
    let linTract = dato['id_lintrans'] || dato['cve_lineatras'];
    if (noCaja != '') {
      this.apiCaja.getCaja(noCaja, cia, linTract).subscribe({
        next: (caja) => {
          this.cajaInfo = caja;
          this.mostrarModal(col);
        },
        error: (error) => {
          if (localStorage.getItem('token')) {
            if (error.status == 401) this.generalService.refreshToken();
          }
          this.spinnerService.hide();
        },
      });
    }
  }
  getOrigenDestino(dato: any, col: any) {
    if (col == 'origen') {
      let cveCliente = dato['origen'];
      let cveCia = dato['cia'] || dato['cve_lineatras'];
      this.apiOrigenDestino.getOrigenDestino(cveCliente, cveCia).subscribe({
        next: (origenesDestinos) => {
          this.datosOrigenDestino = origenesDestinos;
          this.mostrarModal(col);
        },
        error: (error) => {
          if (localStorage.getItem('token')) {
            if (error.status == 401) this.generalService.refreshToken();
          }
          this.spinnerService.hide();
        },
      });
    } else {
      let cveCliente = dato['destino'];
      let cveCia = dato['cia'];
      this.apiOrigenDestino.getOrigenDestino(cveCliente, cveCia).subscribe({
        next: (origenesDestinos) => {
          this.datosOrigenDestino = origenesDestinos;
          this.mostrarModal(col);
        },
        error: (error) => {
          if (localStorage.getItem('token')) {
            if (error.status == 401) this.generalService.refreshToken();
          }
          this.spinnerService.hide();
        },
      });
    }
  }
  posicion(dato: any) {
    let latitud_Pos = dato['latitud'];
    let longitud_Pos = dato['longitud'];
    this.url =
      'https://www.google.com/maps/search/?api=1&query=' +
      latitud_Pos +
      ',' +
      longitud_Pos;
  }
  getTracto(dato: any, col: any) {
    let noCdc = dato['no_cdc'] || dato['no_trac'];
    let cveCia = dato['cia'] || dato['cve_cia'];
    let status = '';
    this.apiTracto.getTracto(noCdc, cveCia, status).subscribe({
      next: (tracto) => {
        this.tractoInfo = tracto;
        this.mostrarModal(col);
      },
      error: (error) => {
        if (localStorage.getItem('token')) {
          if (error.status == 401) this.generalService.refreshToken();
        }
        this.spinnerService.hide();
      },
    });
  }

  getNotas(datosViaje) {
    datosViaje.forEach((viaje) => {
      if (viaje.no_pedido) {
        this.apiNotas.getNotas(viaje.no_pedido).subscribe({
          next: (datos) => {
            if (datos.length != 0) {
              viaje.id_nota = datos[0].id_nota;
              viaje.nota = datos[0].descripcion;
            } else {
              viaje.id_nota = '';
              viaje.nota = '';
            }
          },
          error: (error) => {
            if (localStorage.getItem('token')) {
              if (error.status == 401) this.generalService.refreshToken();
            }
          },
        });
      } else {
        viaje.id_nota = '';
        viaje.nota = '';
      }
    });
    let datasource = {
      getRows: (params: IGetRowsParams) => {
        params.successCallback(datosViaje, this.totalViajes);
      }
    }
    this.gridApi.setServerSideDatasource(datasource);
  }

  openModalNota(nota, accion) {
    let edoNota;
    if (accion == 'edit') {
      if (nota.ind_estado == 'P') {
        edoNota = 'PENDIENTE';
      }
      this.id_nota = nota.id_nota;
      this.modalTitle = 'EDITAR NOTA';
      this.edo_nota = edoNota;
      this.descripNota = nota.descripcion;
    } else {
      this.modalTitle = 'AGREGAR NOTA';
      this.edo_nota = '';
      this.descripNota = '';
      this.pendiente = '';
      this.tipo_pendiente = '';
      this.turno = '';
    }
  }
  mostrarModal(nombre: any) {
    $(document).ready(function () {
      if (nombre == 'no_pedido') {
        jQuery.noConflict();
        $('#mdl_par_int_viaje').modal('show');
        $('#mdl_par_int_viaje').draggable({
          handle: '.modal-dialog',
        });
        $('#comentarios').draggable({
          handle: '.modal-dialog',
        });
        $('#paradasIntermedias').draggable({
          handle: '.modal-header',
        });
      }
      if (nombre == 'no_oper') {
        jQuery.noConflict();
        $('#mdl_info_oper').modal('show');
        $('#mdl_info_oper').draggable({
          handle: ".modal-dialog"
        });
      }
      if (nombre == 'no_caja') {
        jQuery.noConflict();
        $('#mdl_info_caja').modal('show');
        $('#mdl_info_caja').draggable({
          handle: '.modal-dialog',
        });
      }
      if (nombre == 'origen') {
        jQuery.noConflict();
        $('#mdl_info_clave').modal('show');
        $('#mdl_info_clave').draggable({
          handle: '.modal-dialog',
        });
      }
      if (nombre == 'destino') {
        jQuery.noConflict();
        $('#mdl_info_clave').modal('show');
        $('#mdl_info_clave').draggable({
          handle: '.modal-dialog',
        });
      }
      if (nombre == 'no_cdc' || nombre == 'no_trac') {
        jQuery.noConflict();
        $('#mdl_info_tracto').modal('show');
        $('#mdl_info_tracto').draggable({
          handle: '.modal-dialog',
        });
      }
      if (nombre == 'notas') {
        jQuery.noConflict();
        $('#mdl_nota_viaje').modal('show');
        $('#mdl_nota_viaje').draggable({
          handle: '.modal-dialog',
        });
      }
      if (nombre == 'historicos') {
        jQuery.noConflict();
        $('#mdl_historicos').modal('show');
        $('#mdl_historicos').draggable({
          handle: '.modal-dialog',
        });
      }
    });
  }
  exportReportRobo(dato: any) {
    let noCdc = dato['no_cdc'];
    let cveCia = dato['cia'];
    let noCaja = dato['no_caja'];
    let idLinTract = dato['id_lintrans'];
    let lineaTrans = dato['nombre']
    let datosUbicacion = {
      ubicacion: dato['posicion_securite'],
      latitud_caja: dato['latitud_caja'],
      longitud_caja: dato['longitud_caja'],
      latitud_tracto: dato['latitud'],
      longitud_tracto: dato['longitud'],
    };
    let status = '';
    this.apiTracto.getTracto(noCdc, cveCia, status).subscribe((tracto) => {
      this.reporteTractoInfo = tracto;
      if (
        this.reporteTractoInfo[0].marca == null ||
        this.reporteTractoInfo[0].marca == undefined ||
        this.reporteTractoInfo[0].marca == ''
      ) {
        this.reporteTractoInfo[0].marca = ' ';

        this.apiCaja.getCaja(noCaja, cveCia, idLinTract, lineaTrans).subscribe((caja) => {
          this.reporteCajaInfo = caja;
          this.crearReporteLaica(datosUbicacion);
        });
      }
    });
  }

  crearReporteLaica(datosUbicacion) {
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  size: 22,
                  text: 'ROBO DE CARGA / FRIO EXPRESS',
                  bold: true,
                  font: { name: 'Arial' },
                }),
              ],
              spacing: {after: 200,},
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Razón Social: ',
                  bold: true,
                  font: { name: 'Arial' },
                  size: 22,
                }),
                new TextRun({
                  text: 'M TRANSCARGA S.A. DE C.V. / TRANSPORTES FREX S.A. DE C.V.',
                  font: { name: 'Arial' },
                  size: 22,
                }),
              ],
              spacing: { after: 200,},
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'TIPO de Carga: ',
                  bold: true,
                  font: { name: 'Arial' },
                  size: 22,
                }),
                new TextRun({
                  text: '"C"',
                  font: { name: 'Arial' },
                  size: 22,
                }),
              ],
              spacing: {after: 200},
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: '# Predenuncia',
                  bold: true,
                  font: { name: 'Arial' },
                  size: 22,
                }),
              ],
              spacing: { after: 200 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Teléfono de contacto: ',
                  bold: true,
                  font: { name: 'Arial' },
                  size: 22,
                }),
                new TextRun({
                  text: '449 9 22 13 00 Ext 547 / 602',
                  font: { name: 'Arial' },
                  size: 22,
                }),
              ],
              spacing: { after: 200 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: '449 478 16 02',
                  font: { name: 'Arial' },
                  size: 22,
                }),
              ],
              spacing: { after: 200 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'CEL 449 122 25 43',
                  font: { name: 'Arial' },
                  size: 22,
                }),
              ],
              spacing: { after: 400 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  size: 22,
                  text: 'DATOS DEL TRACTOR',
                  bold: true,
                  font: { name: 'Arial' },
                }),
              ],
              spacing: {
                after: 200,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Placas: ',
                  bold: true,
                  font: { name: 'Arial' },
                  size: 22,
                }),
                new TextRun({
                  text: `${this.reporteTractoInfo[0]['placas']}`,
                  font: { name: 'Arial' },
                  size: 22,
                }),
              ],
              spacing: {
                after: 200,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Número Económico: ',
                  bold: true,
                  font: { name: 'Arial' },
                  size: 22,
                }),
                new TextRun({
                  text: `${this.reporteTractoInfo[0]['no_cdc']}`,
                  font: { name: 'Arial' },
                  size: 22,
                }),
              ],
              spacing: {after: 200},
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Marca y Modelo: ',
                  bold: true,
                  font: { name: 'Arial' },
                  size: 22,
                }),
                new TextRun({
                  text: `${this.reporteTractoInfo[0]['marca']}  ${this.reporteTractoInfo[0]['modelo']}`,
                  font: { name: 'Arial' },
                  size: 22,
                }),
              ],
              spacing: {after: 200},
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Serie: ',
                  bold: true,
                  font: { name: 'Arial' },
                  size: 22,
                }),
                new TextRun({
                  text: `${this.reporteTractoInfo[0]['no_serie']}`,
                  font: { name: 'Arial' },
                  size: 22,
                }),
              ],
              spacing: {after: 200},
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Color: ',
                  bold: true,
                  font: { name: 'Arial' },
                  size: 22,
                }),
              ],
              spacing: {after: 200},
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Ubicación: ',
                  bold: true,
                  font: { name: 'Arial' },
                  size: 22,
                }),
                new TextRun({
                  text: `${datosUbicacion['ubicacion']}`,
                  font: { name: 'Arial' },
                  size: 22,
                }),
              ],
              spacing: {after: 200},
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Coordenadas: ',
                  bold: true,
                  font: { name: 'Arial' },
                  size: 22,
                }),
                new TextRun({
                  text: `${datosUbicacion['latitud_tracto']}, ${datosUbicacion['longitud_tracto']}`,
                  font: { name: 'Arial' },
                  size: 22,
                }),
              ],
              spacing: {after: 300},
            }),
            new Paragraph({
              children: [
                new TextRun({
                  size: 22,
                  text: 'DATOS DE LA CAJA',
                  bold: true,
                  font: { name: 'Arial' },
                }),
              ],
              spacing: {after: 200},
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Placas: ',
                  bold: true,
                  font: { name: 'Arial' },
                  size: 22,
                }),
                new TextRun({
                  text: `${this.reporteCajaInfo[0]['placas']}`,
                  font: { name: 'Arial' },
                  size: 22,
                }),
              ],
              spacing: {after: 200},
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Número Económico: ',
                  bold: true,
                  font: { name: 'Arial' },
                  size: 22,
                }),
                new TextRun({
                  text: `${this.reporteCajaInfo[0]['no_caja']}`,
                  font: { name: 'Arial' },
                  size: 22,
                }),
              ],
              spacing: {after: 200},
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Marca y Modelo: ',
                  bold: true,
                  font: { name: 'Arial' },
                  size: 22,
                }),
                new TextRun({
                  text: `${this.reporteCajaInfo[0]['marca'].trim()}  ${
                    this.reporteCajaInfo[0]['modelo']
                  }`,
                  font: { name: 'Arial' },
                  size: 22,
                }),
              ],
              spacing: {after: 200},
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Serie: ',
                  bold: true,
                  font: { name: 'Arial' },
                  size: 22,
                }),
                new TextRun({
                  text: `${this.reporteCajaInfo[0]['no_serie']}`,
                  font: { name: 'Arial' },
                  size: 22,
                }),
              ],
              spacing: {after: 200,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Color: ',
                  bold: true,
                  font: { name: 'Arial' },
                  size: 22,
                }),
              ],
              spacing: {after: 200},
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Ubicación: ',
                  bold: true,
                  font: { name: 'Arial' },
                  size: 22,
                }),
                new TextRun({
                  text: `${datosUbicacion['ubicacion']}`,
                  font: { name: 'Arial' },
                  size: 22,
                }),
              ],
              spacing: {after: 200},
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Coordenadas: ',
                  bold: true,
                  font: { name: 'Arial' },
                  size: 22,
                }),
                new TextRun({
                  text: `${datosUbicacion['latitud_caja']}, ${datosUbicacion['longitud_caja']}`,
                  font: { name: 'Arial' },
                  size: 22,
                }),
              ],
              spacing: { after: 200},
            }),
          ],
        },
      ],
    });
    // Used to export the file into a .docx file
    Packer.toBlob(doc).then((blob) => {
      // saveAs from FileSaver will download the file
      saveAs(blob, 'FORMATO_LAICA.docx');
    });
  }

  modalError(titulo, mensaje, codigoError) {
    this.spinnerService.hide();
    //Configuracion del diseño del modal
    var alerta = Swal.mixin({
      imageUrl: '/assets/general/img/icon/error.svg',
      imageWidth: 85,
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false,
    });
    alerta.fire({
        title: titulo, //Titulo del modal
        text: mensaje, //Mensaje del modal
        allowOutsideClick: false, //Desactiva el backdrop, para no poder ocultar el modal hasta dar en el boton 'OK'
      }).then((resultado) => {
        //Si el error fue 401(No Autorizado) por vencimiento de token, cierra la sesion
        if (resultado.value && codigoError === '401') {
          this.salir(); //Cerrar la sesion del usuario
        }
      });
  }
  onCellKeyPress(e) {
    this.modalTitle = '';
    if (e.colDef.field === 'nota') {
      return;
    }
    let keyPressed = e.event.key;
    if (keyPressed == 'c' || keyPressed == 'C') {
      this.modalTitle = 'HISTORICO DE CAJA ' + e.data.no_caja;
      this.tipoHistorico = 'CAJA';
      let cveCia = e.data.cia;
      let valor = e.data.no_caja;
      let fechaIni = '';
      let fechaFin = '';
      let noDias = '10';
      this.tablaHistoricos = true;
      this.btnConsultar = false;
      this.getHistoricos(this.tipoHistorico, cveCia, valor,fechaFin, fechaIni,noDias);
      this.apiHistoricos.setDatosHistoricos(this.tipoHistorico, cveCia, valor);
    }
    if (keyPressed == 'f' || keyPressed == 'F') {
      this.modalTitle = 'HISTORICO DEl CLIENTE ' + e.data.nickname;
      this.tipoHistorico = 'CLIENTE';
      let cveCia = e.data.cia;
      let valor = e.data.nickname;
      let fechaIni = '';
      let fechaFin = '';
      let noDias = '10';
      this.tablaHistoricos = true;
      this.btnConsultar = false;
      this.apiHistoricos.setDatosHistoricos(this.tipoHistorico, cveCia, valor);
      this.getHistoricos(this.tipoHistorico,cveCia,valor, fechaFin, fechaIni,noDias);
    }
    if (keyPressed == 'o' || keyPressed == 'O') {
      this.modalTitle = 'HISTORICO DEl OPERADOR: ' + e.data.no_oper;
      this.tipoHistorico = 'OPERADOR';
      let cveCia = e.data.cia;
      let valor = e.data.no_oper;
      let fechaIni = '';
      let fechaFin = '';
      let noDias = '10';
      this.tablaHistoricos = true;
      this.btnConsultar = false;
      this.apiHistoricos.setDatosHistoricos(this.tipoHistorico, cveCia, valor);
      this.getHistoricos(this.tipoHistorico, cveCia,valor,fechaFin,fechaIni, noDias);
    }
    if (keyPressed == 'd' || keyPressed == 'D') {
      this.modalTitle = 'HISTORICO DESTINO ' + e.data.destino;
      this.tipoHistorico = 'DESTINO';
      this.cveCia = e.data.cia;
      this.origenDestino = e.data.destino;
      this.tablaHistoricos = false;
      this.btnConsultar = false;
      this.apiHistoricos.setDatosHistoricos(
        this.tipoHistorico,
        this.cveCia,
        this.origenDestino
      );
      this.mostrarModal('historicos');
    }
    if (keyPressed == 'r' || keyPressed == 'R') {
      this.modalTitle = 'HISTORICO ORIGEN ' + e.data.origen;
      this.tipoHistorico = 'ORIGEN';
      this.cveCia = e.data.cia;
      this.origenDestino = e.data.origen;
      this.tablaHistoricos = false;
      this.btnConsultar = false;
      this.apiHistoricos.setDatosHistoricos(this.tipoHistorico, this.cveCia,this.origenDestino);
      this.mostrarModal('historicos');
    }
    if (keyPressed == 't' || keyPressed == 'T') {
      this.modalTitle = 'HISTORICO DEL TRACTOR: ' + e.data.no_cdc;
      this.tipoHistorico = 'TRACTOR';
      let cveCia = e.data.cia;
      let valor = e.data.no_cdc;
      let fechaIni = '';
      let fechaFin = '';
      let noDias = '10';
      this.tablaHistoricos = true;
      this.btnConsultar = false;
      this.getHistoricos(this.tipoHistorico, cveCia, valor,fechaFin,fechaIni,noDias );
      this.apiHistoricos.setDatosHistoricos(this.tipoHistorico, cveCia, valor);
    }
    if (keyPressed == 'h' || keyPressed == 'H') {
      this.modalTitle = 'HISTORICO POR RANGO DE FECHAS';
      this.tipoHistorico = 'RANGO_FECHAS';
      this.cveCia = e.data.cia;
      this.tablaHistoricos = false;
      this.btnConsultar = true;
      this.apiHistoricos.setDatosHistoricos(this.tipoHistorico, this.cveCia, '');
      this.mostrarModal('historicos');
    }
    if (keyPressed == 'i' || keyPressed == 'I') {
      this.modalTitle = 'ALTA DE INCIDENCIA';
      this.actualizar_registros = [];
      let unidad = {
        numeroUnidad: e.data.no_cdc
      };
      this.actualizar_registros.push(unidad)
      this.separarRegistros(this.actualizar_registros, 'incidencia');
    }
    if (keyPressed == 'n' || keyPressed == 'N') {
      this.actualizar_registros = [];
      let unidad = {
        numeroUnidad: e.data.no_cdc
      };
      this.actualizar_registros.push(unidad)
      this.separarRegistros(this.actualizar_registros, 'notificacion');
    }
  }
  consultaDiasHistorico() {
    let tipoHistorico = localStorage.getItem('tipoHistorico');
    let cveCia = localStorage.getItem('cveCia');
    let datoHistorico = localStorage.getItem('datoHistorico');
    let fechaInicial = '';
    let fechafin = '';
    let noDias = this.noDias;
    this.getHistoricos( tipoHistorico, cveCia,datoHistorico,fechafin,fechaInicial,noDias);
  }

  consultarHistorico() {
    this.spinnerService.show();
    let tipoHistorico = localStorage.getItem('tipoHistorico');
    let cveCia = localStorage.getItem('cveCia');
    let origenDestino = localStorage.getItem('datoHistorico');
    let fechaInicial = moment(this.fecha_ini, 'YYYY-MM-DD').format('MM-DD-YYYY');
    let fechaFinal = moment(this.fecha_fin, 'YYYY-MM-DD').format('MM-DD-YYYY');
    let noDias = '';
    this.apiHistoricos.getHistoricos(tipoHistorico, cveCia, origenDestino, fechaInicial, fechaFinal,noDias).subscribe((historicos) => {
        this.columnDefsHistorico = [
          { field: 'no_pedido', headerName: 'VIAJE', sortable: true, width: 100,floatingFilterComponentParams: { suppressFilterButton: true }},
          { field: 'no_trac', headerName: 'TRACTOR', sortable: true, width: 120,floatingFilterComponentParams: { suppressFilterButton: true }},
          { field: 'no_caja', headerName: 'CAJA', sortable: true, width: 100, floatingFilterComponentParams: { suppressFilterButton: true }},
          { field: 'no_oper', headerName: 'OPERADOR', sortable: true,  width: 130, floatingFilterComponentParams: { suppressFilterButton: true }},
          { field: 'circu_serv', headerName: 'CIRCULO', sortable: true,width: 120, floatingFilterComponentParams: { suppressFilterButton: true }},
          { field: 'temp', headerName: 'TEMP.', sortable: true, width: 90, floatingFilterComponentParams: { suppressFilterButton: true }},
          { field: 'nickname', headerName: 'CLIENTE', sortable: true, width: 150, floatingFilterComponentParams: { suppressFilterButton: true }},
          { field: 'fecha_carga',headerName: 'FECHA DE CARGA', sortable: true,width: 200, floatingFilterComponentParams: { suppressFilterButton: true }},
          { field: 'fecha_descarga', headerName: 'FECHA DE DESCARGA',sortable: true, width: 200, floatingFilterComponentParams: { suppressFilterButton: true }},
          { field: 'origen', headerName: 'ORIGEN',sortable: true, width: 200, floatingFilterComponentParams: { suppressFilterButton: true }},
          { field: 'destino', headerName: 'DESTINO', sortable: true,width: 190, floatingFilterComponentParams: { suppressFilterButton: true }},
        ];
        this.datosHistoricos = '';
        this.datosHistoricos = historicos;
        this.rowDataHistorico = this.datosHistoricos;
        this.spinnerService.hide();
        this.mostrarModal('historicos');
        this.tablaHistoricos = true;
      });
  }
  getHistoricos( tipo: string,cve_cia: string,valor: string, fechaIni: string,fechaFin: string, noDias: string) {
    if (!valor) {
      return;
    }
    this.apiHistoricos.getHistoricos(tipo, cve_cia, valor, fechaFin, fechaIni, noDias).subscribe({
      next: historicos => {
        this.columnDefsHistorico = [
          { field: 'no_pedido', headerName: 'VIAJE', sortable: true, width: 100, floatingFilterComponentParams: { suppressFilterButton: true }},
          { field: 'no_trac', headerName: 'TRACTOR', sortable: true, width: 120, floatingFilterComponentParams: { suppressFilterButton: true }},
          { field: 'no_caja', headerName: 'CAJA', sortable: true, width: 100, floatingFilterComponentParams: { suppressFilterButton: true }},
          { field: 'no_oper', headerName: 'OPERADOR', sortable: true, width: 130, floatingFilterComponentParams: { suppressFilterButton: true }},
          { field: 'circu_serv', headerName: 'CIRCULO', sortable: true, width: 120, floatingFilterComponentParams: { suppressFilterButton: true }},
          { field: 'temp', headerName: 'TEMP.', sortable: true, width: 90,floatingFilterComponentParams: { suppressFilterButton: true }},
          { field: 'nickname', headerName: 'CLIENTE', sortable: true, width: 150, floatingFilterComponentParams: { suppressFilterButton: true }},
          { field: 'fecha_carga',  headerName: 'FECHA DE CARGA', sortable: true,  width: 200, floatingFilterComponentParams: { suppressFilterButton: true }},
          { field: 'fecha_descarga', headerName: 'FECHA DE DESCARGA', sortable: true, width: 200, floatingFilterComponentParams: { suppressFilterButton: true }},
          { field: 'origen',headerName: 'ORIGEN', sortable: true, width: 200, floatingFilterComponentParams: { suppressFilterButton: true }},
          { field: 'destino', headerName: 'DESTINO', sortable: true, width: 190, floatingFilterComponentParams: { suppressFilterButton: true }},
        ];
        this.datosHistoricos = '';
        this.datosHistoricos = historicos;
        this.rowDataHistorico = this.datosHistoricos;
        this.spinnerService.hide();
        this.mostrarModal('historicos');
      },
      error: error => {
        Swal.fire({
          title:`Error al obtener el historico: ${error} `,
          icon: 'error'
        });
      }
    });
  }

  modalCerrar() {
    jQuery.noConflict();
    $('#mdl_historicos').modal('hide');
    localStorage.removeItem('tipoHistorico');
    localStorage.removeItem('datoHistorico');
    localStorage.removeItem('cveCia');
    this.noDias = '10';
  }

  resetState() {
    this.gridApi.sizeColumnsToFit();
  }
  salir() {
    this.generalService.refreshToken();
  }

  actualizarDatos() {
    this.actualizar_registros = [];
    for (const unidades of this.dataViaje) {
      const unidad = {
        numeroUnidad: unidades.no_cdc,
      };
      this.actualizar_registros.push(unidad);
    }
    this.separarRegistros(this.actualizar_registros, 'viajes');
  }


  crearFormularioSanciones(){
    this.formularioSancion = this.fb.group({
      'motivo_sancion': new FormControl('', Validators.required),
      'nombre_operador': new FormControl('',Validators.required),
      'no_pedido': new FormControl('', Validators.required),
      'no_cdc': new FormControl('', Validators.required),
      'flotilla_oper': new FormControl('',Validators.required),
      'no_oper': new FormControl('', Validators.required),
      'nombre_flota': new FormControl('',Validators.required),
      'notificar_area': new FormControl(''),
      'reporte': new FormControl('', Validators.required),
    })
  }
  openModalReporteSancion(datosSancion, proceso) {
    this.modalTitle = '';
    if (proceso == 'incidencia') {
      this.modalTitle = 'ALTA DE INCIDENCIA'
      this.accionIncidencia = 'registro';
      this.mostrarFormularioIncidencia = true;
      this.informacionReporteIncidencia = datosSancion;
    } else {
      this.modalTitle = 'REPORTE DE NOTIFICACION';
      this.informacionReporteIncidencia = datosSancion;
      this.mostrarFormularioIncidencia = true;
      this.accionIncidencia = 'notificacion'
    }
  }

  obtenerCorreos() {
    return new Promise((resolve, reject) => {
      this.apiAlert.getCorreosAlertas().subscribe({
        next: correos =>{
          this.correosIncidencias = correos;
          this.correosIncidencias.forEach((proceso) => {
            if (proceso['proceso'] == 'NOTIFICACION-INCIDEN') {
              this.correoFlota.push(proceso);
            }
          });
          resolve('ok')
        },
        error: error =>{
          reject(error)
        }
      });
    })
  }


  cerrarModal() {
    this.formularioSancion.reset()
    this.notificar_area = '';
    this.correoDestino = [];
  }

  separarRegistros(unidad, proceso) {
    if (unidad.length > 1) {
      let i = 0;
      let totalUnidad = [];
      const totalUnidadesLote = unidad.length / 4;
      let unidades1 = {numeroUnidad: unidad.splice(0, totalUnidadesLote)};
      let unidades2 = {numeroUnidad: unidad.splice(0, totalUnidadesLote)};
      let unidades3 = {numeroUnidad: unidad.splice(0, totalUnidadesLote)};
      let unidades4 = {numeroUnidad: unidad.splice(0, totalUnidadesLote)};
      totalUnidad.push(unidades1, unidades2, unidades3, unidades4);
      for (i ; i < totalUnidad.length; i++) {
          const unidades = totalUnidad[i];
          this.actualizarRegistros(unidades,  'viajes')
      }
      if (i == totalUnidad.length) {
      }
    }else{
      let unidades1 = {numeroUnidad: unidad};
      let i = 0;
      let totalUnidad = [];
      totalUnidad.push(unidades1);
      for (i ; i < totalUnidad.length; i++) {
          const unidades = totalUnidad[i];
          this.actualizarRegistros(unidades,  proceso)
      }
      if (i == totalUnidad.length) {
      }
    }
  }

  actualizarRegistros(body,proceso) {
    this.apiViaje.postActualizarRegistrosViaje(body).subscribe({
      next: data => {
        this.spinnerService.hide();
        if (proceso != 'viajes') {
          if (data) {
            Swal.fire({
              title: 'Se encontraron nuevos datos del viaje',
              text: '¿Desea actualizar los datos?',
              icon: 'question',
              customClass: {
                confirmButton: 'btn btn-success',
              },
              confirmButtonText: `Si`,
              cancelButtonText: 'No',
              cancelButtonColor: ' #BF343F'
            }).then((result) => {
              if (result.isConfirmed) {
                this.openModalReporteSancion(data, proceso);
              }
            })
          }
          this.openModalReporteSancion(this.datosTemporalesIncidencias, proceso);
          return;
        }
        clearInterval(this.intervalViaje);
        this.onGridReady(this.gridParams);
      },
      error: (error) => {
        if (localStorage.getItem('token')) {
          this.spinnerService.hide();
          if (error.status == 401) {
            this.modalError('La sesion a caducado', '', '401');
          } else {
            Swal.fire({
              title: 'Error al actualizar los registros',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            });
          }
        }
      },
    });
  }
  detallesViaje(dato: any, col: any) {
    this.spinnerService.show();
    let cia = dato.cia || dato.cve_cia;
    this.apiViaje.obtenerDetalleViaje(dato.no_pedido, cia).subscribe({
      next: (dato) => {
        this.datosDetalleViaje.push(dato);
        this.spinnerService.hide();
        this.no_pedido = dato.no_pedido;
        $('#custodia').prop('checked', false);
        $('#descargaAutorizada').prop('checked', false);
        if (dato.custodia == 'SI') {
          $('#custodia').prop('checked', true);
        }
        if (dato.carga_autorizada == 'SI') {
          $('#descargaAutorizada').prop('checked', true);
        }
        this.mostrarModal(col);
      },
      error: (error) => {
        this.spinnerService.hide();
        Swal.fire({
          title: 'Error al obtener los detalles del viaje',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
      },
    });
  }

  maximizar(id) {
    if (id == 'mdl_par_int_viaje') {
      this.mdlViaje = true;
    }
    if (id == 'mdl_info_oper') {
      this.mdlOperador = true;
    }
    if (id == 'mdl_info_caja') {
      this.mdlCaja = true;
    }
    if (id == 'mdl_info_clave') {
      this.mdlOrigenDestino = true;
    }
    if (id == 'mdl_info_tracto') {
      this.mdlTractor = true;
    }
  }

  minimizar(id) {
    if (id == 'mdl_par_int_viaje') {
      this.mdlViaje = false;
    }
    if (id == 'mdl_info_oper') {
      this.mdlOperador = false;
    }
    if (id == 'mdl_info_caja') {
      this.mdlCaja = false;
    }
    if (id == 'mdl_info_clave') {
      this.mdlOrigenDestino = false;
    }
    if (id == 'mdl_info_tracto') {
      this.mdlTractor = false;
    }
  }

  onCellEditingStarted(event) {
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      width: 150,
      filter: true,
      suppressKeyboardEvent: params => {
        const key = params.event.key;
        const gridShouldDoNothing = params.editing && (key === 'C' || key === 'c' || key === 'F' || key === 'f' || key === 'o' || key === 'O' || key === 'D' || key === 'd' || key === 'r' || key === 'R' || 
        key === 't' || key === 'T' || key === 'H' || key === 'h' || key === 'I' || key === 'i' || key === 'N' || key === 'n');
        return gridShouldDoNothing;},
    };
    clearInterval(this.intervalViaje);
  }

  onCellEditingStopped(event) {
    let id_nota = '';
    let descrip = '';
    let estadoNota = 'P';
    if (!event.data.id_nota) {
      let idUsuario = localStorage.getItem('id_usuario');
      descrip = event.value;
      let cve_oper = event.data.cia;
      let num_oper = event.data.no_oper;
      let num_viaje = event.data.no_pedido;
      this.apiNotas.postNotas(descrip, idUsuario, cve_oper, num_oper, num_viaje).subscribe({
        next: (idNota) => {
          console.log('nota guardada:', idNota);
        },
        error: (error) => {
          Swal.fire({
            title: 'Error al agregar nota',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });
        },
      });
    } else {
      id_nota = event.data.id_nota;
      descrip = event.value;
      estadoNota = 'P';
      this.apiNotas.updateNota(id_nota, descrip, estadoNota).subscribe({
        next: () => {
          console.log('actualizacion correctamente');
        },
        error: (error) => {
          Swal.fire({
            title: 'Error al actualizar nota',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });
        },
      });
    }
  }
  
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
    const filterModel = JSON.parse(localStorage.getItem('filterModel'));
    if (filterModel) {
      params.api.setFilterModel(filterModel);
    }
  }
  onBtExport() {
    this.datosBitacora = [];
    this.rowData.forEach((rowData) => {
      if (rowData.no_pedido != '' && rowData.st_unidad == "ACTIVO                ") {
        let datosBitacora = {
          viaje: rowData.no_pedido,
          cliente: rowData.nickname,
          'fecha-carga': rowData.fecha_carga,
          'fecha-descarga': rowData.fecha_descarga,
          'circulo serv': rowData.circu_serv,
          operador: rowData.no_oper,
          caja: rowData.no_caja,
          origen: rowData.origen,
          destino: rowData.destino,
          tractor: rowData.no_cdc,
          ultima_posicion: rowData.pinteres,
          comentarios: rowData.nota,
          flota_operador: rowData.jefe_flota_oper,
          flota_tractor: rowData.flotilla_tractor,
        };
        this.datosBitacora.push(Object.values(datosBitacora)); 
      }
    });
    this.crearArchivo(this.datosBitacora);
  }
  crearArchivo(data) {
    let book = new Workbook();
    let doc_title = ['BITACORA'];
    let sheet = book.addWorksheet('Hoja 1');
    sheet.addRow(doc_title);
    sheet.mergeCells('A1:O1');
    sheet.getCell('A1').alignment = {
      vertical: 'middle',
      horizontal: 'center',
    };
    sheet.getCell('A1').font = { bold: true, size: 14 };
    sheet.addRow([]);
    /**Tabla de alertas */
    let tab_header = ['VIAJE', 'CLIENTE', 'FECHA CARGA','FECHA DESCARGA','CIRCULO SERV','OPERADOR','CAJA','ORIGEN', 'DESTINO','TRACTOR',
    'ULTIMA POSICION','COMENTARIOS', 'FLOTA OPERADOR','FLOTA TRACTOR'];
    let header_style = sheet.addRow(tab_header);
    header_style.height = 30;
    sheet.getColumn(1).width = 7;
    sheet.getColumn(1).alignment = { horizontal: 'center' };
    sheet.getColumn(2).width = 10;
    sheet.getColumn(2).alignment = { horizontal: 'center' };
    sheet.getColumn(3).width = 13;
    sheet.getColumn(3).alignment = { horizontal: 'center' };
    sheet.getColumn(4).width = 13;
    sheet.getColumn(4).alignment = { horizontal: 'center' };
    sheet.getColumn(5).width = 10;
    sheet.getColumn(5).alignment = { horizontal: 'center' };
    sheet.getColumn(6).width = 7;
    sheet.getColumn(6).alignment = { horizontal: 'center' };
    sheet.getColumn(7).width = 5;
    sheet.getColumn(7).alignment = { horizontal: 'center' };
    sheet.getColumn(8).width = 10;
    sheet.getColumn(8).alignment = { horizontal: 'center' };
    sheet.getColumn(9).width = 10;
    sheet.getColumn(9).alignment = { horizontal: 'center' };
    sheet.getColumn(10).width = 6;
    sheet.getColumn(10).alignment = { horizontal: 'center' };
    sheet.getColumn(11).width = 45;
    sheet.getColumn(11).alignment = { horizontal: 'center' };
    sheet.getColumn(12).width = 29;
    sheet.getColumn(12).alignment = { horizontal: 'center' };
    sheet.getColumn(13).width = 28;
    sheet.getColumn(13).alignment = { horizontal: 'center' };
    sheet.getColumn(14).width = 28;
    sheet.getColumn(14).alignment = { horizontal: 'center' };
    header_style.eachCell((cell) => {
      cell.fill = {type: 'pattern', pattern: 'solid', fgColor: { argb: '014781' }, bgColor: { argb: 'ffff' }};
      cell.font = { bold: true, color: { argb: 'F2F2F2' }, size: 8 };
      cell.border = {left: { style: 'thin' }, top: { style: 'thin' },right: { style: 'thin' }, bottom: { style: 'thin' }};
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });
    data.forEach((incidencia) => {
      let incidencia_style = sheet.addRow(incidencia);
      incidencia_style.eachCell((cell, number) => {
        cell.border = {top: { style: 'thin' },left: { style: 'thin' },bottom: { style: 'thin' },right: { style: 'thin' }};
        cell.font = { bold: false, size: 8 };
      });
    });
    book.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = 'BITACORA';
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  }

  obtenerNotas(){
   return new Promise((resolve, reject) => {
    this.socket.obtenerNotaViaje().subscribe({
      next: data =>{
        let params = {}
        if (!data.id_viaje){
          this.dataViaje.forEach(viaje =>{
            if (viaje.id_nota == data.id) {
              params = {
                'id_nota' : data.id,
                'nota': data.descripcion
              }
            }
          })
          // this.gridApi.refreshCells(params)
        }
        else{
          this.dataViaje.forEach(viaje =>{
            if (viaje.no_pedido == data.id_viaje) {
              params = {
                'id_nota' : data.id_nota,
                'nota': data.descripcion_nota
              }
            }
          })
          // this.gridApi.refreshCells(params)
        }
        resolve('ok')
      },
      error: error => {
        reject(error)
      }
     })
   })
  }
  obtenerFlotas(): Promise<unknown>{
    return new Promise((resolve, reject) => {
      this.apiViaje.obtenerFlotas().subscribe({
        next: listaFlotas =>{
          this.flotas = listaFlotas
          this.flotas.forEach(flota => {
            if (flota.id == "02" || flota.id =="04" || flota.id == "03" ||
                flota.id == "10" || flota.id == "11"|| flota.id == "16" || flota.id == "27" ) {
              this.flota_ref.push(flota);
              this.flota_ref.sort((x, y) => x.id - y.id)
            }
            if (flota.id == "22" || flota.id =="23" || flota.id == "25") {
              this.flota_seco.push(flota);
              this.flota_seco.sort((x, y) => x.id - y.id)
            }
          } )
          resolve('ok')
        },
        error: error =>{
          if (localStorage.getItem('token')) {
            if (error.status == 401) this.generalService.refreshToken();
          }
          reject(error);
        }
      })
    })
  }

  onPageChange(event){
    if(event.newPage == true){
      this.filtros = {
        bloqueInformacion: this.filtros.bloqueInformacion + 1,
        filters: this.filtros.filters
      }
    }
  }

  onPageSizeChanged(event: any) {
    this.gridApi.rowModel.cacheParams.blockSize = event.target.value;
    this.gridApi.gridOptionsWrapper.setProperty('cacheBlockSize', event.target.value);
    this.gridApi.paginationSetPageSize(event.target.value);
  }

  onFilterChanged(params) {
    this.filtros.filters = params.api.getFilterModel();
    let filtroViaje = params.api.getFilterModel()['no_pedido'];
    let filtroCliente = params.api.getFilterModel()['nickname'];
    let filtroFechaCarga = params.api.getFilterModel()['fecha_carga'];
    let filtroFechaDescarga = params.api.getFilterModel()['fecha_descarga'];
    let filtroProducto = params.api.getFilterModel()['producto'];
    let filtroCirculoServicio = params.api.getFilterModel()['circulo'];
    let filtroOper = params.api.getFilterModel()['no_oper'];
    let filtroCaja = params.api.getFilterModel()['no_caja'];
    let filtroOrigen = params.api.getFilterModel()['origen'];
    let filttoDestino = params.api.getFilterModel()['destino'];
    let filtroTractor = params.api.getFilterModel()['no_cdc']

    if (filtroViaje) {
      this.filtros.filters.no_pedido =  filtroViaje['filter'];
    }
    if (filtroCliente) {
     this.filtros.filters.nickname = filtroCliente['filter'];
    }
    if (filtroFechaCarga) {
     this.filtros.filters.fecha_carga = filtroFechaCarga['filter'];
    }
    if (filtroFechaDescarga) {
      this.filtros.filters.fecha_descarga = filtroFechaDescarga['filter'];
    }
    if (filtroProducto) {
      this.filtros.filters.producto = filtroProducto['filter'];
    }
    if (filtroCirculoServicio) {
       this.filtros.filters.circu_serv = filtroCirculoServicio['filter'];
    }
    if (filtroOper) {
      this.filtros.filters.no_oper = filtroOper['filter'];
    }
    if (filtroCaja) {
      this.filtros.filters.no_caja = filtroCaja['filter'];
    }
    if (filtroTractor) {
      this.filtros.filters.no_cdc = filtroTractor['filter'];
    }
    this.getViaje();
  }
  mostrarViajesTransfrontera(){
    this.mostrarViajesFrio= false;
    this.mostrarViajesTransf = true;
    $(document).ready(function () {
      $('.dropdownMenuViajesButton').on('click', function (e) {
        e.stopPropagation();
      });
    });
    if ($('#dp_viaje_trans').prop('checked') == true) {
      $('#dp_viaje_frio').prop('checked', false)
    }
  }
}
