<main role="main" class="col-12" [ngClass]="{'pl-menu': !navbar}">
  <h5 class="mt-2">CATALOGO - CAJAS</h5>
  <div class="row">
    <!--TOOLBAR-->
    <div class="form-inline col-12">
      <div class="dropdown">
        <button class="btn frio-btn-toolbar dropdown-toggle btn-sm mr-2" type="button" id="dropdownMenuButton"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          LINEAS TRANSPORTISTAS
        </button>
        <div class="dropdown-menu scrollable-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item frio-btn-toolbar"  *ngFor="let linea of lineaTransCaja; let i = index">
            <div class="custom-control custom-checkbox custom-control-inline float-left dropdown-option">
              <input type="checkbox" class="custom-control-input"  id="dm_linea_prime-{{i}}" value="{{linea.nombre}}" (change)="filtrarLineaCaja()"> 
              <label class="custom-control-label" for="dm_linea_prime-{{i}}">{{linea.nombre}}</label>
            </div>
          </a>
        </div>
      </div>
    </div>
    <!--/TOOLBAR-->
  </div>
  <div class="col-12 mt-5">
    <br>
    <div [ngStyle]="style">
      <ag-grid-angular #agGrid_caja class="ag-theme-alpine" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
        [rowData]="rowData" (cellClicked)="onCellClicked($event)" [sideBar]="sideBar"
        (columnMoved)="onColumnMoved($event)" (gridReady)="onGridReady($event)"
        (firstDataRendered)="onFirstDataRendered($event)" domLayout='autoHeight' [pagination]="true"
        [paginationPageSize]="13">
      </ag-grid-angular>
    </div>
  </div>
</main>
<!--Modal info caja-->
<div class="modal fade" id="mdl_info_caja" tabindex="-1" role="dialog" aria-labelledby="mdl_info_caja"
  aria-hidden="true" data-backdrop="static" style="z-index: 10000;">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document" style="width: 100%;">
    <div class="modal-content" *ngFor="let caja of cajaInfo">
      <div class="modal-header bg-primary d-block">
        <button type="button" class="close color-white float-right" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title color-white text-center" id="mdl_info_cajas">CAJA</h5>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <form>
            <fieldset disabled>
              <div class="form-group row form-inline">
                <label>Linea Transportista:</label>
                <div class="input-group input-group-sm col-sm-3">
                  <input id="txt_linea_trans" type="text" class="form-control input-frio-caja" aria-label="Username"
                    aria-describedby="basic-addon1" autocomplete="off" value="{{caja.nombre}}">
                </div>
                <label>No.Caja: </label>
                <div class="input-group input-group-sm col-sm-2">
                  <input id="txt_no_caja" type="text" class="form-control input-frio-caja" aria-label="Username"
                    aria-describedby="basic-addon1" autocomplete="off" value="{{caja.no_caja}}">
                </div>
                <label>STATUS:</label>
                <div class="input-group input-group-sm col-sm-2">
                  <input id="txt_status" type="text" class="form-control input-frio-caja" aria-label="Username"
                    aria-describedby="basic-addon1" autocomplete="off" value="{{caja.status}}">
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group row form-inline">
                    <label>Año:</label>
                    <div class="input-group input-group-sm col-sm-2">
                      <input id="txt_año" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{caja.year_caja}}">
                    </div>
                    <label>Modelo:</label>
                    <div class="input-group input-group-sm col-sm-2">
                      <input id="txt_cond_pago" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{caja.modelo}}">
                    </div>
                    <label>Marca:</label>
                    <div class="input-group input-group-sm col-sm-3">
                      <input id="txt_marca" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{caja.marca}}">
                    </div>
                  </div>
                  <div class="form-group row form-inline">
                    <label>Medidas:</label>
                    <div class="input-group input-group-sm col-sm-2">
                      <input id="txt_medida" type="text" class="form-control input-frio-caja" placeholder="contado"
                        aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                        value="{{caja.medida}}">
                    </div>
                    <div class="input-group input-group-sm col-sm-1"></div>
                    <label>Serie:</label>
                    <div class="input-group input-group-sm col-sm-4">
                      <input id="txt_serie" type="text" class="form-control input-frio-caja" placeholder="NACIONAL"
                        aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                        value="{{caja.no_serie}}">
                    </div>
                  </div>
                  <div class="form-group row form-inline">
                    <label>Placas:</label>
                    <div class="input-group input-group-sm col-sm-3">
                      <input id="txt_placa" type="text" class="form-control input-frio-caja" placeholder="contado"
                        aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                        value="{{caja.placas}}">
                    </div>
                    <label>Marca de Thermos:</label>
                    <div class="input-group input-group-sm col-sm-3">
                      <input id="txt_marca_term" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{caja.marca_termo}}">
                    </div>
                  </div>
                  <div class="form-group row form-inline">
                    <label>No. Thermo:</label>
                    <div class="input-group input-group-sm col-sm-5">
                      <input id="txt_no_thermo" type="text" class="form-control input-frio-caja" placeholder="contado"
                        aria-label="Username" aria-describedby="basic-addon1" autocomplete="off" value="{{caja.termo}}">
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group row form-inline">
                    <label>Ult. Ubicación:</label>
                    <div class="input-group input-group-sm col-sm-4">
                      <input id="txt_medida" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{caja.ubica}}">
                    </div>
                    <label>Fec. Ubicación:</label>
                    <div class="input-group input-group-sm col-sm-3">
                      <input id="txt_serie" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{caja.fech_ulubica}}">
                    </div>
                  </div>
                  <div class="form-group row form-inline">
                    <label>Mes Verificación:</label>
                    <div class="input-group input-group-sm col-sm-4">
                      <input id="txt_medida" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{caja.verificacion}}">
                    </div>
                    <label>Poliza:</label>
                    <div class="input-group input-group-sm col-sm-4">
                      <input id="txt_serie" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{caja.no_polseg}}">
                    </div>
                  </div>
                  <div class="form-group text-center">
                    <label>COMENTARIOS</label>
                    <textarea id="txt_comentarios" class="form-control input-frio-caja" rows="4"
                      value="{{caja.comen}}"></textarea>
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>