import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/service/login/login.service';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TractorService {
  private headers: HttpHeaders;

  constructor(private httpApi: HttpClient, private apiAuth: LoginService) { }
  getTracto(noCaja:any, cveCia:any, statusTractor:any): Observable<any>{
    let httpParams =  new HttpParams()
    .append('noCdc',noCaja)
    .append('cveCia', cveCia)
    .append('status', statusTractor);
    if (noCaja != '' && cveCia != '') {
      return this.httpApi.get<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/tractores?${httpParams}`)
        .pipe(map(data => data));
    }else{
      return this.httpApi.get<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/tractores?${httpParams}`)
        .pipe(map(data => data));
    }
  }
}
