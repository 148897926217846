import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocketwebService {

  constructor(private socket: Socket) {

   }

   getData(){
     return Observable.create((observer) =>{
       this.socket.on('alertas', (data) =>{
         observer.next(data);
       })
     })
   }

   obtenerNotaViaje(){
    return Observable.create((observer) =>{
      this.socket.on('nota_viaje', (data) =>{
        observer.next(data);
      })
    })
   }
}
