<div class="fade modal-tablero" id="mdl_alta_sancion" tabindex="-1" role="dialog" aria-labelledby="mdl_info_caja"
  aria-hidden="true" data-backdrop="static" style="z-index: 10000;">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document" style="width: 100%;">
    <div class="modal-content">
      <div class="modal-header bg-primary d-block">
        <button type="button" class="close color-white float-right" data-dismiss="modal" (click)="cerrarModal()"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title color-white text-center" id="mdl_info_cajas">{{this.modalTitle}}</h5>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <form [formGroup]="formularioSancion">
            <div class="row">
              <div class="col-6">
                <div class="form-group row form-inline" *ngIf="this.modalTitle == 'ALTA DE INCIDENCIA'">
                  <label>INCIDENCIA:</label>
                  <div class="input-group input-group-sm col-sm">
                    <select class="custom-select form-control-sm" id="motivo_sancion" formControlName="motivo_sancion"
                      name="motivo_sancion" (change)="obtenerIdSancion()">
                      <option>SELECCIONAR SANCIÓN</option>
                      <option *ngFor="let sanciones of listaSancion">{{sanciones.nombre}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group row form-inline">
                  <label>VIAJE:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_viaje" type="text" class="form-control form-control-sm" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" formControlName="no_pedido" name="no_pedido"
                      [attr.disabled]="true">
                  </div>
                </div>
                <div class="form-group row form-inline">
                  <label>UNIDAD:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_unidad" type="text" class="form-control form-control-sm" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" formControlName="no_cdc" name="no_cdc"
                      [attr.disabled]="true">
                  </div>
                  <label>No.OPERADOR:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_no_oper" type="text" class="form-control form-control-sm" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" formControlName="no_oper" name="no_oper"
                      [attr.disabled]="true">
                  </div>
                </div>
                <div class="form-group row form-inline">
                  <label>OPERADOR:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_linea_trans" type="text" class="form-control form-control-sm" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" formControlName="nombre_operador" name="nombre_operador"
                      [attr.disabled]="true">
                  </div>
                </div>
                <div class="form-group row form-inline">
                  <label>FLOTA:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_nombre_flota" type="text" class="form-control form-control-sm" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" formControlName="nombre_flota" name="nombre_flota"
                      [attr.disabled]="true">
                  </div>
                </div>
                <div class="form-group row form-inline" *ngIf="this.modalTitle == 'ALTA DE INCIDENCIA'">
                  <label class="label-alerta">NOTIFICAR A OTRA AREA</label>
                  <div class="input-group col-sm-4 input-group-sm">
                    <select class="custom-select" formControlName="notificar_area" name="notificar_area">
                      <option value="SI">SI</option>
                      <option value="NO">NO</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group text-center">
                  <label>REPORTE: </label>
                  <span class="text-danger animated fadeIn" *ngIf="this.formularioSancion.value.reporte == ''"><br>Campo obligatorio</span>
                  <textarea id="txt_reporte" class="form-control" rows="4" formControlName="reporte" name="reporte"></textarea>
                </div>
                <div class="row" *ngIf="this.modalTitle == 'ALTA DE INCIDENCIA' && this.formularioSancion.value.notificar_area == 'SI'">
                  <label class="label-alerta">NOTIFICAR A: </label>
                </div>
                <div class="row" *ngIf="this.formularioSancion.value.notificar_area == 'SI'">
                  <div class="col-8 row">
                    <div class="form-group form-check-inline">
                      <input type="checkbox" class="form-check-input" id="ch_atenc_oper" (change)="asignarCorreos()">
                      <label class="form-check-label" for="ch_atenc_oper">OPERACION
                        REFRIGERADO</label>
                    </div>
                    <div class="form-group form-check-inline">
                      <input type="checkbox" class="form-check-input" id="ch_atenc_oper_seco"
                        (change)="asignarCorreos()">
                      <label class="form-check-label" for="ch_atenc_oper_seco">OPERACION
                        SECO</label>
                    </div>
                    <div class="form-group form-check-inline">
                      <input type="checkbox" class="form-check-input" id="ch_atenc_clientes"
                        (change)="asignarCorreos()">
                      <label class="form-check-label" for="ch_atenc_clientes">ATENCION A
                        CLIENTES</label>
                    </div>
                  </div>
                  <div class="col-4 row">
                    <div class="form-group form-check-inline">
                      <input type="checkbox" class="form-check-input" id="ch_mantenimiento" (change)="asignarCorreos()">
                      <label class="form-check-label" for="ch_mantenimiento">MANTENIMIENTO</label>
                    </div>
                    <div class="form-group form-check-inline">
                      <input type="checkbox" class="form-check-input" id="ch_rh" (change)="asignarCorreos()">
                      <label class="form-check-label" for="ch_rh">RH</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer" *ngIf="this.modalTitle == 'ALTA DE INCIDENCIA'">
              <button type="button" class="btn btn-danger" data-dismiss="modal"
                (click)="cerrarModal()">CANCELAR</button>
              <a *ngIf="this.correoDestino != ''" href="mailto:{{this.correoSancion}}" target="_blank">
                <button type="button" class="btn btn-primary" (click)="guardarReporteSancion()"
                [disabled]="!formularioSancion.valid">GUARDAR</button></a>
              <a *ngIf="this.correoDestino == ''" href="mailto:{{this.correoSancion}}" target="_blank">
                <button type="button" class="btn btn-primary" (click)="guardarReporteSancion()"
                [disabled]="!formularioSancion.valid">GUARDAR</button></a>
            </div>
            <div class="modal-footer" *ngIf="this.modalTitle != 'ALTA DE INCIDENCIA'">
              <button type="button" class="btn btn-danger" data-dismiss="modal"
                (click)="cerrarModal()">CANCELAR</button>
              <a href="mailto:{{this.notificacion}}" target="_blank">
                <button type="button" class="btn btn-primary" (click)="guardarReporteSancion()"
                  [disabled]="!formularioSancion.valid">GUARDAR</button></a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>