<main role="main" class="col-12" [ngClass]="{'pl-menu': !navbar}">
    <h5 class="mt-2">CATALOGO-TRACTOS</h5>
    <div class="row">
        <!--TOOLBAR-->
        <div class="form-inline col-12">
            <div class="dropdown">
                <button class="btn frio-btn-toolbar dropdown-toggle btn-sm mr-2" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    FILTRAR POR STATUS DEL TRACTOR
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_accidentado" (change)="filtroStatusTractor()">
                            <label class="custom-control-label" for="dp_accidentado">ACCIDENTADO</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_activo" (change)="filtroStatusTractor()">
                            <label class="custom-control-label" for="dp_activo">ACTIVO</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_a_para_venta" (change)="filtroStatusTractor()">
                            <label class="custom-control-label" for="dp_a_para_venta">APARTADO PARA VENTA</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_inactivo" (change)="filtroStatusTractor()">
                            <label class="custom-control-label" for="dp_inactivo">INACTIVO</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_nuevo"(change)="filtroStatusTractor()">
                            <label class="custom-control-label" for="dp_nuevo">NUEVO</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_para_venta" (change)="filtroStatusTractor()">
                            <label class="custom-control-label" for="dp_para_venta">PARA VENTA</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_patio" (change)="filtroStatusTractor()">
                            <label class="custom-control-label" for="dp_patio">PATIO</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_servicio_ded" (change)="filtroStatusTractor()">
                            <label class="custom-control-label" for="dp_servicio_ded">SERVICIO DEDICADO</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_sin_oper" (change)="filtroStatusTractor()">
                            <label class="custom-control-label" for="dp_sin_oper">SIN OPERADOR</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_taller" (change)="filtroStatusTractor()">
                            <label class="custom-control-label" for="dp_taller">TALLER</label>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <!--/TOOLBAR-->
    </div>
    <div class="col-12 mt-5">
        <div [ngStyle]="style">
            <ag-grid-angular #agGrid_tracto class="ag-theme-alpine"
                [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowData]="rowData"
                (cellClicked)="onCellClicked($event)"
                (columnMoved)="onColumnMoved($event)" (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
</main>
<!--Modal info tractor-->
<div class="modal fade" id="mdl_info_tracto" tabindex="-1" role="dialog" aria-labelledby="#mdl_info_tracto"
    aria-hidden="true" data-backdrop="static" style="z-index: 10000;">
    <div class="modal-dialog modal-xl modal-dialog-centerd" role="document">
        <div class="modal-content" *ngFor="let tracto of tractoInfo">
            <div class="modal-header bg-primary d-block">
                <button type="button" class="close color-white float-right" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title color-white text-center">TRACTOR</h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <form>
                        <fieldset disabled>
                            <div class="form-group row justify-content-start">
                                <div class="col-6">
                                    <div class=" form-group row form-inline">
                                        <label>No.Tractor:</label>
                                        <div class="input-group input-group-sm col-sm">
                                            <input id="txt_no_tracto" type="text" class="form-control input-frio-caja"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                value="{{tracto.no_cdc}}">
                                        </div>
                                        <label>No.Tmc: </label>
                                        <div class="input-group input-group-sm col-sm">
                                            <input id="txt_no_tmc" type="text" class="form-control input-frio-caja"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                value="{{tracto.no_tmc}}">
                                        </div>
                                    </div>
                                    <div class="form-group row form-inline ">
                                        <label>Linea Transportista:</label>
                                        <div class="input-group input-group-sm col-sm">
                                            <input id="txt_linea_trans" type="text" class="form-control input-frio-caja"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                value="{{tracto.nombre}}">
                                        </div>
                                    </div>
                                    <div class="form-group row form-inline ">
                                        <label>COMPAÑIA:</label>
                                        <div class="input-group input-group-sm col-sm">
                                            <input id="txt_linea_trans" type="text" class="form-control input-frio-caja"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                value="{{tracto.razon_social}}">
                                        </div>
                                    </div>
                                    <div class=" form-group row form-inline ">
                                        <label>Año:</label>
                                        <div class="input-group input-group-sm col-sm">
                                            <input id="txt_year" type="text" class="form-control input-frio-caja"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                value="{{tracto.year_trac}}">
                                        </div>
                                        <label>Modelo: </label>
                                        <div class="input-group input-group-sm col-sm">
                                            <input id="txt_mod" type="text" class="form-control input-frio-caja"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                value="{{tracto.modelo}}">
                                        </div>
                                    </div>
                                    <div class=" form-group row form-inline">
                                        <label>No.Motor:</label>
                                        <div class="input-group input-group-sm col-sm">
                                            <input id="txt_no_motor" type="text" class="form-control input-frio-caja"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                value="{{tracto.no_motor}}">
                                        </div>
                                        <label>Tarjeta IAVE:</label>
                                        <div class="input-group input-group-sm col-sm-4">
                                            <input id="txt_iave" type="text" class="form-control input-frio-caja"
                                                placeholder="activo" aria-label="Username"
                                                aria-describedby="basic-addon1" autocomplete="off"
                                                value="{{tracto.no_tiave}}">
                                        </div>
                                    </div>
                                    <div class="form-group row form-inline">
                                        <label>Placas:</label>
                                        <div class="input-group input-group-sm col-sm">
                                            <input id="txt_placa" type="text" class="form-control input-frio-caja"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                value="{{tracto.placas}}">
                                        </div>
                                        <label>Tarjeta Zacatecas:</label>
                                        <div class="input-group input-group-sm col-sm">
                                            <input id="txt_tarjeta_zac" type="text" class="form-control input-frio-caja"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                value="{{tracto.televia}}">
                                        </div>
                                    </div>
                                    <div class="form-group row form-inline">
                                        <label>Mes/Año:</label>
                                        <div class="input-group input-group-sm col-sm">
                                            <input id="txt_mes_ano" type="text" class="form-control input-frio-caja"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                value="{{tracto.anio_verificacion}}">
                                        </div>
                                        <label>Verificación:</label>
                                        <div class="input-group input-group-sm col-sm">
                                            <input id="txt_verif" type="text" class="form-control input-frio-caja"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                value="{{tracto.verificacion}}">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class=" form-group row form-inline">
                                        <label>Status:</label>
                                        <div class="input-group input-group-sm col-sm">
                                            <input id="txt_status" type="text" class="form-control input-frio-caja"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                value="{{tracto.status}}">
                                        </div>
                                        <label>Marca:</label>
                                        <div class="input-group input-group-sm col-sm">
                                            <input id="txt_marca" type="text" class="form-control input-frio-caja"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                value="{{tracto.marca}}">
                                        </div>
                                    </div>
                                    <div class=" form-group row form-inline">
                                        <label>No.Serie:</label>
                                        <div class="input-group input-group-sm col-sm">
                                            <input id="txt_serie" type="text" class="form-control input-frio-caja"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                value="{{tracto.no_serie}}">
                                        </div>
                                    </div>
                                    <div class="form-group text-center">
                                        <label>COMENTARIOS</label>
                                        <div class="input-group align-self-end">
                                            <textarea id="txt_coment" class="form-control input-frio-caja" rows="5"
                                                value="{{tracto.comentarios}}"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>