import { Component, OnInit, Input } from '@angular/core';
import { OperadoresService } from 'src/app/service/operadores/operadores.service'; 
import { NgxSpinnerService } from 'ngx-spinner';
import { CanTokenService } from 'src/app/service/guards/can-token.service';

declare var $: any;
declare const jQuery: any;

@Component({
  selector: 'app-operador',
  templateUrl: './operador.component.html',
  styleUrls: ['./operador.component.css']
})
export class OperadorComponent implements OnInit {

  @Input() navbar: boolean = false;
  operList: any = [];
  catalogoOper:any = [];
  noOper:string = '';
  cia:string = '';
  operInfo:any= [];
  imagenOperador = ''
  imagen:boolean;
  avatar:boolean;
  columnDefs:any = [];
  rowData:any;
  dato:any;
  nombreCol:any;
  gridColumnApi:any;
  defaultColDef:any;
  rowSelection:any;
  style = {
    width: '100%',
    height: '100%',
    flex: '1 1 auto'
  };
  division:any = [];
  ageType:any

  constructor(private apiOper: OperadoresService, private spinnerService: NgxSpinnerService, private generalService: CanTokenService) {
    window.scroll(0, 0);
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      width: 150,
      filter: true,
      floatingFilter: true,
    };
    this.rowSelection = 'multiple';
   }

  ngOnInit() {
    this.getOper();
  }
  
  getOper(){
    this.spinnerService.show();
      this.apiOper.getOper(this.noOper,this.cia).subscribe({
        next: oper => {
          if (oper) {
            this.operList = oper;
            this.columnDefs = [
             {field: "division", headerName:"DIVISION", width: 180,  sortable: true,filter: 'agTextColumnFilter', floatingFilterComponentParams: { suppressFilterButton: true }},
             {field: "driverno", headerName:"NO.OPERADOR", sortable: true, filter: 'agTextColumnFilter',width: 250, floatingFilterComponentParams: { suppressFilterButton: true }},
             {field: "nombre", headerName:"NOMBRE", sortable: true, filter: 'agTextColumnFilter',width: 350, floatingFilterComponentParams: { suppressFilterButton: true }},
             {field: "ape_p", headerName: "APELLIDO PATERNO", sortable: true, width: 350, floatingFilterComponentParams: { suppressFilterButton: true }},
             {field: "ape_m", headerName:"APELLIDO MATERNO", sortable: true, width: 350, floatingFilterComponentParams: { suppressFilterButton: true }},
             {field: "nickname", headerName:"NICKNAME", width: 170,  sortable: true,filter: 'agTextColumnFilter',ffloatingFilterComponentParams: { suppressFilterButton: true }},
             {field: "tractor", headerName:"TRACTOR", width: 170,  sortable: true,filter: 'agTextColumnFilter', floatingFilterComponentParams: { suppressFilterButton: true } },
             {field: "razon_social", headerName:"COMPAÑIA", width: 170,  sortable: true,filter: 'agTextColumnFilter', floatingFilterComponentParams: { suppressFilterButton: true } },
           ]
           this.rowData = this.operList;
           this.spinnerService.hide();
         } else{
           setTimeout(() => {
             this.spinnerService.hide(); //Oculta el spinner de cargando
           }, 300);
         }
        },
        error: error => {
          if(localStorage.getItem('token')) {
            if (error.status == 401) this.generalService.refreshToken();
          }  
        this.spinnerService.hide();
      }
    });
  }

  setWidthAndHeight(width, height) {
    this.style = {
      flex: '20px',
      width: width,
      height: height
    };
  }
  onGridReady(params) {
    this.gridColumnApi = params.columnApi;
    var columnState = JSON.parse(localStorage.getItem('myColumnState'))
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }
  onColumnMoved(e) {
    let columnState = JSON.stringify(e.columnApi.getColumnState());
    localStorage.setItem('myColumnState', columnState);
  }

  onColumnVisible(e) {
    console.log('Event Column Visible', e);
  }
  onCellClicked(e) {
    this.dato = e['data'];
    this.nombreCol = e['colDef']['field'];
    if (this.nombreCol == 'driverno') {
      this.infoModal(this.dato);
      jQuery.noConflict();
      $('#mdl_info_oper').modal('show');
      $('#mdl_info_oper').draggable({
        handle: ".modal-header"
      });
    }
  }

  infoModal(dato:any){
    let cia;
    if (dato['division'] == 'REFRIGERADO') {
      cia = '01';
    }else if (dato['division'] == 'SECO       ') {
      cia = '16'
    }
    let noOper = dato['driverno'];
    this.imagenOperador = ''
    this.apiOper.getOper(noOper,cia).subscribe(info=>{
      const numeroOper = noOper.trim();
      const companiaOper = cia.trim();
      this.apiOper.obtenerFotoOperador(companiaOper, numeroOper).subscribe(datos =>{
        this.avatar = true;
        this.imagen = false;
        if (datos.length > 0) {
          this.imagenOperador = datos.url_fotografia
          this.imagen = true;
          this.avatar = false
        }
      });
      this.operInfo = info;
    });
  }


  filtroDivision(){
    let filtroDiv = {};
    this.division = [];
    $(document).ready(function () {
      $('.dropdown-menu').on('click', function (e) {
        e.stopPropagation();
      });
    });
    if ($('#dp_ref').prop('checked') == true) {
      filtroDiv = filtroDiv === '' ? {division: 'REFRIGERADO'} :this.division.push({division: 'REFRIGERADO'})
    }
    if ($('#dp_seco').prop('checked') == true) {
      filtroDiv = filtroDiv === '' ? {division: 'SECO'} : this.division.push({division: 'SECO'})
    }
    this.ageType = this.division;
    this.filtro(this.ageType)
  }

  filtro(datos){
    let filtroDivisionOper = [];
    let divOper = datos;
    let cont = 0;
    let divisionOper = '';
    let totalDivision = divOper.length;
     for (cont; cont < totalDivision; cont++) {
       let div = divOper[cont];
       divisionOper = div.division;
       this.rowData = [];
       this.operList.forEach(datos => {
        if (datos.division == divisionOper) {
          filtroDivisionOper.push(datos);
        }
      });
     }
     if (filtroDivisionOper.length == 0) {
       this.rowData = this.operList;
     }else{
       this.rowData = filtroDivisionOper; 
     }
  }

}
