import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/service/login/login.service';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CajaService {
  private headers: HttpHeaders;
  
  constructor(private httpApi: HttpClient, private apiAuth: LoginService) { }

  getCaja(noCaja:any, cveCia:any, idLinTract:any, nombreLineaFiltro?): Observable<any>{
    let caja = noCaja;
    let cia = cveCia;
    let linTrans = idLinTract;
    let nombreLinea = nombreLineaFiltro
    let httpParams = new HttpParams()
    .append('linTract', linTrans);
    if (nombreLineaFiltro) {httpParams = httpParams.append('nombre_linea', nombreLinea)}
    if (cia !== '' && caja !=='' ) {httpParams= httpParams.append('noCaja', caja).append('cveCia', cia)}
    return this.httpApi.get<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/cajas?${httpParams}`);
  }
  getLineaCaja():Observable<any>{
    return this.httpApi.get<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/lineas`)
  }
  
}
