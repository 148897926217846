import { Component, OnInit, Input } from '@angular/core';
import { MensajeService } from 'src/app/service/mensaje/mensaje.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { CanTokenService } from 'src/app/service/guards/can-token.service';

declare var $: any;
declare const jQuery: any;

@Component({
  selector: 'app-mensaje',
  templateUrl: './mensaje.component.html',
  styleUrls: ['./mensaje.component.css']
})
export class MensajeComponent implements OnInit {

  @Input() navbar: boolean = false;
  constructor(private apiMensaje: MensajeService, public spinnerService: NgxSpinnerService, private generalService: CanTokenService) {
    window.scroll(0, 0);
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      width: 150,
      filter: true,
      floatingFilter: true
    };
    this.rowSelection = 'multiple';
   }
  catalogoMensaje:any = [];
  modalTitle :string = '';
  tipoMensaje:string = '';
  selectTipoMens:string ='';
  descrip: string ='';
  datosMensaje:any = '';
  idMensaje:any = '';
  columnDefs:any = [];
  rowData:any;
  dato:any;
  nombreCol:any;
  gridColumnApi:any;
  defaultColDef:any;
  rowSelection:any;
  style = {
    width: '95%',
    height: '100%',
    flex: '1 1 auto'
  };
  filtro_mens:any = [];
  ageType:any;
  idUsuario:any = ''

  ngOnInit() {
    this.getMensege();
    this.idUsuario = localStorage.getItem('id_usuario')
  }
  getMensege(){
    this.spinnerService.show();
    this.apiMensaje.getMensaje().subscribe(data =>{
      this.catalogoMensaje = data;
      this.catalogoMensaje.forEach(mensaje => {
        if (mensaje.activo == '1') {
          mensaje.activo = 'ACTIVO'
        }
      });
      this.columnDefs = [
        {field: "tipo", headerName:"NOMBRE", sortable: true, filter: 'agTextColumnFilter', width: 280, floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: "descripcion", headerName:"DESCRIPCION", sortable: true,  filter: 'agTextColumnFilter', width: 350, floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: "activo", headerName: "ESTADO", sortable: true, width: 275,  filter: 'agTextColumnFilter',  floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: "id_usuario_alta", headerName:"USUARIO ALTA", sortable: true,  filter: 'agTextColumnFilter', width: 350, floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: 'editar', width: 165, filter: false, cellRenderer: function (params) { return '<i class="far fa-edit" style="color:#BF343F;"></i>' } },
        {field: 'eliminar', width: 165, filter: false, cellRenderer: function (params) { return '<i class="fas fa-minus-circle" style="color:#BF343F;"></i>' } }
      ]
      this.rowData = this.catalogoMensaje;
      this.spinnerService.hide();
    })
  }

  detailMenssage(datos:any){
    this.datosMensaje = datos;
    jQuery.noConflict();
    $('#mdl_det_mensaje').modal('show');
  }

  saveMensaje(){
    if (this.modalTitle == 'Editar Mensaje') {
      let idMensaj = this.idMensaje;
      let tipoMensaje = this.selectTipoMens.toUpperCase();
      let description= this.descrip
      this.apiMensaje.putMensaje(idMensaj, tipoMensaje, description).subscribe(update =>{
        if(update == null){
          $(document).ready(function () {
            jQuery.noConflict();
            $('#mdl_agr_men').modal('hide');
          })
          Swal.fire({
            title: 'Mensaje actualizado correctamente',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          }).then((result) =>{
            if (result.isConfirmed) {
              this.getMensege();
            }
          });
        }
      });
    } else {
      let tipoMensaje = this.selectTipoMens.toUpperCase()
      let description= this.descrip
      let usuario = this.idUsuario;
      this.apiMensaje.postMensaje(tipoMensaje, description, usuario).subscribe({
        next: data =>{
          if(data['id_nuevo_mensaje'] != ''){
            $(document).ready(function () {
              jQuery.noConflict();
              $('#mdl_agr_men').modal('hide');
            })
            Swal.fire({
              title: 'Mensaje creado correctamente',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then((result) =>{
              if (result.isConfirmed) {
                this.getMensege();
              }
            });
          }
        },
        error: error => {
          if (localStorage.getItem('token')) {
            if (error.status == 401) this.generalService.refreshToken();
          }
        }
      })
    }
  }
  openModal(mensaje, action){
    if (action == 'edit') {
      this.modalTitle = 'Editar Mensaje';
      this.idMensaje = mensaje.id_mensaje;
      this.selectTipoMens = mensaje.tipo;
      this.descrip = mensaje.descripcion;
      jQuery.noConflict();
      $('#mdl_agr_men').modal('show');
    } else if (action == 'add') {
      this.modalTitle = 'Nuevo Mensaje'
      this.selectTipoMens = " ";
      this.descrip = " ";
    }
  }
  deletMensaje(id: any) {
    Swal.fire({
      title: '¿Desea eliminar el mensaje?',
      showCancelButton: true,
      confirmButtonText: `SI`,
      confirmButtonColor: '#004680',
      cancelButtonText: 'CANCELAR',
      cancelButtonColor: '#BF343F'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        this.apiMensaje.deleteMensaje(id).subscribe(mensaje => {
          if (mensaje != null) {
            jQuery.noConflict();

            this.getMensege();
          }
        })
      }
    })
  }

  setWidthAndHeight(width, height) {
    this.style = {
      flex: '20px',
      width: width,
      height: height
    };
  }
  onGridReady(params) {
    this.gridColumnApi = params.columnApi;
    var columnState = JSON.parse(localStorage.getItem('myColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }
  onColumnMoved(e) {
    let columnState = JSON.stringify(e.columnApi.getColumnState());
    localStorage.setItem('myColumnState', columnState);
  }

  onColumnVisible(e) {
    console.log('Event Column Visible', e);
  }
  onCellClicked(e) {
    this.dato = e['data'];
    this.nombreCol = e['colDef']['field'];
    if (this.nombreCol == 'editar') {
      this.openModal(this.dato, 'edit');
      jQuery.noConflict();
      $('#mdl_agregar').modal('show');
    }
    if (this.nombreCol == 'eliminar') {
      this.deletMensaje(this.dato['id_mensaje'])
    }
    if (this.nombreCol == 'tipo'|| this.nombreCol == 'descripcion' ) {
      this.detailMenssage(this.dato)
    }
    if (this.nombreCol == 'editar' ) {
      this.openModal(this.dato, 'edit')
    }
  }

  
}
