<main role="main" class="col-12" [ngClass]="{'pl-menu': !navbar}">
    <h5 class="mt-2">CATALOGO-OPERADORES</h5>
    <div class="row">
        <!--TOOLBAR-->
        <div class="form-inline mt-2 col-12">
            <div class="dropdown">
                <button class="btn frio-btn-toolbar dropdown-toggle btn-sm mr-2" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    DIVISION
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_ref"
                                (change)="filtroDivision()">
                            <label class="custom-control-label" for="dp_ref">REFRIGERADO</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_seco"
                                (change)="filtroDivision()">
                            <label class="custom-control-label" for="dp_seco">SECO</label>
                        </div>
                    </a>
                    
                </div>
            </div>
        </div>
        <!--/TOOLBAR-->
    </div>
    <div class="col-12 mt-5">
        <br>
        <div [ngStyle]="style">
            <ag-grid-angular #agGrid_mensaje class="ag-theme-alpine"
                [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowData]="rowData"
                (cellClicked)="onCellClicked($event)" [sideBar]="false"
                (columnMoved)="onColumnMoved($event)" (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
</main>
<div class="modal fade" id="mdl_info_oper" tabindex="-1" role="dialog" aria-labelledby="#mdl_info_oper"
    aria-hidden="true" data-backdrop="static" style="z-index: 10000;">
    <div class="modal-dialog modal-xl modal-dialog-centerd" role="document">
        <div class="modal-content" *ngFor="let ope of operInfo">
            <div class="modal-header bg-primary d-block">
                <button type="button" class="close color-white float-right" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title color-white text-center">OPERADOR</h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <form>
                        <fieldset disabled>
                            <div class="form-group row align-items-center">
                                <div class="col-2" *ngIf="avatar">
                                    <img src="../../../../../../../assets/general/img/avatar.png" />
                                </div>
                                <div class="col-2" *ngIf="imagen">
                                    <img style="width: auto; height: 20vh;" src="{{imagenOperador}}" />
                                </div>
                                <div class="col">
                                    <div class="form-group row justify-content-end">
                                        <div class="form-inline">
                                            <label>No. Operador:</label>
                                            <div class="input-group col align-self-end">
                                                <input id="txt_no_oper" type="text" class="form-control input-frio-caja"
                                                    aria-label="Username"
                                                    aria-describedby="basic-addon1" autocomplete="off"
                                                    value="{{ope.driverno}}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row justify-content-end">
                                        <div class="form-inline ">
                                            <label>Tractor:</label>
                                            <div class="input-group col align-self-end">
                                                <input id="txt_tractor" type="text" class="form-control input-frio-caja"
                                                    aria-label="Username"
                                                    aria-describedby="basic-addon1" autocomplete="off"
                                                    value="{{ope.tractor}}">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group row justify-content-center">
                                        <div class="form-inline">
                                            <label>Status:</label>
                                            <div class="input-group col align-self-end">
                                                <input id="txt_status" type="text" class="form-control input-frio-caja"
                                                   aria-label="Username"
                                                    aria-describedby="basic-addon1" autocomplete="off"
                                                    value="{{ope.status}}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row justify-content-center">
                                        <div class="form-inline">
                                            <label>Caja: </label>
                                            <div class="input-group col align-self-end">
                                                <input id="txt_caja" type="text" class="form-control input-frio-caja"
                                                    aria-label="Username"
                                                    aria-describedby="basic-addon1" autocomplete="off"
                                                    value="{{ope.trailer}}">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group row justify-content-end">
                                        <div class="form-inline">
                                            <label>Tipo Operador:</label>
                                            <div class="input-group col align-self-end">
                                                <input id="txt_tipo_oper" type="text"
                                                    class="form-control input-frio-caja" aria-label="Username"
                                                    aria-describedby="basic-addon1" autocomplete="off"
                                                    value="{{ope.tipo_oper}}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row justify-content-end">
                                        <div class="form-inline">
                                            <label>Fecha Alta:</label>
                                            <div class="input-group col align-self-end">
                                                <input id="txt_fecha_alt" type="text"
                                                    class="form-control input-frio-caja" 
                                                    aria-label="Username" aria-describedby="basic-addon1"
                                                    autocomplete="off" value="{{ope.fecha_alt}}">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-center">
                                <div class="col">
                                    <div class="form-group row justify-content-end">
                                        <div class="form-inline col">
                                            <label>Nombre:</label>
                                            <div class="input-group col align-self-end">
                                                <input id="txt_nombre_oper" type="text"
                                                    class="form-control input-frio-caja" 
                                                    aria-label="Username" aria-describedby="basic-addon1"
                                                    autocomplete="off" value="{{ope.nombreoperador}}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row justify-content-end">
                                        <div class="form-inline col">
                                            <label>Nickname:</label>
                                            <div class="input-group col align-self-end">
                                                <input id="txt_nickname" type="text"
                                                    class="form-control input-frio-caja" aria-label="Username"
                                                    aria-describedby="basic-addon1" autocomplete="off"
                                                    value="{{ope.nickname}}">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group row justify-content-center">
                                        <div class="form-inline">
                                            <label>Apellido Paterno:</label>
                                            <div class="input-group col align-self-end">
                                                <input id="txt_ape_paterno" type="text"
                                                    class="form-control input-frio-caja"
                                                    aria-label="Username" aria-describedby="basic-addon1"
                                                    autocomplete="off" value="{{ope.ape_p}}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row justify-content-center">
                                        <div class="form-inline">
                                            <label>Fec. Nacimiento:</label>
                                            <div class="input-group col align-self-end">
                                                <input id="txt_fec_nac" type="text" class="form-control input-frio-caja"
                                                    aria-label="Username"
                                                    aria-describedby="basic-addon1" autocomplete="off"
                                                    value="{{ope.fecha_nac}}">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group row justify-content-end">
                                        <div class="form-inline">
                                            <label>Apellido Materno:</label>
                                            <div class="input-group col align-self-end">
                                                <input id="txt_ape_materno" type="text"
                                                    class="form-control input-frio-caja"
                                                    aria-label="Username" aria-describedby="basic-addon1"
                                                    autocomplete="off" value="{{ope.ape_m}}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row justify-content-end">
                                        <div class="form-inline">
                                            <label>RFC:</label>
                                            <div class="input-group col align-self-end">
                                                <input id="txt_rfc" type="text" class="form-control input-frio-caja"
                                                    aria-label="Username"
                                                    aria-describedby="basic-addon1" autocomplete="off"
                                                    value="{{ope.rfc}}">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-center form-inline">
                                <label>Jefe de Flota:</label>
                                <div class="input-group col-6">
                                    <input id="txt_jefe_flota" type="text" class="form-control input-frio-caja"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1" autocomplete="off" value="{{ope.nombref}}">
                                </div>
                            </div>
                            <div class="form-group row align-items-center form-inline">
                                <label>Empresa:</label>
                                <div class="input-group col-6">
                                    <input id="txt_empresa" type="text" class="form-control input-frio-caja"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1" autocomplete="off" value="{{ope.razon_social}}">
                                </div>
                            </div>
                            <div class="form-group row align-items-center form-inline">
                                <label>Calle y Número:</label>
                                <div class="input-group col">
                                    <input id="txt_calle_num" type="text" class="form-control input-frio-caja"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1" autocomplete="off" value="{{ope.calle_no}}">
                                </div>
                            </div>
                            <div class="form-group row justify-content-start">
                                <div class="form-inline col">
                                    <label>Colonia:</label>
                                    <div class="input-group col">
                                        <input id="txt_col" type="text" class="form-control input-frio-caja"
                                            aria-label="Username"
                                            aria-describedby="basic-addon1" autocomplete="off" value="{{ope.colonia}}">
                                    </div>
                                </div>
                                <div class="form-inline col">
                                    <label>Ciudad:</label>
                                    <div class="input-group col">
                                        <input id="txt_ciud" type="text" class="form-control input-frio-caja"
                                             aria-label="Username" aria-describedby="basic-addon1"
                                            autocomplete="off" value="{{ope.ciudad}}">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-center">
                                <div class="col-8">
                                    <div class="form-group row justify-content-end">
                                        <div class="form-inline col">
                                            <label>Estado:</label>
                                            <div class="input-group col align-self-end">
                                                <input id="txt_edo" type="text" class="form-control input-frio-caja"
                                                     aria-label="Username"
                                                    aria-describedby="basic-addon1" autocomplete="off"
                                                    value="{{ope.edo}}">
                                            </div>
                                        </div>
                                        <div class="form-inline col">
                                            <label>C.P:</label>
                                            <div class="input-group col align-self-end">
                                                <input id="txt_cod_post" type="text"
                                                    class="form-control input-frio-caja"
                                                    aria-label="Username" aria-describedby="basic-addon1"
                                                    autocomplete="off" value="{{ope.cp}}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row justify-content-end">
                                        <div class="form-inline col">
                                            <label>Tel. Casa:</label>
                                            <div class="input-group col align-self-end">
                                                <input id="txt_tel_casa" type="text"
                                                    class="form-control input-frio-caja"
                                                    aria-label="Username" aria-describedby="basic-addon1"
                                                    autocomplete="off" value="{{ope.tel_casa}}">
                                            </div>
                                        </div>
                                        <div class="form-inline col">
                                            <label>Tel. Familiar:</label>
                                            <div class="input-group col align-self-end">
                                                <input id="txt_tel_fam" type="text" class="form-control input-frio-caja"
                                                     aria-label="Username"
                                                    aria-describedby="basic-addon1" autocomplete="off"
                                                    value="{{ope.tel_fam}}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row justify-content-start">
                                        <div class="form-group text-center col">
                                            <label>COMENTARIOS</label>
                                            <div class="input-group align-self-end">
                                                <textarea id="txt_comentarios" class="form-control input-frio-caja"
                                                    rows="5" value="{{ope.comen}}"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 align-self-center">
                                    <div class="form-group row justify-content-end">
                                        <div class="form-inline">
                                            <label>Tel.Movil:</label>
                                            <div class="input-group col">
                                                <input id="txt_movil" type="tel" class="form-control input-frio-caja"
                                                     aria-label="Username"
                                                    aria-describedby="basic-addon1" autocomplete="off"
                                                    value="{{ope.tel_movil}}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row justify-content-end">
                                        <div class="form-inline">
                                            <label>Tel.Movil 1:</label>
                                            <div class="input-group col">
                                                <input id="txt_movil_uno" type="tel"
                                                    class="form-control input-frio-caja" aria-label="Username"
                                                    aria-describedby="basic-addon1" autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row justify-content-end">
                                        <div class="form-inline">
                                            <label>Tel.Movil 2:</label>
                                            <div class="input-group col">
                                                <input id="txt_movil_dos" type="tel"
                                                    class="form-control input-frio-caja" aria-label="Username"
                                                    aria-describedby="basic-addon1" autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>