<main role="main" class="col-12" [ngClass]="{'pl-menu': !navbar}">
    <h5 class="mt-2">AGENDA DE PERMISOS</h5>
    <div class="row">
        <div class="col-12 mt-3">
            <full-calendar #calendar [options]="calendarOptions" ></full-calendar>
        </div>
    </div>
    <div class="row">
        <div class="col-12 mt-5">
            <br>
            <div [ngStyle]="style">
                <ag-grid-angular #agGrid class="ag-theme-alpine"
                    [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowData]="rowData"
                     [sideBar]="false"
                    (gridReady)="onGridReady($event)"
                    domLayout='autoHeight'
                    [pagination]="true"
                    [paginationPageSize]="13">
                </ag-grid-angular>
            </div>
        </div>
    </div>
</main>