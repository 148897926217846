import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CanTokenService } from 'src/app/service/guards/can-token.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(
    private cookieService: CookieService,
    private generalService: CanTokenService
  ) { }

  validateUserSession() {
    return new Promise<void>((resolve) => {
      if (!this.cookieService.check('USID')) {
        window.location.href = environment.loginUrl;
        return resolve();
      }

      const USID = this.cookieService.get('USID');
      this.generalService.getUserToken(USID, environment.appId).subscribe({
        next: (data: any) => {
          localStorage.setItem('token', data.token);
          resolve();
        },
        error: error => {
          resolve();
        }
      });
    });
  }
}
