import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/service/login/login.service';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PendientesService {
  private headers: HttpHeaders;

  constructor(private httpApi: HttpClient, private apiAuth: LoginService) { }

  getPendientes(): Observable<any>{
    return this.httpApi.get<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/pendientes`)
    .pipe(map( data => data));
  }
  postPendientes(turno:string, descri:string,  idNota:string, tipoPendiente:string, idUsuario:string, idCompania:string, idOperador, idViaje:string, idFlota:string, flota:string, idUnidad:string): Observable<any>{
    let httpParams = new HttpParams()
    .append('turno_alta',turno)
    .append('descripcion', descri)
    .append('id_nota', idNota)
    .append('tipo', tipoPendiente)
    .append('id_usuario_alta', idUsuario)
    .append('id_compania_operador', idCompania)
    .append('id_empleado_operador', idOperador)
    .append('id_viaje', idViaje)
    .append('id_flota', idFlota)
    .append('flota', flota)
    .append('id_tractor', idUnidad);
    
    return this.httpApi.post<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/pendientes?${httpParams}`, httpParams)
    .pipe(map(data => data));
  }
  putPendientes(idPendiente:any,turno:string, descri:string, estado:string): Observable<any>{
    let httpParams = new HttpParams()
    .append('idPendiente', idPendiente)
    .append('turno', turno)
    .append('descri', descri)
    .append('estado', estado);
    return this.httpApi.put<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/pendientes?${httpParams}`, httpParams)
    .pipe(map(data => data));
  }

  deletePendiente(id:string): Observable<any>{
    let httpParams = new HttpParams()
    .append('idPendiente', id);
    return this.httpApi.delete<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/pendientes?${httpParams}`)
    .pipe(map(data => data));
  }
}
