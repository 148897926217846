import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS,HttpClient } from '@angular/common/http';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import { AgGridModule } from 'ag-grid-angular';
import { ToastrModule } from 'ngx-toastr';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import {SocketIoConfig, SocketIoModule} from 'ngx-socket-io';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/service/login/login.service';
import {SocketwebService } from './service/socketweb/socketweb.service';
const config: SocketIoConfig = { url: 'https://api.frioexpress.com', options: {} };

import 'ag-grid-enterprise';
import { AccidenteBloqueoComponent } from './components/accidente-bloqueo/accidente-bloqueo.component';
import { AgendaPermisoComponent } from './components/agenda-permiso/agenda-permiso.component';
import { AlertasComponent } from './components/alertas/alertas.component';
import { CajaComponent } from './components/catalogo/caja/caja.component';
import { MensajeComponent } from './components/catalogo/mensaje/mensaje.component';
import { OperadorComponent } from './components/catalogo/operador/operador.component';
import { OrigenDestinoComponent } from './components/catalogo/origen-destino/origen-destino.component';
import { ParadorPensionComponent } from './components/catalogo/parador-pension/parador-pension.component';
import { TractoComponent } from './components/catalogo/tracto/tracto.component';
import { HeaderComponent } from './components/header/header.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { MenuComponent } from './components/menu/menu.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PendienteComponent } from './components/pendiente/pendiente.component';
import { ReporteIncidenciaComponent } from './components/reporte-incidencia/reporte-incidencia.component';
import { ConfigService } from './service/config/config.service';
import { FiltroAccBloqueoPipe } from './filtros/filtro-acc-bloqueo.pipe';
import { ViajesTransComponent } from './components/viajes-trans/viajes-trans.component';
import { FormIncidenciaComponent } from './components/reporte-incidencia/componentes/form-incidencia/form-incidencia.component';
import { PrincipalComponent } from './components/reporte-incidencia/componentes/principal/principal.component';
import { ListaIncidenciasComponent } from './components/reporte-incidencia/componentes/lista-incidencias/lista-incidencias.component';


FullCalendarModule.registerPlugins([ // registro de plugins de FullCalendar
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin,
  bootstrapPlugin
]);

@NgModule({
  declarations: [
    AppComponent,
    AccidenteBloqueoComponent,
    AgendaPermisoComponent,
    AlertasComponent,
    CajaComponent,
    MensajeComponent,
    OperadorComponent,
    OrigenDestinoComponent,
    ParadorPensionComponent,
    TractoComponent,
    HeaderComponent,
    InicioComponent,
    MenuComponent,
    NavbarComponent,
    PendienteComponent,
    ReporteIncidenciaComponent,
    FiltroAccBloqueoPipe,
    ViajesTransComponent,
    FormIncidenciaComponent,
    PrincipalComponent,
    ListaIncidenciasComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    FullCalendarModule,
    AgGridModule.withComponents([]),
    SocketIoModule.forRoot(config),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoginService, multi: true },
    CookieService,
    ConfigService, {provide : APP_INITIALIZER, useFactory : validateUserSession, deps: [ConfigService, HttpClient] , multi : true},
    SocketwebService],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function validateUserSession(config : ConfigService)
{
  return () => config.validateUserSession();
}