import { Component, OnInit, Input } from '@angular/core';
import { PendientesService } from 'src/app/service/pendientes/pendientes.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { OperadoresService } from 'src/app/service/operadores/operadores.service';
import * as moment from 'moment';
import { CanTokenService } from 'src/app/service/guards/can-token.service';

declare var $: any;
declare const jQuery: any;

@Component({
  selector: 'app-pendiente',
  templateUrl: './pendiente.component.html',
  styleUrls: ['./pendiente.component.css']
})
export class PendienteComponent implements OnInit {

  @Input() navbar: boolean = false;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  listaPendiente:any =[];
  turno:string ='';
  no_unidad:string = '';
  estatus:string = '';
  fecha:string='';
  descripcion:string = '';
  id_pendiente:string = ''
  tipo_pendiente: string = '';
  turno_resolvio:string = '';
  no_viaje:string = '';
  no_operador:string = '';
  modalTitle:string = '';
  columnDefs:any = [];
  rowData:any;
  dato:any;
  nombreCol:any;
  gridColumnApi:any;
  defaultColDef:any;
  rowSelection:any;
  style = {
    width: '100%',
    height: '100%',
    flex: '1 1 auto'
  };
  id_usuario:string = '';
  turno_alta:string = '';
  idFlotillas:any = [];
  ageType:any;
  turnoFiltro:any = [];
  no_flota:string = '';
  id_flota:string = '';
  flotas:any = [
    {
      flota: 'FLOTILLA (1)  RAFAEL ROBLES',
      id_flota: '02'
    },
    {
      flota: 'FLOTILLA (2) GERARDO QUEZADA',
      id_flota: '03'
    },
    {
      flota: 'FLOTILLA (3) SALVADOR MARTINEZ',
      id_flota: '04'
    },
    {
      flota: 'FLOTILLA (4) GERARDO GUERRERO',
      id_flota: '10'
    },
    {
      flota: 'FLOTILLA (5) MIGUEL DAVILA',
      id_flota: '11'
    },
    {
      flota: 'FLOTILLA (6) JULIO CESAR LARA',
      id_flota: '16'
    },
    {
      flota: 'FLOTILLA (7) FRANCISCO LOPEZ',
      id_flota: '27'
    },
    {
      flota: 'FLOTILLA (11) ENRIQUE TALAMANT',
      id_flota: '22'
    },
    {
      flota: 'FLOTILLA (12) EDGAR PADILLA',
      id_flota: '23'
    },
    {
      flota: 'FLOTILLA (13) EDGAR DE LUNA',
      id_flota: '25'
    },

  ]
  rowClassRules:any;
  ultima_alerta:string = '';

  constructor(private apiPendiente: PendientesService, private apiOperador: OperadoresService,   private generalService: CanTokenService) {
    window.scroll(0, 0);
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      width: 100,
      filter: true
    };
    this.rowSelection = 'multiple';
    this.rowClassRules = params => {
        var tipo_pendiente = params.data.tipo;
        if (tipo_pendiente == 'SEGUIMIENTO ESPECIAL') {
          return {backgroundColor: '#BADAEB', color: '#000000'}
        }
    }
   }

  ngOnInit() {
    this.getPendiente();
    this.id_usuario = localStorage.getItem('id_usuario')
  }
  getPendiente(){
    this.apiPendiente.getPendientes().subscribe({
      next: pendiente =>{
        this.listaPendiente = pendiente;
        for (let cont = 0; cont < this.listaPendiente.length; cont++) {
          const pendiente = this.listaPendiente[cont];
          pendiente.fecha_alta = moment.utc(pendiente.fecha_alta).format('DD-MM-YYYY');
          if (pendiente.id_empleado_operador == '0' || pendiente.id_empleado_operador == null) {
            pendiente.id_empleado_operador = ""
          }
          if (pendiente.id_viaje == '0' || pendiente.id_viaje == null) {
            pendiente.id_viaje = ""
          }
          if (pendiente.id_tractor == '0' || pendiente.id_tractor == null) {
            pendiente.id_tractor = "";
          }
          if (pendiente.flota == "" || pendiente.flota == null) {
            pendiente.flota = "";
          } if (pendiente.tipo == 'SEGUIMIENTO ESPECIAL' && pendiente.flota == "") {
            pendiente.flota = "ESPECIAL"
          } if (pendiente.tipo == 'ALERTA' && pendiente.estado == 'Pendiente') {
            this.ultima_alerta = '';
            this.ultima_alerta = pendiente.id_tractor;
            pendiente.descripcion = 'ULTIMA ALERTA'+' '+pendiente.id_tractor;
          }
        }
        this.columnDefs = [
          {field: "flota", headerName:"FLOTA", sortable: true, width: 250, floatingFilterComponentParams: { suppressFilterButton: true }},
          {field: "id_tractor", headerName:"UNIDAD", sortable: true, width: 130, floatingFilterComponentParams: { suppressFilterButton: true }},
          {field: "descripcion", headerName:"PENDIENTE", sortable: true, width: 350, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true ,filter: 'agTextColumnFilter'},
          {field: "fecha_alta", headerName: "FECHA", sortable: true, width: 120, floatingFilterComponentParams: { suppressFilterButton: true }},
          {field: "tipo", headerName: "TIPO PENDIENTE", sortable: true, width: 160, floatingFilterComponentParams: { suppressFilterButton: true }},
          {field: "estado", headerName: "STATUS", sortable: true, width: 200, floatingFilterComponentParams: { suppressFilterButton: true }},
          {field: "turno_alta", headerName:"TURNO", sortable: true, width: 120, floatingFilterComponentParams: { suppressFilterButton: true }},
          {field: 'editar', width: 120, filter: false, cellRenderer: function (params) { return '<i class="far fa-edit" style="color:#BF343F;"></i>' } },
          {field: 'eliminar', width: 120, filter: false, cellRenderer: function (params) { return '<i class="fas fa-minus-circle" style="color:#BF343F;"></i>' } }
        ]
        this.rowData = this.listaPendiente;
      },
      error: error => {
        if (localStorage.getItem('token')) {
          if (error.status == 401) this.generalService.refreshToken();
        }
      }
    })
  }

  setWidthAndHeight(width, height) {
    this.style = {
      flex: '20px',
      width: width,
      height: height
    };
  }
  onGridReady(params) {
    this.gridColumnApi = params.columnApi;
    var columnState = JSON.parse(localStorage.getItem('myColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }
  onColumnMoved(e) {
    let columnState = JSON.stringify(e.columnApi.getColumnState());
    localStorage.setItem('myColumnState', columnState);
  }

  onColumnVisible(e) {
    console.log('Event Column Visible', e);
  }
  onCellClicked(e) {
    this.dato = e['data'];
    this.nombreCol = e['colDef']['field'];
    if (this.nombreCol == 'editar') {
      this.openModal(this.dato, 'edit');
      jQuery.noConflict();
      $('#mdl_generar_pen').modal('show');
      $('#mdl_generar_pen').draggable({
        handle: ".modal-header"
      });
    }
    if (this.nombreCol == 'eliminar') {
      this.deletPendiente(this.dato['id_pendiente'])
    }
    if (this.nombreCol == 'flota') {
      this.openModal(this.dato, 'detail');
      jQuery.noConflict();
      $('#mdl_generar_pen').modal('show');
      $('#mdl_generar_pen').draggable({
        handle: ".modal-header"
      });
    }
   
  }
  
  

  addPendiente(){
    if (this.modalTitle == 'Editar Pendiente') {
      let turno_resolvio = this.turno_resolvio;
      let descrip = this.descripcion
      let id_pendiente = this.id_pendiente;
      let estado = this.estatus;
      this.apiPendiente.putPendientes(id_pendiente, turno_resolvio, descrip,estado).subscribe({
        next: update =>{
          if(update == null){
            $(document).ready(function () {
              jQuery.noConflict();
              $('#mdl_generar_pen').modal('hide');
            })
            this.alertPendiente('ACTUALIZACION EXITOSA', '', 'success');
          }
        },
        error: error => {
          if (localStorage.getItem('token')) {
            if (error.status == 401){this.generalService.refreshToken();}
            if (error.status != 401) {
              $(document).ready(function () {
                jQuery.noConflict();
                $('#mdl_generar_pen').modal('hide');
              })
              this.alertPendiente('ERROR', 'Error al actualizar los datos', 'error')
            }
          }
        }
      })
    } else {
      let turno = this.turno;
      let idUsuario =  this.id_usuario;
      let compania_operador = '';
      let id_operador = this.no_operador;
      let idNota = '';
      let idViaje = this.no_viaje
      let tipoPendiente = this.tipo_pendiente;
      let descrip = this.descripcion
      let idFlota = this.id_flota;
      let flota = this.no_flota;
      let noUnidad = this.no_unidad;
      this.apiPendiente.postPendientes(turno,descrip,idNota,tipoPendiente, idUsuario,compania_operador,id_operador,idViaje, idFlota, flota, noUnidad).subscribe({
        next: data =>{
          if(data['id_nuevo_pendiente'] != null){
            $(document).ready(function () {
              jQuery.noConflict();
              $('#mdl_generar_pen').modal('hide');
            })
            this.alertPendiente('DATOS GUARDADOS', 'Se ha creado el pendiente', 'success')
          }
        },
        error: error => {
          if (localStorage.getItem('token')) {
            if (error.status == 401){
              this.generalService.refreshToken();
            }else{
              $(document).ready(function () {
                jQuery.noConflict();
                $('#mdl_generar_pen').modal('hide');
              })
              this.alertPendiente('ERROR', 'Error al generar el pendiente', 'error')
            }
          }
        }
      }) 
    }
  }

  openModal(pendiente, action){
    if (action == 'edit') {
      this.modalTitle = 'Editar Pendiente';
      this.turno = pendiente.turno_alta
      this.id_pendiente = pendiente.id_pendiente;
      this.descripcion = pendiente.descripcion;
      this.estatus = pendiente.estado;
      this.no_flota = pendiente.flota;
      this.id_flota = pendiente.id_flota;
      this.no_unidad = pendiente.id_tractor;
      this.no_viaje = pendiente.id_viaje;
      this.no_operador = pendiente.id_empleado_operador;
      this.tipo_pendiente = pendiente.tipo;
    } else if (action == 'add') {
      this.modalTitle = 'Nuevo Pendiente'
      this.turno = " ";
      this.descripcion = " ";
      this.estatus = 'PENDIENTE';
      this.no_operador = '';
      this.no_viaje = '';
      this.no_flota = '';
      this.no_unidad = ''
    }
    if (action == 'detail') {
      this.modalTitle = 'Detalle del pendiente';
      this.turno = pendiente.turno_alta
      this.id_pendiente = pendiente.id_pendiente;
      this.descripcion = pendiente.descripcion;
      this.estatus = pendiente.estado;
      this.turno_resolvio = pendiente.turno_resolvio;
      this.no_flota = pendiente.flota;
      this.id_flota = pendiente.id_flota;
      this.no_unidad = pendiente.id_tractor;
      this.no_viaje = pendiente.id_viaje;
      this.no_operador = pendiente.id_empleado_operador;
      this.tipo_pendiente = pendiente.tipo
    }
  }

  deletPendiente(id: any) {
    Swal.fire({
      title: '¿Desea eliminar el pendiente?',
      showCancelButton: true,
      confirmButtonText: `SI`,
      confirmButtonColor: '#004680',
      cancelButtonText: 'CANCELAR',
      cancelButtonColor: ' #BF343F'
    }).then((result) => {
      if (result.isConfirmed) {
        this.apiPendiente.deletePendiente(id).subscribe(borrar => {
          if(borrar == null){
            this.alertPendiente('BAJA EXITOSA', '', 'success')
          }else{
            this.alertPendiente('ERROR', 'Error al actualizar los datos', 'error')
          }
        })
      }
    })
  }

  alertPendiente(title, text, icon) {
    Swal.fire({
      title: title,
      icon: icon,
      text: text,
      customClass: {
        confirmButton: 'btn btn-success',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.getPendiente();
      }
    });
  }

  filtroflotaPendiente(){
    let  flota = {};
    this.idFlotillas = []
    $(document).ready(function () {
      $('.dropdown-menu').on('click', function (e) {
        e.stopPropagation();
      });
    });
    if ($('#dp_flota1_ref').prop('checked') == true) {
      flota = flota === {} ?{id_flotilla: '02'} : this.idFlotillas.push({id_flotilla: '02'});
    }
    if ($('#dp_flota2_ref').prop('checked') == true) {
      flota = flota === {} ? {id_flotilla: '03'} : this.idFlotillas.push({id_flotilla: '03'});
    }
    if ($('#dp_flota3_ref').prop('checked') == true) {
      flota = flota === {} ? {id_flotilla: '04'} :  this.idFlotillas.push({id_flotilla: '04'});
    }
    if ($('#dp_flota4_ref').prop('checked') == true) {
      flota = flota === {} ? {id_flotilla: '10' } :   this.idFlotillas.push({id_flotilla: '10'});
    }
    if ($('#dp_flota5_ref').prop('checked') == true) {
      flota = flota === {} ? {id_flotilla: '11' } :  this.idFlotillas.push({id_flotilla: '11'});
    }
    if ($('#dp_flota6_ref').prop('checked') == true) {
      flota = flota === {} ? {id_flotilla: '16'} :  this.idFlotillas.push({id_flotilla: '16'});
    }
    if ($('#dp_flota7_ref').prop('checked') == true) {
      flota = flota === {} ? {id_flotilla: '27'} :  this.idFlotillas.push({id_flotilla: '27'});

    }
    if ($('#dp_flota1_sec').prop('checked') == true) {
      flota = flota === {} ? {id_flotilla: '22'} :  this.idFlotillas.push({id_flotilla: '22'});

    }
    if ($('#dp_flota2_sec').prop('checked') == true) {
      flota = flota === {} ? {id_flotilla: '23'} :  this.idFlotillas.push({id_flotilla: '23'});

    }
    if ($('#dp_flota3_sec').prop('checked') == true) {
      flota = flota === {} ? {id_flotilla:' 25'}:  this.idFlotillas.push({id_flotilla: ' 25'});

    }
    this.ageType = this.idFlotillas
    this.filtroPendienteFlotas(this.idFlotillas);
  }

  filtroPendienteFlotas(dato){
    let pendienteFlota = [];
    let idFlota = dato;
    let cont = 0;
    let idFlotillas = '';
    let totalFlotas =  idFlota.length;
    for (cont; cont < totalFlotas; cont++) {
      let flota = idFlota[cont];
      idFlotillas = flota.id_flotilla;
      this.rowData = [];
      this.listaPendiente.forEach(datosPendientes => {
        if (datosPendientes.id_flota == idFlotillas) {
          pendienteFlota.push(datosPendientes)
        }
      });          
    }
    if (idFlota.length == 0) {
      this.rowData = this.listaPendiente;
    }else{
      this.rowData = pendienteFlota;
    }
  }

  filtroTurno(){
    let turno = {}
    this.turnoFiltro = [];
    $(document).ready(function () {
      $('.dropdown-menu').on('click', function (e) {
        e.stopPropagation();
      });
    });
    if ($('#dp_turno_matu').prop('checked') == true) {
      turno =  turno === {} ? {turno: 'MATUTINO'} : this.turnoFiltro.push({turno: 'MATUTINO'})       
    }
    if($('#dp_turno_ves').prop('checked') == true) {
      turno = turno === {} ? {turno: 'VESPERTINO'} : this.turnoFiltro.push({turno:'VESPERTINO'})
    }
    if($('#dp_turno_noc').prop('checked') == true){
      turno =  turno === {} ? {turno: 'NOCTURNO'} : this.turnoFiltro.push({turno:'NOCTURNO'})
    }
    this.ageType =  this.turnoFiltro;
    this.filtroTurnoPendiente(this.turnoFiltro);
  }
  filtroTurnoPendiente(datos){
    let pendienteTurno = [];
    let turno = datos;
    let cont = 0;
    let turnoPen = '';
    let totalTurnos = turno.length;
    for (cont; cont < totalTurnos; cont++) {
      let turnos = turno[cont];
      turnoPen = turnos.turno;
      this.rowData = [];
      this.listaPendiente.forEach(datoPendientes => {
        if (datoPendientes.turno_alta == turnoPen) {
          pendienteTurno.push(datoPendientes)
        }
      });      
    }
    if (turno.length == 0) {
      this.rowData = this.listaPendiente;
    }else{
      this.rowData = pendienteTurno;
    }
  }
  obtenerIdFlota(nombre){
    const result = this.flotas.find(flota => flota.flota === nombre);
    if (result != null) {
      this.no_flota = result.flota;
      this.id_flota = result.id_flota;
    }
  }
  onFilterChanged(params) {
    const filterModel = params.api.getFilterModel();
    localStorage.setItem('filterModel', JSON.stringify(filterModel));
  }
  onFirstDataRendered(params) {
    const filterModel = JSON.parse(localStorage.getItem('filterModel'));
    if (filterModel) {
      params.api.setFilterModel(filterModel);
    }
  }
}
