import { Component, OnInit, Input, ViewChild} from '@angular/core';
import { FullCalendarComponent,CalendarOptions, EventApi } from '@fullcalendar/angular';
import { PermisosService } from 'src/app/service/permisos/permisos.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';

declare var $: any;
declare const jQuery: any; 

@Component({
  selector: 'app-agenda-permiso',
  templateUrl: './agenda-permiso.component.html',
  styleUrls: ['./agenda-permiso.component.css']
})
export class AgendaPermisoComponent implements OnInit {

  @Input() navbar: boolean = false;
   
  @ViewChild('calendar', { static: false }) calendarComponent: FullCalendarComponent;
  constructor(private apiPermiso: PermisosService, private spinnerService: NgxSpinnerService) { }
  listaPermiso:any;
  permisosList:any = [];
  calendarPerm: any = [];
  fecha_act: any;
  fechaActiva: any;
  calendarVisible = true;
  calendarOptions: CalendarOptions;
  currentEvents: EventApi[] = [];
  columnDefs:any = [];
  rowData:any;
  dato:any;
  nombreCol:any;
  gridColumnApi:any;
  defaultColDef:any;
  rowSelection:any;
  style = {
    width: '100%',
    height: '100%',
    flex: '1 1 auto'
  };

  ngOnInit(): void{
    this.getPermisos();
  }

  handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible;
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }
  handleDateClick(arg) {
    this.permisosData(arg.dateStr);
  }

  setWidthAndHeight(width, height) {
    this.style = {
      flex: '20px',
      width: width,
      height: height
    };
  }
  onGridReady(params) {
    this.gridColumnApi = params.columnApi;
    var columnState = JSON.parse(localStorage.getItem('myColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }

  getPermisos(){
    this.spinnerService.show()
    this.fecha_act = moment().format('MM-DD-YYYY');
    this.apiPermiso.getPermisos(this.fecha_act).subscribe(permiso=>{
      this.listaPermiso = permiso;
      this.fecha_act = moment().format('MM-DD-YYYY');
      this.columnDefs = [
        {field: "no_oper", headerName:"OPERADOR", sortable: true, width: 280, floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: "no_tracto", headerName:"TRACTOR", sortable: true, width: 350, floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: "nombre", headerName:"NOMBRE", sortable: true, width: 350, floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: "ape_p" + "ape_m", headerName: "APELLIDO", sortable: true, width: 250, floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: "fecha_ini", headerName:"DESDE", sortable: true, width: 350, floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: "fecha_fin", headerName: "HASTA", sortable: true, width: 250, floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: "no_dias", headerName:"DIAS", sortable: true, width: 350, floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: "motivo", headerName: "MOTIVO", sortable: true, width: 250, floatingFilterComponentParams: { suppressFilterButton: true }},
      ]
      this.rowData = this.listaPermiso;
      this.spinnerService.hide()
      this.cargarFullCalendar();
    })
  }
  permisosData(fecha:any){
    this.permisosList = [];
    let fechaPermiso = moment(fecha, 'YYYY-MM-DD').format('MM-DD-YYYY');
    this.spinnerService.show();
    this.apiPermiso.getPermisos(fechaPermiso).subscribe(permiso=>{
      this.permisosList = permiso
      this.rowData = this.permisosList;
      this.spinnerService.hide()
    })
  }
  cargarFullCalendar(){
    this.calendarPerm = this.listaPermiso.map(permiso =>{
      this.calendarPerm = permiso
         this.calendarPerm= {
           'title': this.calendarPerm.nombre,
           'start': this.calendarPerm.fecha_ini,
           'end' : this.calendarPerm.fecha_fin
         }
         return this.calendarPerm;
     });
    this.calendarOptions = {
      contentHeight: 600,
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek'
        },
        themeSystem: 'bootstrap',
        initialView: 'dayGridMonth',
        weekends: true,
        editable: true,
        selectable: true,
        dateClick: this.handleDateClick.bind(this),
        selectMirror: true,
        dayMaxEvents: true,
        eventSources: [
          {
            events:this.calendarPerm,
            color: '#BF364F',     // an option!
            textColor: '#FFF' // an option!
          }],
        eventsSet: this.handleEvents.bind(this)
    };
  }
}
