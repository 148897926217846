import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ViajeService } from 'src/app/service/viaje/viaje.service';
import Swal from 'sweetalert2';
import { GridOptions, IDatasource, IGetRowsParams } from 'ag-grid-community';
import { CanTokenService } from 'src/app/service/guards/can-token.service';

@Component({
  selector: 'app-viajes-trans',
  templateUrl: './viajes-trans.component.html',
  styleUrls: ['./viajes-trans.component.css']
})
export class ViajesTransComponent implements OnInit {

  columnDefs= [];
  gridParams;
  gridColumnApi;
  gridApi;
  gridOptions;
  bloqueInformacion = 1;
  rowData: any = [];
  defaultColDef;
  rowSelection;

  constructor(
    private apiViaje: ViajeService,
    private spinnerService: NgxSpinnerService,
    private generalService: CanTokenService,
  ) { 
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
    };
    this.rowSelection = 'multiple';
    this.gridOptions = {
      cacheBlockSize: 100,
      paginationPageSize: 100,
      rowModelType: 'serverSide',
      serverSideStoreType: 'partial'
    }
  }

  ngOnInit(): void {
    this.columnDefs=[
      { field: 'maniobra', headerName: 'VIAJE', sortable: true, filter: 'text', floatingFilterComponentParams: { suppressFilterButton: true }},
      { field: 'fecha', headerName: 'FECHA', sortable: true, filter: 'text', floatingFilterComponentParams: { suppressFilterButton: true }},
      { field: 'caja', headerName: 'CAJA', filter: 'text', floatingFilterComponentParams: { suppressFilterButton: true }},
      { field: 'id_transfer', headerName: 'TRANSFER', sortable: true, filter: 'text', floatingFilterComponentParams: { suppressFilterButton: true }},
      { field: 'cve_ope', headerName: 'OPERADOR', sortable: true, filter: 'text', floatingFilterComponentParams: { suppressFilterButton: true }},
      { field: 'origen', headerName: 'ORIGEN', sortable: true, filter: 'text', floatingFilterComponentParams: { suppressFilterButton: true }},
      { field: 'destino', headerName: 'DESTINO', sortable: true, filter: 'text', floatingFilterComponentParams: { suppressFilterButton: true }},
      { field: 'arranque', headerName: 'ARRANQUE', sortable: true, filter: 'text', floatingFilterComponentParams: { suppressFilterButton: true }},
      { field: 'termino', headerName: 'TERMINO', sortable: true, filter: 'text', floatingFilterComponentParams: { suppressFilterButton: true }},
      ]
      this.obtnerViajesTrans()
  }

  onGridReady(params){
    this.gridParams = params;
    this.gridColumnApi = params.columnApi;
    this.gridApi = params.api;
    var columnState = JSON.parse(localStorage.getItem('myColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
}

  obtnerViajesTrans(){
    this.spinnerService.show();
    this.apiViaje.obtenerViajesTransfrontera().subscribe({
      next: viajes => {
        this.gridOptions.paginationPageSize = 100;
        this.gridOptions.cacheBlockSize = 100,
        this.rowData = viajes;
        this.spinnerService.hide();
      },
      error: error => {
        this.spinnerService.hide();
        if (localStorage.getItem('token')) {
          this.spinnerService.hide();
          if (error.status == 401) {
            this.modalError('La sesion a caducado', '', '401');
          } else {
            Swal.fire({
              title: 'Error al obtener los viajes',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            });
          }
        } else {
          this.modalError('La sesion a caducado', '', '401');
        }
      }
    })
  }
  modalError(titulo, mensaje, codigoError) {
    this.spinnerService.hide();
    //Configuracion del diseño del modal
    var alerta = Swal.mixin({
      imageUrl: '/assets/general/img/icon/error.svg',
      imageWidth: 85,
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false,
    });
    alerta.fire({
        title: titulo, //Titulo del modal
        text: mensaje, //Mensaje del modal
        allowOutsideClick: false, //Desactiva el backdrop, para no poder ocultar el modal hasta dar en el boton 'OK'
      }).then((resultado) => {
        //Si el error fue 401(No Autorizado) por vencimiento de token, cierra la sesion
        if (resultado.value && codigoError === '401') {
          this.salir(); //Cerrar la sesion del usuario
        }
      });
  }
  onPageChange(event){
    if(event.newPage == true){
      this.bloqueInformacion = this.bloqueInformacion + 1;
    }
  }

  onPageSizeChanged(event: any) {
    this.gridApi.rowModel.cacheParams.blockSize = event.target.value;
    this.gridApi.gridOptionsWrapper.setProperty('cacheBlockSize', event.target.value);
    this.gridApi.paginationSetPageSize(event.target.value);
  }
  salir() {
    this.generalService.refreshToken();
  }
}
