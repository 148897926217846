import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/service/login/login.service';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';

export interface ParadPen{
  id_parador_pension;
  nombre;
  zona;
  activo;
  fec_alta;
  id_usuario_alta;
  latitud;
  longitud;
  tipo;
}

@Injectable({
  providedIn: 'root'
})
export class ParadorPensionService {
  private headers: HttpHeaders;

  constructor(private httpApi: HttpClient, private apiAuth: LoginService) { }

  getParadores(): Observable<any>{
    return this.httpApi.get<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/paradores`)
    .pipe(map(data => <ParadPen[]>data))
  }

  postParadores(nombre,  zona, latitud, longitud, tipoParaPen, usuario):Observable<any>{;
    let httpParams = new HttpParams()
    .append( 'nombre', nombre)
    .append('zona',zona)
    .append('latitud',latitud)
    .append('longitud',longitud)
    .append('tipo',tipoParaPen)
    .append('id_usuario_alta', usuario);
    return this.httpApi.post<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/paradores?${httpParams}`, httpParams)
    .pipe(map(data=>data));
  }

  putParadores(idParadPen,nombre, zona, latitud, longitud):Observable<any>{
    let httpParams = new HttpParams()
    .append('idParadPen', idParadPen)
    .append('nombre', nombre)
    .append('zona', zona)
    .append('longitud', longitud)
    .append('latitud', latitud);
    return this.httpApi.put<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/paradores?${httpParams}`, httpParams)
  }

  deleteParadores(id:string):Observable<any>{
    let httpParams = new HttpParams()
    .append('idParadPen',id);
    return this.httpApi.delete<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/paradores?${httpParams}`);
  }
}
