import { Component, OnInit, Input } from '@angular/core';
import { Workbook } from 'exceljs';
import { SancionService } from 'src/app/service/sancion/sancion.service';
import * as moment from 'moment';
declare var $: any;
declare const jQuery: any;

@Component({
  selector: 'app-reporte-incidencia',
  templateUrl: './reporte-incidencia.component.html',
  styleUrls: ['./reporte-incidencia.component.css']
})
export class ReporteIncidenciaComponent implements OnInit {

  @Input() navbar: boolean = false;
  contador: number;
  
  listaReporte:any =[];
  reporteSanciones:any = [];
  listaSancion:any = [];
  sancion:string = '';
  columnDefs:any = [];
  rowData:any;
  dato:any;
  nombreCol:any;
  gridColumnApi:any;
  defaultColDef:any;
  rowSelection:any;
  style = {
    width: '100%',
    height: '100%',
    flex: '1 1 auto'
  };
  operador:string = '';
  viaje:string = '';
  incidencia: string = '';
  reporte:string = '';
  reporto:string = '';
  flota:string = '';
  unidad:string = ''
  fecha:string = ''
  constructor(private apiSancion: SancionService) {
    window.scroll(0, 0);
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      width: 150,
      filter: true
    };
    this.rowSelection = 'multiple';
   }

  ngOnInit() { 
    this.getCatalogoSancion()
    this.getReporteSancion();
   
  }

  getReporteSancion(){
    this.apiSancion.getReporteSancion().subscribe(sanciones =>{
      this.listaReporte = sanciones
      this.listaReporte.forEach(datos => {
        const result = this.listaSancion.find(sancion => sancion.id_sancion === datos.id_sancion);
        if(result != null){
           datos.id_sancion = result.nombre
        }
        datos.fecha_alta = moment(datos.fecha_alta, 'YYYY-MM-DD').format('DD-MM-YYYY')
      });
      this.rowData = this.listaReporte
      this.columnDefs=[
        {field: "id_viaje", headerName:"VIAJE", sortable: true, width: 100, floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: "id_tractor", headerName:"UNIDAD", sortable: true, width: 130, floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: "operador", headerName: "OPERADOR", sortable: true, width: 275, floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: "flota", headerName:"FLOTA", sortable: true, width: 350, floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: "fecha_alta", headerName: "FECHA-HORA", sortable: true, width: 150, floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: "id_sancion", headerName:"TIPO INCIDENCIA", sortable: true, width: 250, floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: "reporte", headerName: "REPORTE", sortable: true, width: 275, floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: "id_usuario_alta", headerName:"REPORTÓ", sortable: true, width: 150, floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: "sancion",headerName:"SANCION",  sortable: true, width: 110, floatingFilterComponentParams: { suppressFilterButton: true },  cellRenderer: function (params) { return 'SI' }},
      ]
    })
  }

  getCatalogoSancion(){
    this.apiSancion.getSanciones().subscribe(sancion =>{
      this.listaSancion = sancion;
    })
  }

  onGridReady(params) {
    this.gridColumnApi = params.columnApi;
    var columnState = JSON.parse(localStorage.getItem('myColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }
  onColumnMoved(e) {
    let columnState = JSON.stringify(e.columnApi.getColumnState());
    localStorage.setItem('myColumnState', columnState);
  }

  onColumnVisible(e) {
    console.log('Event Column Visible', e);
  }

  generarReporte(){
    this.listaReporte.forEach(sancion => {
      let sanciones = {
        'viaje': sancion.id_viaje,
        'unidad': sancion.id_tractor,
        'oper' : sancion.operador,
        'flota': sancion.flota,
        'fecha': sancion.fec_alta,
        'descripcion': sancion.reporte,
        'reporto': sancion.id_usuario_alta,
        'sancion': 'SI'
      }
      this.reporteSanciones.push(Object.values(sanciones));
    });
    this.reporteInci(this.reporteSanciones)
  }

  reporteInci(data:any){
    let book = new Workbook();
    let doc_title = ["REPORTE INCIDENCIAS"];
    let sheet = book.addWorksheet('Hoja 1');
    sheet.addRow(doc_title);
    sheet.mergeCells('A1:H1');
    sheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
    sheet.getCell('A1').font = { bold: true, size: 14 }
    sheet.addRow([]);
    /**Tabla de inicidencias */
    let tab_header = ["VIAJE", "UNIDAD", "OPER", "FLOTA", "FECHA", "DESCRIPCION", "REPORTO", "SANCION"];
    let header_style = sheet.addRow(tab_header);
    header_style.height = 30;
    sheet.getColumn(1).width = 15;
    sheet.getColumn(1).alignment = { horizontal: 'center' }
    sheet.getColumn(2).width = 10;
    sheet.getColumn(2).alignment = { horizontal: 'center' }
    sheet.getColumn(3).width = 35;
    sheet.getColumn(3).alignment = { horizontal: 'center' }
    sheet.getColumn(4).width = 35;
    sheet.getColumn(4).alignment = { horizontal: 'center' }
    sheet.getColumn(5).width = 15;
    sheet.getColumn(5).alignment = { horizontal: 'center' }
    sheet.getColumn(6).width = 45;
    sheet.getColumn(6).alignment = { horizontal: 'center' }
    sheet.getColumn(7).width = 45;
    sheet.getColumn(7).alignment = { horizontal: 'center' }
    sheet.getColumn(8).width = 10;
    sheet.getColumn(8).alignment = { horizontal: 'center' }

    header_style.eachCell((cell) => {
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '014781' }, bgColor: { argb: 'ffff' } }
      cell.font = { bold: true, color: { argb: 'F2F2F2' } }
      cell.border = { left: { style: 'thin' }, top: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' } };
      cell.alignment = { vertical: 'middle', horizontal: 'center' }
    });
    data.forEach(incidencia => {
      let incidencia_style = sheet.addRow(incidencia);
      this.contador = this.contador + 1;
      incidencia_style.eachCell((cell, number) => {
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      });
    });
    book.xlsx.writeBuffer().then((data) => {
      const blob = new Blob(
        [data],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
      );
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = "REPORTE_INCIDENCIAS";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  }
  formatDate(date: any) {
    let dd = date.getDate();
    let mm = date.getMonth() + 1; //January is 0!
    let yyyy = date.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }

    date = dd + '/' + mm + '/' + yyyy;

    return date;
  }
  onRowClicked(e) {
    this.operador = e.data['operador']
    this.flota = e.data['flota']
    this.viaje = e.data['id_viaje']
    this.incidencia = e.data['id_sancion']
    this.reporto = e.data['id_usuario_alta']
    this.reporte = e.data['reporte']
    this.unidad = e.data['id_tractor']
    this.fecha = e.data['fecha_alta']
    $(document).ready(function () {
      jQuery.noConflict();
      $('#mdl_info_sancion').modal('show');
      $('#mdl_info_sancion').draggable({
        handle: ".modal-header"
      });
    })
  }

}
