<main role="main" class="col-12" [ngClass]="{'pl-menu': !navbar}">
    <h5 class="mt-2">CATALOGO-PARADORES Y PENSIONES AUTORIZADAS</h5>
    <div class="row">
        <!--TOOLBAR-->
        <div class="form-inline col-12">
            <div class="dropdown">
                <button class="btn frio-btn-toolbar dropdown-toggle btn-sm mr-2" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    TIPO
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_pension" (change)="filtroTipoParaPen()">
                            <label class="custom-control-label" for="dp_pension">PENSION</label>
                        </div>
                    </a>
                    <a class="dropdown-item frio-btn-toolbar">
                        <div class="custom-control custom-checkbox float-left">
                            <input type="checkbox" class="custom-control-input" id="dp_parador" (change)="filtroTipoParaPen()">
                            <label class="custom-control-label" for="dp_parador">PARADOR</label>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <!--/TOOLBAR-->
    </div>
    <div class="col-12 mt-5">
        <div class="row">
            <div class="input-group input-group-sm">
                <button type="submit" class="btn btn-primary" data-toggle="modal" data-target="#mdl_agregar"  (click)="openModal('','add')">AGREGAR NUEVO PARADOR O PENSION</button>
            </div>
            <div class="input-group input-group-sm col-10"></div>
        </div>
        <br>
        <div [ngStyle]="style">
            <ag-grid-angular #agGrid_para_pen class="ag-theme-alpine"
                [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowData]="rowData"
                (cellClicked)="onCellClicked($event)" [sideBar]="false"
                (columnMoved)="onColumnMoved($event)" (gridReady)="onGridReady($event)"
                >
            </ag-grid-angular>
        </div>
    </div>
</main>
<!--Modal Detalles del Parador o Pension-->
<div class="modal fade" id="mdl_parad_pen" tabindex="-1" role="dialog" aria-labelledby="mdl_parad_pen" aria-hidden="true" data-backdrop="static" style="z-index: 10000;" >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header bg-primary d-block">
                <button type="button" class="close color-white float-right" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title color-white text-center" id="mdl_parad_pen">PARADAS Y PENSIONES</h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <fieldset disabled>
                    <div class="form-group row form-inline justify-content-center">
                        <label>TIPO: </label>
                        <div class="input-group input-group-md col-6">
                            <input id="txt_tipo_para_pen" type="text" class="form-control text-uppercase"  aria-label="Username" aria-describedby="basic-addon1" autocomplete="off" value="{{this.datosPara_Pen.tipo}}">
                       </div>                      
                    </div>
                    <div class="form-group row form-inline justify-content-center" *ngIf="this.datosPara_Pen.tipo == 'PARADOR'">
                        <label>ZONA:</label>
                        <div class="input-group input-group-md col-6">
                             <input id="txt_zona" type="text" class="form-control text-uppercase"  aria-label="Username" aria-describedby="basic-addon1" autocomplete="off" value="{{this.datosPara_Pen.zona}}">
                        </div>
                    </div>
                    <div class="form-group row form-inline justify-content-center" >
                        <label>NOMBRE:</label>
                        <div class="input-group input-group-md col-6">
                             <input id="txt_nombre_det" type="text" class="form-control text-uppercase"  aria-label="Username" aria-describedby="basic-addon1" autocomplete="off" value="{{this.datosPara_Pen.nombre}}">
                        </div>
                    </div>
                    <div class="form-group row form-inline justify-content-center">
                        <label>LATITUD:</label>
                        <div class="input-group input-group-md col-6">
                             <input id="txt_lat_det" type="text" class="form-control text-uppercase"  aria-label="Username" aria-describedby="basic-addon1" autocomplete="off" value="{{this.datosPara_Pen.latitud}}">
                        </div>
                    </div>
                    <div class="form-group row form-inline justify-content-center">
                        <label>LONGITUD:</label>
                        <div class="input-group input-group-md col-6">
                             <input id="txt_long_det" type="text" class="form-control text-uppercase"  aria-label="Username" aria-describedby="basic-addon1" autocomplete="off" value="{{this.datosPara_Pen.longitud}}">
                        </div>
                    </div>
                </fieldset>
                    <div class="form-group row justify-content-center">
                        <div class="form-inline">
                            <div class="input-group input-group-sm col-sm">
                                <a href="{{this.url}}" target="_blank"><button type="button" class="btn btn-light"  (click)="posicion()"><i class="fas fa-map-marker-alt" style="color:#BF343F;"></i> MAPA</button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Modal Agregar Parador o Pension-->
<div class="modal fade" id="mdl_agregar" tabindex="-1" role="dialog" aria-labelledby="mdl_agregar" aria-hidden="true" data-backdrop="static" style="z-index: 10000;" >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header bg-primary d-block">
                <button type="button" class="close color-white float-right" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title color-white text-center" id="mdl_agregar">{{this.modalTitle}}</h5>
            </div>
            <div class="modal-body container-fluid">
                <div>
                    <div class="form-group row form-inline justify-content-center">
                        <label>TIPO:</label>
                        <div class="input-group input-group-md col-6">
                        <select class="form-control text-uppercase" name="tipoParaPen" [(ngModel)]="tipoParaPen">
                            <option value="PARADOR">PARADOR</option>
                            <option value="PENSION">PENSION</option>
                        </select>             
                       </div>      
                    </div>
                    <div class="form-group row form-inline justify-content-center" *ngIf="this.tipoParaPen != 'PENSION'">
                        <label>ZONA:</label>
                        <div class="input-group input-group-md col-6">
                             <input id="txt_zona" type="text" class="form-control text-uppercase"  aria-label="Username" aria-describedby="basic-addon1" autocomplete="off" name="zona" [(ngModel)]="zona" >
                        </div>
                    </div>
                    <div class="form-group row form-inline justify-content-center" >
                        <label>NOMBRE:</label>
                        <div class="input-group input-group-md col-6">
                             <input id="txt_nombre" type="text" class="form-control text-uppercase"  aria-label="Username" aria-describedby="basic-addon1" autocomplete="off" name="nombre" [(ngModel)]="nombre">
                        </div>
                    </div>
                    <div class="form-group row form-inline justify-content-center">
                        <label>LATITUD:</label>
                        <div class="input-group input-group-md col-6">
                             <input id="txt_lat" type="text" class="form-control text-uppercase"  aria-label="Username" aria-describedby="basic-addon1" autocomplete="off" name="latitud" [(ngModel)]="latitud">
                        </div>
                    </div>
                    <div class="form-group row form-inline justify-content-center">
                        <label>LONGITUD:</label>
                        <div class="input-group input-group-md col-6">
                             <input id="txt_long" type="text" class="form-control text-uppercase"  aria-label="Username" aria-describedby="basic-addon1" autocomplete="off" name="longitud" [(ngModel)]="longitud">
                        </div>
                    </div>
                    <div class="form-group row justify-content-center">
                        <div class="form-inline">
                            <div class="input-group input-group-sm col-sm">
                                <button type="submit" class="btn btn-primary" (click)="saveParaPen()">GUARDAR</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
