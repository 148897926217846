import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment-timezone';
import { SancionService } from 'src/app/service/sancion/sancion.service';
import { AlertaService } from 'src/app/service/alertas/alertas.service';
import { CanTokenService } from 'src/app/service/guards/can-token.service';
import Swal from 'sweetalert2';
import { Workbook } from 'exceljs';
import { NgForm } from '@angular/forms';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-alertas',
  templateUrl: './alertas.component.html',
  styleUrls: ['./alertas.component.css']
})
export class AlertasComponent implements OnInit {

  @Input() navbar: boolean = false;
  listaAlerta: any = [];
  fecha: any;
  hora: string = '';
  columnDefs: any = [];
  rowData: any;
  gridColumnApi: any;
  gridApi:any;
  rowSelection: any;
  defaultColDef: any;
  datosAlerta: any = [];
  id_alerta: string = '';
  tipo_alerta: string = '';
  flota_alerta: string = '';
  operador_alerta: string = '';
  ubicacion: string = '';
  unidad_alerta: string = '';
  comentario: string = '';
  aplica_sancion: string = '';
  envio_reporte: string = 'N';
  lista_sanciones: any = [];
  alertas: any = [];
  id_viaje: any = '';
  url: any = ''
  id_sancion: string = '';
  nombre_sancion:string ='';
  descripcion_sancion:string ='';
  idFlotillas:any = [];
  ageType:any;
  motivo_sancion:string;
  correoSancion: any = '';
  sancion: any = [
    {
      aplica: 'SI',
      valor: 'S'
    },
    {
      aplica: 'NO',
      valor: 'N'
    }
  ];
  correosIncidencias: any = [];
  correoDestino: any = [];
  id_usuario:string='';
  datos_alertas:any = [];
  styleAlert:any;
  label_alert: any;
  correoFlota:any = [];
  correoNotificacion:string = '';
  correoDestinoFlota:any = [];
  fechaInicial:string = '';
  fechaFin:string = '';
  alertaAtendidas:any = [];
  contador: number;
  constructor(
    private apiAlerta: AlertaService,
    private spinnerService: NgxSpinnerService,
    private apiSancion: SancionService,
    private generalService: CanTokenService
    ) {
    window.scroll(0, 0);
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      width: 150,
      filter: true,
      floatingFilter: true,
    };
    this.rowSelection = 'multiple';
  }
  ngOnInit() {
    this.id_usuario = localStorage.getItem('id_usuario');
    this.getAlerta();
    this.obtenerCatalogoSanciones();
    this.obtenerCorreos();
  }

  getAlerta() {
    this.spinnerService.show();
    this.apiAlerta.getAlert().subscribe({
      next: alerta => {
        this.listaAlerta = alerta;
        this.listaAlerta.forEach(alerta => {
          const result = this.listaAlerta.find(alertaDatos => alertaDatos.id_alerta_viaje === alerta.id_alerta_viaje);
          if (result != null) {
            alerta.fec_alta = moment.utc(result.fec_alta).format('DD-MM-YYYY HH:mm');
          }
          const nombre_incidencia = this.lista_sanciones.find(sancion => sancion.id_sancion === alerta.id_sancion)
          if (nombre_incidencia != null) {
            alerta.id_sancion = nombre_incidencia.nombre
          }
        });
        this.rowData = this.listaAlerta;
        this.columnDefs = [
          { field: 'flota', headerName: 'FLOTA', sortable: true, filter: 'agTextColumnFilter'  , floatingFilterComponentParams: { suppressFilterButton: true }, width: 300 },
          { field: 'id_viaje', headerName: 'VIAJE', sortable: true, filter: 'agTextColumnFilter'   ,floatingFilterComponentParams: { suppressFilterButton: true }, width: 100 },
          { field: 'id_tractor', headerName: 'UNIDAD', sortable: true, filter: 'agTextColumnFilter'   ,floatingFilterComponentParams: { suppressFilterButton: true }, width: 150 },
          { field: 'fec_alta', headerName: 'FECHA-HORA', sortable: true,  filter: 'agTextColumnFilter'  ,floatingFilterComponentParams: { suppressFilterButton: true }, width: 200 },
          { field: 'nombre_alerta', headerName: 'ALERTA', sortable: true, filter: 'agTextColumnFilter'   ,floatingFilterComponentParams: { suppressFilterButton: true }, width: 300, cellStyle: this.prioridadAlerta },
          { field: 'estado_alerta', headerName: 'PRIORIDAD', sortable: true, floatingFilterComponentParams: { suppressFilterButton: true }, width: 120 },
          { field: 'id_empleado', headerName: 'OPERADOR', sortable: true, floatingFilterComponentParams: { suppressFilterButton: true }, width: 100 },
          { field: 'id_usuario_alta', headerName: 'ATENDIO', sortable: true, floatingFilterComponentParams: { suppressFilterButton: true }, width: 115 },
          { field: 'id_sancion', headerName: 'INCIDENCIA', sortable: true, floatingFilterComponentParams: { suppressFilterButton: true }, width: 250 },
          { field: 'comentario', headerName: 'COMENTARIO', sortable: true, floatingFilterComponentParams: { suppressFilterButton: true }, width: 250 },
          { field: 'incidencia_operador', headerName: 'INCIDENCIA OPERADOR', sortable: true, floatingFilterComponentParams: { suppressFilterButton: true } },
          { field: 'ind_estado', headerName: 'STATUS', sortable: true, floatingFilterComponentParams: { suppressFilterButton: true }, width: 100 },
        ];
        this.spinnerService.hide();
      },
      error: error => {
        if (localStorage.getItem('token')) {
          if (error.status == 401){
            this.generalService.refreshToken(); 
          } else
          if (error.status != 401) {
            this.modalError('Error al obtener las alertas', 'Por favor comuniquese con el area de sistemas', error.status)
          }
        }
      }
    })
  }

  filtroflotaAlerta(){
    let  flota = {};
    this.idFlotillas = []
    $(document).ready(function () {
      $('.dropdown-menu').on('click', function (e) {
        e.stopPropagation();
      });
    });
    if ($('#dp_flota1_ref').prop('checked') == true) {
      flota = flota === {} ?{id_flotilla: '02'} : this.idFlotillas.push({id_flotilla: '02'});
    }
    if ($('#dp_flota2_ref').prop('checked') == true) {
      flota = flota === {} ? {id_flotilla: '03'} : this.idFlotillas.push({id_flotilla: '03'});
    }
    if ($('#dp_flota3_ref').prop('checked') == true) {
      flota = flota === {} ? {id_flotilla: '04'} :  this.idFlotillas.push({id_flotilla: '04'});
    }
    if ($('#dp_flota4_ref').prop('checked') == true) {
      flota = flota === {} ? {id_flotilla: '10' } :   this.idFlotillas.push({id_flotilla: '10'});
    }
    if ($('#dp_flota5_ref').prop('checked') == true) {
      flota = flota === {} ? {id_flotilla: '11' } :  this.idFlotillas.push({id_flotilla: '11'});
    }
    if ($('#dp_flota6_ref').prop('checked') == true) {
      flota = flota === {} ? {id_flotilla: '16'} :  this.idFlotillas.push({id_flotilla: '16'});
    }
    if ($('#dp_flota7_ref').prop('checked') == true) {
      flota = flota === {} ? {id_flotilla: '27'} :  this.idFlotillas.push({id_flotilla: '27'});

    }
    if ($('#dp_flota1_sec').prop('checked') == true) {
      flota = flota === {} ? {id_flotilla: '22'} :  this.idFlotillas.push({id_flotilla: '22'});

    }
    if ($('#dp_flota2_sec').prop('checked') == true) {
      flota = flota === {} ? {id_flotilla: '23'} :  this.idFlotillas.push({id_flotilla: '23'});

    }
    if ($('#dp_flota3_sec').prop('checked') == true) {
      flota = flota === {} ? {id_flotilla:' 25'}:  this.idFlotillas.push({id_flotilla: ' 25'});

    }
    this.ageType = this.idFlotillas
    this.filtroAlertasFlotas(this.idFlotillas);
  }

  obtenerCatalogoSanciones() {
    this.apiSancion.getSanciones().subscribe(sanciones => {
      this.lista_sanciones = sanciones;
    })
  }

 filtroAlertasFlotas(datos){
   let alertasFlota = [];
   let idFlotillas = datos;
   let cont = 0;
   let idFlota = '';
   let totalFlotas = idFlotillas.length;
    for (cont; cont < totalFlotas; cont++) {
      let flota = idFlotillas[cont];
      idFlota = flota.id_flotilla;
      this.rowData = [];
      this.listaAlerta.forEach(datosAlerta => {
       if (datosAlerta.id_flota == idFlota) {
         alertasFlota.push(datosAlerta);
       }
     });
    }
    if (alertasFlota.length == 0) {
      this.rowData = this.listaAlerta;
    }else{
      this.rowData = alertasFlota; 
    }
 }

  onRowClicked(e) {
    this.correoSancion = '';
    this.datosAlerta = e.data;
    this.id_alerta = this.datosAlerta['id_alerta_viaje'];
    this.id_viaje = this.datosAlerta['id_viaje']
    this.tipo_alerta = this.datosAlerta['nombre_alerta'];
    this.flota_alerta = this.datosAlerta['flota'];
    this.operador_alerta = this.datosAlerta['id_empleado'] + " " + this.datosAlerta['operador'];
    this.ubicacion = this.datosAlerta['latitud'] + " ," + this.datosAlerta['longitud'];
    this.unidad_alerta = this.datosAlerta['id_tractor'];
    this.comentario = this.datosAlerta['comentario'];
    this.aplica_sancion = this.datosAlerta['inc_operador'];
    this.envio_reporte = this.datosAlerta['envia_reporte'];
    this.obtenerCorreoFlota(this.datosAlerta['id_flota']);
    this.redactarCorreoSancion(this.comentario)
    if (this.datosAlerta['estado_alerta'] == 'Alta') {
      this.styleAlert = {
        background: '#BF343F',
        color: '#ffff'
      };
    }else if (this.datosAlerta['estado_alerta'] == 'Media') {
      this.styleAlert = {
        background: '#FF9900',
        color: '#ffff'
      };
    }else if (this.datosAlerta['estado_alerta'] == 'Baja') {
      this.styleAlert = {
        background: '#FFCD02',
        color: '#000000'
      };
    }else{
      this.styleAlert = {
        background: '#51A351',
        color: '#ffff'
      };
    }
    $(document).ready(function () {
      jQuery.noConflict();
      $('#mdl_alerta_viaje').modal('show');
      $('#mdl_alerta_viaje').draggable({
        handle: ".modal-header"
      });
    })
  }
  obtenerIdSancion(sancion) {
    const result = this.lista_sanciones.find(sanciones => sanciones.nombre === sancion);
    if (result != null) {
      this.id_sancion = result.id_sancion;
      this.nombre_sancion = result.nombre;
      this.descripcion_sancion = result.descripcion
    }
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    var columnState = JSON.parse(localStorage.getItem('myColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }
  posicion() {
    this.url = 'https://www.google.com/maps/search/?api=1&query=' + this.ubicacion;
  }

  prioridadAlerta(params) {
    let prioridad = params['data']['estado_alerta'];
    if (prioridad == 'Alta') {
      return { backgroundColor: '#BD362F', color: '#FFFFFF' };
    }
    else if (prioridad == 'Media') {
      return { backgroundColor: '#FF9900', color: '#FFFFFF' };
    } else if(prioridad == 'Baja') {
      return { backgroundColor: '#FFCD02', color: '#000000' };
    }else{
      return { backgroundColor: '#51A351', color: '#FFFFFF' };
    }
  }

  actualizarDatosAlerta() {
    this.id_usuario = localStorage.getItem('id_usuario');
    if(this.correoDestino != '') {
      this.envio_reporte = 'S';
    }else{
      this.envio_reporte = 'N'
    }
    let alerta = {
      'id_alerta': this.id_alerta,
      'comentario': this.comentario,
      'envio_reporte': this.envio_reporte,
      'id_usuario': this.id_usuario,
      'id_sancion': this.id_sancion,
      'inc_operador': this.aplica_sancion,
      'status': 'R'
    }
    this.apiAlerta.updateAlert(alerta).subscribe(datos => {
      if (datos == null) {
        if (alerta['inc_operador'] != "N") {
          let reporte = this.comentario;
          let id_viaje = this.id_viaje;
          let id_sancion = this.id_sancion;
          let id_usuario_alta = this.id_usuario;
          let id_alerta_viaje = this.id_alerta;
          let id_empleado_operador = this.datosAlerta['id_empleado'];
          let operador = this.datosAlerta['operador'];
          let id_unidad = this.unidad_alerta;
          let id_flota = this.datosAlerta['id_flota'];
          this.obtenerCorreoFlota(id_flota);
          let flota = this.flota_alerta;
          this.redactarCorreoSancion(reporte)
          this.apiSancion.postReporteSancion(reporte, id_viaje, id_sancion, id_usuario_alta, id_alerta_viaje, id_unidad,id_empleado_operador, operador,id_flota,flota).subscribe(datos => {
            if (datos['id_nuevo_reporte_sancion'] != null) {
              $(document).ready(function () {
                jQuery.noConflict();
                $('#mdl_alerta_viaje').modal('hide');
              })
              Swal.fire({
                title: 'Alerta atendida correctamente',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.correoDestino = [];
            }
          })
          Swal.fire({
            title: 'Alerta atendida correctamente',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else {
          $(document).ready(function () {
            jQuery.noConflict();
            $('#mdl_alerta_viaje').modal('hide');
          })
          this.correoDestino = [];
        }
      }
    });
  }

  asignarCorreos(){
    let correo = '';
    if($('#ch_operaciones').prop('checked') == true){
      correo = correo === '' ? `${this.correosIncidencias[15]['correo']}` : correo + `, ${this.correosIncidencias[15]['correo']}`;
    }
    if ($('#ch_rh').prop('checked') == true) {
      correo = correo === '' ? `${this.correosIncidencias[16]['correo']}` : correo + `, ${this.correosIncidencias[16]['correo']}`;
    }
    if ($('#ch_mantenimiento').prop('checked') == true) {
      correo = correo === '' ? `${this.correosIncidencias[17]['correo']}` : correo + `, ${this.correosIncidencias[17]['correo']}`;
    }
    if ($('#ch_atenc_clientes').prop('checked') == true) {
      correo = correo === '' ? `${this.correosIncidencias[18]['correo']}` : correo + `, ${this.correosIncidencias[18]['correo']}`;
    }
    if ($('#ch_operaciones_seco').prop('checked') == true) {
      correo = correo === '' ? `${this.correosIncidencias[14]['correo']}` : correo + `, ${this.correosIncidencias[14]['correo']}`;
    }
    this.correoDestino = correo;
  }

  redactarCorreoSancion(reporte) {
    if (this.correoDestino != '') {
      this.correoSancion = `${this.correoDestino}?cc=${this.correosIncidencias[0]['correo']}, ${this.correosIncidencias[1]['correo']}, ${this.correosIncidencias[2]['correo']}, ${this.correosIncidencias[3]['correo']}, ${this.correoDestinoFlota}&subject=REPORTE%20DE%20INCIDENCIA%20UNIDAD:%20
      ${this.unidad_alerta}/${this.nombre_sancion}&body=VIAJE:%20${this.id_viaje}%0D%0AUNIDAD:${this.unidad_alerta}%0D%0AOPERADOR:%20${this.datosAlerta['id_empleado']}%0D%0AJEFE DE FLOTA:%20${this.flota_alerta}%0D%0AINCIDENCIA:%20${this.descripcion_sancion}, %20${reporte}`;
    }else{
      this.correoSancion = `${this.correoDestinoFlota}?cc=${this.correosIncidencias[0]['correo']}, ${this.correosIncidencias[1]['correo']}, ${this.correosIncidencias[2]['correo']}, ${this.correosIncidencias[3]['correo']}&subject=REPORTE%20DE%20INCIDENCIA%20UNIDAD:%20
      ${this.unidad_alerta}/${this.nombre_sancion}&body=VIAJE:%20${this.id_viaje}%0D%0AUNIDAD:${this.unidad_alerta}%0D%0AOPERADOR:%20${this.datosAlerta['id_empleado']}%0D%0AJEFE DE FLOTA:%20${this.flota_alerta}%0D%0AINCIDENCIA:%20${this.descripcion_sancion}, %20${reporte}`;
    }
  }

  obtenerCorreos(){
    this.apiAlerta.getCorreosAlertas().subscribe(correos =>{
      this.correosIncidencias = correos
      this.correosIncidencias.forEach(proceso => {
        if (proceso['proceso'] == 'NOTIFICACION-INCIDEN') {
          this.correoFlota.push(proceso);
        }
      });
    })
  }
  onColumnMoved(e) {
    let columnState = JSON.stringify(e.columnApi.getColumnState());
    localStorage.setItem('myColumnState', columnState);
  }

  onColumnVisible(e) {
    console.log('Event Column Visible', e);
  }

  modalError(titulo, mensaje, codigoError) {
    this.spinnerService.hide()
    //Configuracion del diseño del modal
    var alerta = Swal.mixin({
      imageUrl: '/assets/general/img/icon/error.svg',
      imageWidth: 85,
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    });
    alerta.fire(
      {
        title: titulo, //Titulo del modal
        text: mensaje, //Mensaje del modal
        allowOutsideClick: false //Desactiva el backdrop, para no poder ocultar el modal hasta dar en el boton 'OK'
      }
    ).then((resultado) => {
      //Si el error fue 401(No Autorizado) por vencimiento de token, cierra la sesion
      if (resultado.value && codigoError === '401') {
        this.salir();//Cerrar la sesion del usuario
      }
    });
  }
  salir() {
    this.generalService.refreshToken()
  }
  obtenerCorreoFlota(flota){
    this.correoNotificacion = flota
    let correoFlota = '';
    this.correoDestinoFlota = '';
    if (this.correoNotificacion == '02') {
      correoFlota = this.correoFlota[0]['correo']
    }
    if (this.correoNotificacion == '03') {
      correoFlota = this.correoFlota[2]['correo']
    }
    if (this.correoNotificacion == '04') {
      correoFlota = this.correoFlota[3]['correo']
    }
    if (this.correoNotificacion == '10') {
      correoFlota = this.correoFlota[4]['correo']
    }
    if (this.correoNotificacion == '11') {
      correoFlota = this.correoFlota[5]['correo']
    }
    if (this.correoNotificacion =='16') {
      correoFlota = this.correoFlota[1]['correo']
    }
    if (this.correoNotificacion == '27') {
      correoFlota = this.correoFlota[6]['correo']
    }
    if (this.correoNotificacion == '22') {
      correoFlota = this.correoFlota[7]['correo']
    }
    if (this.correoNotificacion == '23') {
      correoFlota = this.correoFlota[8]['correo']
    }
    if (this.correoNotificacion == '25') {
      correoFlota = this.correoFlota[9]['correo']
    }
    this.correoDestinoFlota = correoFlota;
  }

  reporteAlertasAtendidas(form:NgForm){
    if(form.invalid){
      return;
    }
    this.apiAlerta.getAlertasAtendidas(this.fechaInicial, this.fechaFin).subscribe({
      next: alertas=>{
        alertas.forEach(datosAlerta => {
          datosAlerta.fec_alta = moment.utc(datosAlerta.fec_alta).format('DD-MM-YYYY HH:mm');
          if (datosAlerta.sancion == null) {
            datosAlerta.sancion = 'No aplica sanción'
          }
          let alertasAtendidas = {
            'fecha': datosAlerta.fec_alta,
            'alerta': datosAlerta.nombre_alerta,
            'prioridad': datosAlerta.estado_alerta,
            'sancion': datosAlerta.sancion,
            'usuario': datosAlerta.id_usuario_atendio,
            'comentario': datosAlerta.comentario
          }
          this.alertaAtendidas.push(Object.values(alertasAtendidas))
        });
        this.reporteAlerta(this.alertaAtendidas);
      },
      error: error =>{
        this.spinnerService.hide();
        if(localStorage.getItem('token')) {
          this.spinnerService.hide()
          if (error.status == 401) {
            this.modalError('La sesion a caducado', '','401');
          }else {
            Swal.fire({
              title: 'Error al actualizar los registros',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        }
      }
    })
  }
  reporteAlerta(data:any){
    let book = new Workbook();
    let doc_title = ["ALERTAS ATENDIDAS"];
    let sheet = book.addWorksheet('Hoja 1');
    sheet.addRow(doc_title);
    sheet.mergeCells('A1:F1');
    sheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
    sheet.getCell('A1').font = { bold: true, size: 14 }
    sheet.addRow([]);
    /**Tabla de alertas */
    let tab_header = ["FECHA", "ALERTA", "PRIORIDAD", "SANCIÓN", "USUARIO", "COMENTARIO"];
    let header_style = sheet.addRow(tab_header);
    header_style.height = 30;
    sheet.getColumn(1).width = 25;
    sheet.getColumn(1).alignment = { horizontal: 'center' }
    sheet.getColumn(2).width = 35;
    sheet.getColumn(2).alignment = { horizontal: 'center' }
    sheet.getColumn(3).width = 15;
    sheet.getColumn(3).alignment = { horizontal: 'center' }
    sheet.getColumn(4).width = 35;
    sheet.getColumn(4).alignment = { horizontal: 'center' }
    sheet.getColumn(5).width = 15;
    sheet.getColumn(5).alignment = { horizontal: 'center' }
    sheet.getColumn(6).width = 55;
    sheet.getColumn(6).alignment = { horizontal: 'center' }

    header_style.eachCell((cell) => {
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '014781' }, bgColor: { argb: 'ffff' } }
      cell.font = { bold: true, color: { argb: 'F2F2F2' } }
      cell.border = { left: { style: 'thin' }, top: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' } };
      cell.alignment = { vertical: 'middle', horizontal: 'center' }
    });
    data.forEach(incidencia => {
      let incidencia_style = sheet.addRow(incidencia);
      this.contador = this.contador + 1;
      incidencia_style.eachCell((cell, number) => {
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      });
    });
    book.xlsx.writeBuffer().then((data) => {
      const blob = new Blob(
        [data],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
      );
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = "ALERTAS_ATENDIDAS";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  }
  cerrarModal(form:NgForm){
    this.fechaInicial = '';
    this.fechaFin = '';
    form.reset;
  }
}
