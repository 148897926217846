import { Component, OnInit, Input } from '@angular/core';
import { OrigenDestinoService } from 'src/app/service/origen-destino/origen-destino.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Workbook } from 'exceljs';

declare var $: any;
declare const jQuery: any;

@Component({
  selector: 'app-origen-destino',
  templateUrl: './origen-destino.component.html',
  styleUrls: ['./origen-destino.component.css']
})
export class OrigenDestinoComponent implements OnInit {

  catalogoOri_Des: any = [];
  datosOrigenDestino: any;
  url: any = '';
  reporteOrigen = []
  contador: number;
  columnDefs: any = [];
  rowData: any;
  dato: any;
  nombreCol: any;
  gridColumnApi: any;
  defaultColDef: any;
  rowSelection: any;
  style = {
    width: '95%',
    height: '100%',
    flex: '1 1 auto'
  };
  constructor(
    private apiOrigenDestino: OrigenDestinoService,
    private spinnerService: NgxSpinnerService) {
    window.scroll(0, 0);
    this.defaultColDef = {
      resizable: true,
      width: 150,
      filter: true,
      sortable: true,
      floatingFilter: true
    };
    this.rowSelection = 'multiple';
  }
  @Input() navbar: boolean = false;
  ngOnInit(): void {
    this.getOrigenDestino();
  }

  getOrigenDestino() {
    let cveCia = '';
    let cveCliente = '';
    this.spinnerService.show();
    this.apiOrigenDestino.getOrigenDestino(cveCia, cveCliente).subscribe(origenDestino => {
      this.catalogoOri_Des = origenDestino;
      this.rowData = this.catalogoOri_Des
      this.columnDefs=[
        {field: "cve_cliente", headerName:"CLAVE", sortable: true, width: 250,  filter: 'agTextColumnFilter', floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: "nombre", headerName:"NOMBRE", sortable: true, width: 275, filter: 'agTextColumnFilter', floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: "ciudad", headerName: "CIUDAD", sortable: true, width: 275, filter: 'agTextColumnFilter', floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: "estado", headerName:"ESTADO", sortable: true, width: 275, filter: 'agTextColumnFilter', floatingFilterComponentParams: { suppressFilterButton: true }},
        {field: "direccion", headerName: "DIRECCIÓN", sortable: true, width: 350,  floatingFilter: false},
      ]
      this.spinnerService.hide();
    })
  }
  setWidthAndHeight(width, height) {
    this.style = {
      flex: '20px',
      width: width,
      height: height
    };
  }
  onGridReady(params) {
    this.gridColumnApi = params.columnApi;
    var columnState = JSON.parse(localStorage.getItem('myColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }
  onColumnMoved(e) {
    let columnState = JSON.stringify(e.columnApi.getColumnState());
    localStorage.setItem('myColumnState', columnState);
  }

  onColumnVisible(e) {
    console.log('Event Column Visible', e);
  }
  onCellClicked(e) {
    this.dato = e['data'];
    this.nombreCol = e['colDef']['field'];
    if (this.nombreCol == 'cve_cliente') {
      this.detallesClave(this.dato);
      jQuery.noConflict();
      $('#mdl_info_clave').modal('show');
    }
    if (this.nombreCol == 'poliza') {
    }
  }


  detallesClave(datos: any) {
    let cveCia = datos['cve_cia'];
    let cveCliente = datos['cve_cliente'];
    this.apiOrigenDestino.getOrigenDestino(cveCliente, cveCia).subscribe(origenDestino => {
      this.datosOrigenDestino = origenDestino;
    })
  }

  generarReporte() {
    this.catalogoOri_Des.forEach(destinos => {
      let origenes = {
        'clave': destinos.cve_cliente,
        'nombre': destinos.nombre,
        'ciudad': destinos.ciudad,
        'estado': destinos.estado,
        'direccion': destinos.direccion
      }
      this.reporteOrigen.push(Object.values(origenes))
    });
    this.reporteInci(this.reporteOrigen)
  }

  reporteInci(data: any) {
    let book = new Workbook();
    let doc_title = ["ORIGENES Y DESTINOS"];
    let sheet = book.addWorksheet('Hoja 1');
    sheet.addRow(doc_title);
    sheet.mergeCells('A1:H1');
    sheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
    sheet.getCell('A1').font = { bold: true, size: 14 }
    sheet.addRow([]);
    /**Tabla de inicidencias */
    let tab_header = ["CLAVE", "NOMBRE", "CIUDAD", "ESTADO", "DIRECCIÓN"];
    let header_style = sheet.addRow(tab_header);
    header_style.height = 50;
    sheet.getColumn(1).width = 25;
    sheet.getColumn(1).alignment = { horizontal: 'center' }
    sheet.getColumn(2).width = 45;
    sheet.getColumn(2).alignment = { horizontal: 'center' }
    sheet.getColumn(3).width = 50;
    sheet.getColumn(3).alignment = { horizontal: 'center' }
    sheet.getColumn(4).width = 50;
    sheet.getColumn(4).alignment = { horizontal: 'center' }
    sheet.getColumn(5).width = 50;
    sheet.getColumn(5).alignment = { horizontal: 'center' }

    header_style.eachCell((cell) => {
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '014781' }, bgColor: { argb: 'ffff' } }
      cell.font = { bold: true, color: { argb: 'F2F2F2' } }
      cell.border = { left: { style: 'thin' }, top: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' } };
      cell.alignment = { vertical: 'middle', horizontal: 'center' }
    });
    data.forEach(incidencia => {
      let incidencia_style = sheet.addRow(incidencia);
      this.contador = this.contador + 1;
      incidencia_style.eachCell((cell, number) => {
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      });
    });
    book.xlsx.writeBuffer().then((data) => {
      const blob = new Blob(
        [data],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
      );
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = "REPORTE_INCIDENCIAS";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  }
  formatDate(date: any) {
    let dd = date.getDate();
    let mm = date.getMonth() + 1; //January is 0!
    let yyyy = date.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }

    date = dd + '/' + mm + '/' + yyyy;

    return date;
  }
  posicion(dato: any) {
    let latitud_Pos = dato['latitud'];
    let longitud_Pos = dato['longitud'];
    this.url = 'https://www.google.com/maps/search/?api=1&query=' + latitud_Pos + ',' + longitud_Pos;
  }
}
