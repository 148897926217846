<main role="main" class="col-12" [ngClass]="{'pl-menu': !navbar}">
    <h5 class="mt-2">CATALOGO-ORIGENES Y DESTINO</h5>
    <div class="row">
        <!--TOOLBAR-->
        <!--/TOOLBAR-->
    </div>
    <div class="col-12 mt-5">
        <div [ngStyle]="style">
            <ag-grid-angular #agGrid_origenDestino class="ag-theme-alpine"
                [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowData]="rowData"
                [sideBar]="false" (cellClicked)="onCellClicked($event)"
                (columnMoved)="onColumnMoved($event)" (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
</main>
<div class="modal fade" id="mdl_info_clave" tabindex="-1" role="dialog" aria-labelledby="#mdl_info_clave"
    aria-hidden="true" data-backdrop="static" style="z-index: 10000;">
    <div class="modal-dialog modal-xl modal-dialog-centerd" role="document">
        <div class="modal-content" *ngFor='let origenDest of datosOrigenDestino'>
            <div class="modal-header bg-primary d-block">
                <button type="button" class="close color-white float-right" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title color-white text-center">CLAVES</h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <form>
                        <div class="row justify-content-start">
                            <div class="col-7 align-self-start">
                                <fieldset disabled>
                                    <div class="form-group row form-inline">
                                        <label>Nombre:</label>
                                        <div class="input-group input-group-sm col-sm">
                                            <input id="txt_nombre" type="text" class="form-control input-frio-caja"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                value="{{origenDest.nombre}}">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="form-inline">
                                            <label>Clave:</label>
                                            <div class="input-group input-group-sm col-sm">
                                                <input id="txt_clave" type="text" class="form-control input-frio-caja"
                                                    aria-label="Username" aria-describedby="basic-addon1"
                                                    autocomplete="off" value="{{origenDest.cve_cliente}}">
                                            </div>
                                            <label>No.Cliente:</label>
                                            <div class="input-group input-group-sm col-sm">
                                                <input id="txt_no_client" type="text"
                                                    class="form-control input-frio-caja" aria-label="Username"
                                                    aria-describedby="basic-addon1" autocomplete="off"
                                                    value="{{origenDest.nocliente}}">
                                            </div>
                                            <label>Status:</label>
                                            <div class="input-group input-group-sm col-sm">
                                                <input id="txt_status" type="text" class="form-control input-frio-caja"
                                                    aria-label="Username" aria-describedby="basic-addon1"
                                                    autocomplete="off" value="{{origenDest.status}}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row form-inline">
                                        <label>Calle y No.:</label>
                                        <div class="input-group input-group-sm col-sm">
                                            <input id="txt_calle_no" type="text" class="form-control input-frio-caja"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                value="{{origenDest.direccion}}">
                                        </div>
                                    </div>
                                    <div class="form-group row form-inline">
                                        <label>Colonia:</label>
                                        <div class="input-group input-group-sm col-sm">
                                            <input id="txt_col" type="text" class="form-control input-frio-caja"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                value="{{origenDest.colonia}}">
                                        </div>
                                    </div>
                                    <div class="form-group row justify-content-between">
                                        <div class="form-inline">
                                            <label>Ciudad:</label>
                                            <div class="input-group input-group-sm col-sm">
                                                <input id="txt_cid" type="text" class="form-control input-frio-caja"
                                                    aria-label="Username" aria-describedby="basic-addon1"
                                                    autocomplete="off" value="{{origenDest.ciudad }}">
                                            </div>
                                            <div class="input-group input-group-sm col-sm-2"></div>
                                            <label>Estado:</label>
                                            <div class="input-group input-group-sm col-sm">
                                                <input id="txt_edo" type="text" class="form-control input-frio-caja"
                                                    aria-label="Username" aria-describedby="basic-addon1"
                                                    autocomplete="off" value="{{origenDest.estado}}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="form-inline">
                                            <label>C.P.:</label>
                                            <div class="input-group input-group-sm col-sm">
                                                <input id="txt_cp" type="text" class="form-control input-frio-caja"
                                                    aria-label="Username" aria-describedby="basic-addon1"
                                                    autocomplete="off" value="{{origenDest.cp}}">
                                            </div>
                                            <label>RFC:</label>
                                            <div class="input-group input-group-sm col-sm">
                                                <input id="txt_rfc" type="text" class="form-control input-frio-caja"
                                                    aria-label="Username" aria-describedby="basic-addon1"
                                                    autocomplete="off" value="{{origenDest.rfc}}">
                                            </div>
                                            <label>Pago de Seguro:</label>
                                            <div class="input-group input-group-sm col-sm">
                                                <input id="txt_pago_seg" type="text"
                                                    class="form-control input-frio-caja" aria-label="Username"
                                                    aria-describedby="basic-addon1" autocomplete="off"
                                                    value="{{origenDest.pag_seguro}}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="form-inline">
                                            <label>Tel. Directo:</label>
                                            <div class="input-group input-group-sm col-sm">
                                                <input id="txt_tel_dir" type="text" class="form-control input-frio-caja"
                                                    aria-label="Username" aria-describedby="basic-addon1"
                                                    autocomplete="off" value="{{origenDest.tel_dire}}">
                                            </div>
                                            <label>Tel.Fax:</label>
                                            <div class="input-group input-group-sm col-sm">
                                                <input id="txt_tel_fax" type="text" class="form-control input-frio-caja"
                                                    aria-label="Username" aria-describedby="basic-addon1"
                                                    autocomplete="off" value="{{origenDest.fax}}">
                                            </div>
                                            <label>Tel.(Otro):</label>
                                            <div class="input-group input-group-sm col-sm">
                                                <input id="txt_tel_otro" type="text"
                                                    class="form-control input-frio-caja" aria-label="Username"
                                                    aria-describedby="basic-addon1" autocomplete="off"
                                                    value="{{origenDest.tel}}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row form-inline">
                                        <label>Contacto:</label>
                                        <div class="input-group input-group-sm col-sm">
                                            <input id="txt_cont" type="text" class="form-control input-frio-caja col"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                value="{{origenDest.contacto}}">
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="col-5">
                                <div class="form-group row justify-content-around">
                                    <fieldset disabled>
                                        <div class="form-inline">
                                            <label>Latitud:</label>
                                            <div class="input-group input-group-sm col-sm">
                                                <input id="txt_lat" type="text" class="form-control input-frio-caja"
                                                    aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                    value="{{origenDest.latitud}}">
                                            </div>
                                            <label>Longitud:</label>
                                            <div class="input-group input-group-sm col-sm">
                                                <input id="txt_long" type="text" class="form-control input-frio-caja"
                                                    aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                    value="{{origenDest.longitud}}">
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                                <div class="form-group row justify-content-around">
                                    <div class="form-inline">
                                        <div class="input-group input-group-sm col-sm">
                                            <a href="{{this.url}}" target="_blank"><button type="button"
                                                    class="btn btn-light" (click)="posicion(origenDest)"><i
                                                        class="fas fa-map-marker-alt" style="color:#BF343F;"></i>
                                                    MAPA</button></a>
                                        </div>
                                    </div>
                                </div>
                                <fieldset disabled>
                                    <div class="form-group text-center">
                                        <label>ESPECIFICACIONES</label>
                                        <textarea id="txt_especif" class="form-control input-frio-caja"
                                            rows="4">{{origenDest.espec}}</textarea>
                                    </div>
                                    <div class="form-group text-center">
                                        <label>COMENTARIOS</label>
                                        <textarea id="txt_comentarios" class="form-control input-frio-caja"
                                            rows="4">{{origenDest.comen}}</textarea>
                                    </div>
                                </fieldset>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>