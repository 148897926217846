<main role="main" class="col-12" [ngClass]="{'pl-menu': !navbar}">
    <h5 class="mt-2">REPORTE DE INCIDENCIAS</h5>
    <div class="row">
        <!--TOOLBAR-->
        <div class="form-inline col-12">
            <div class="input-group input-group-sm col-2 float-left">
                <button type="submit" class="btn btn-primary" (click)="generarReporte()">EXPORTAR REPORTE</button>
            </div>
        </div>
        <!--/TOOLBAR-->
    </div>
    <div class="col-12 mt-5">
        <div [ngStyle]="style">
            <ag-grid-angular #agGrid_mensaje class="ag-theme-alpine"
                [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowData]="rowData"
                [sideBar]="false"
                (columnMoved)="onColumnMoved($event)" (gridReady)="onGridReady($event)" (rowClicked)="onRowClicked($event)">
            </ag-grid-angular>
        </div>
    </div>
</main>
<div class="modal fade" id="mdl_info_sancion" tabindex="-1" role="dialog" aria-labelledby="mdl_info_caja"
    aria-hidden="true" data-backdrop="static" style="z-index: 10000;">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document" style="width: 100%;">
        <div class="modal-content">
            <div class="modal-header bg-primary d-block">
                <button type="button" class="close color-white float-right" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title color-white text-center" id="mdl_info_cajas">DETALLE DEL REPORTE DE INCIDENCIA</h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <form>
                        <fieldset disabled>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group row form-inline">
                                        <label>INCIDENCIA:</label>
                                        <div class="input-group input-group-sm col-sm">
                                            <input id="txt_linea_trans" type="text" class="form-control input-frio-caja"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                value="{{this.incidencia}}">
                                        </div>
                                    </div>
                                    <div class="form-group row form-inline">
                                        <label>OPERADOR:</label>
                                        <div class="input-group input-group-sm col-sm">
                                            <input id="txt_linea_trans" type="text" class="form-control input-frio-caja"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                value="{{this.operador}}">
                                        </div>
                                    </div>
                                    <div class="form-group row form-inline">
                                        <label>VIAJE:</label>
                                        <div class="input-group input-group-sm col-sm">
                                            <input id="txt_linea_trans" type="text" class="form-control input-frio-caja"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                value="{{this.viaje}}">
                                        </div>
                                        <label>UNIDAD:</label>
                                        <div class="input-group input-group-sm col-sm">
                                            <input id="txt_linea_trans" type="text" class="form-control input-frio-caja"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                value="{{this.unidad}}">
                                        </div>
                                    </div>
                                    <div class="form-group row form-inline">
                                        <label>FLOTA:</label>
                                        <div class="input-group input-group-sm col-sm">
                                            <input id="txt_linea_trans" type="text" class="form-control input-frio-caja"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                value="{{this.flota}}">
                                        </div>
                                    </div>
                                    <div class="form-group row form-inline">
                                        <label>REPORTO:</label>
                                        <div class="input-group input-group-sm col-sm">
                                            <input id="txt_linea_trans" type="text" class="form-control input-frio-caja"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                value="{{this.reporto}}">
                                        </div>
                                        <label>FECHA-HORA:</label>
                                        <div class="input-group input-group-sm col-sm">
                                            <input id="txt_linea_trans" type="text" class="form-control input-frio-caja"
                                                aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                                                value="{{this.fecha}}">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group text-center">
                                        <label>REPORTE</label>
                                        <textarea id="txt_comentarios_caja" class="form-control input-frio-caja" rows="4"
                                            value="{{this.reporte}}"></textarea>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>