import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/service/login/login.service';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OperadoresService {
  private headers: HttpHeaders;

  constructor(private httpApi: HttpClient, private apiAuth: LoginService) { }

  getOper(noOper:string,cia:string):Observable<any>{
    let noOperador = noOper;
    let cveCia = cia;
    if (noOperador != ' ' && cveCia != ' ') {
      let httpParams = new HttpParams()
      .append('noOperador', noOperador)
      .append('cveCia', cia)
      return this.httpApi.get<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/operadores?${httpParams}`)
      .pipe(map(data => data));
    }else{
      return this.httpApi.get<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/operadores`)
      .pipe(map(data => data));
    }
  }

  obtenerFotoOperador(idCompania:string, idOperador:string): Observable<any>{
    return this.httpApi.get<any>(`${environment.URL_SERVIDOR}/api-interna/general/empleados/${idOperador}/${idCompania}`)
    .pipe(map(data => data));   
  }
  private actualizaHeader(){
    let token = localStorage.getItem('token');
    this.headers = new HttpHeaders({
      "Authorization": `Bearer ${token}`
    });
  }
}
