import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/service/login/login.service';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotasService {
  private headers: HttpHeaders;

  constructor(private httpApi: HttpClient, private apiAuth: LoginService) { }

  getNotas(idViaje):Observable<any>{
    return this.httpApi.get<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/notas/${idViaje}`);
  }
  postNotas(descrip,usuario, compania,num_operador,num_viaje): Observable<any>{
    let httpParams = new HttpParams()
    .append('descripcion',descrip)
    .append('id_usuario_alta', usuario)
    .append('id_compania_operador', compania)
    .append('id_empleado_operador', num_operador)
    .append('id_viaje', num_viaje);
    return this.httpApi.post<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/notas?${httpParams}`,httpParams);
  }
  updateNota(idNota, descrip,estado):Observable<any>{
    let httpParams = new HttpParams()
    .append('idNota', idNota)
    .append('descri', descrip)
    .append('ind_estado', estado)
    return this.httpApi.put<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/notas?${httpParams}`,httpParams);
  }
  deleteNota(id):Observable<any>{
    let httpParams = new HttpParams()
    .append('idNota', id)
    return this.httpApi.delete<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/notas?${httpParams}`)
  }
}
