<main role="main" class="col-12" [ngClass]="{'pl-menu': !navbar}">
  <h5 class="mt-2">VIAJES</h5>
  <div class="row">
    <!--TOOLBAR-->
    <div class="form-inline col-12">
      <div class="dropdown">
        <button class="btn frio-btn-toolbar dropdown-toggle btn-sm mr-2" type="button" id="dropdownMenuButton"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          FLOTA DE REFRIGERADO
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item frio-btn-toolbar" *ngFor="let flotillas of flota_ref; let i = index" >
            <div class="custom-control custom-checkbox custom-control-inline float-left">
              <input type="checkbox" class="custom-control-input" id="dm_flotilla_ref_{{i}}" value="{{flotillas.id}}" (change)="filtrarFlotillaRef()"> 
              <label class="custom-control-label" for="dm_flotilla_ref_{{i}}">{{flotillas.nombre}}</label>
            </div>
          </a>
        </div>
      </div>
      <div class="dropdown">
        <button class="btn frio-btn-toolbar dropdown-toggle btn-sm mr-2" type="button" id="dropdownMenuButton"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          FLOTA DE SECO
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item frio-btn-toolbar" *ngFor="let flotillas of flota_seco; let i = index">
            <div class="custom-control custom-checkbox custom-control-inline float-left">
              <input type="checkbox" class="custom-control-input" id="dm_flotilla_seco_{{i}}" value="{{flotillas.id}}" (change)="filtrarFlotillaSeco()"> 
              <label class="custom-control-label" for="dm_flotilla_seco_{{i}}">{{flotillas.nombre}}</label>
            </div>
          </a>
        </div>
      </div>
      <div class="form-inline">
        <div class="dropdown">
          <button class="btn frio-btn-toolbar dropdown-toggle btn-sm mr-2" type="button" id="dropdownMenuViajesButton"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            FILTRO DE VIAJES
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuViajesButton">
            <a class="dropdown-item frio-btn-toolbar">
              <div class="custom-control custom-checkbox float-left">
                <input type="checkbox" class="custom-control-input" id="dp_viaje_frio" (click)="onGridReady(this.gridParams);">
                <label class="custom-control-label" for="dp_viaje_frio">FRIO EXPRESS</label>
              </div>
            </a>
            <a class="dropdown-item frio-btn-toolbar">
              <div class="custom-control custom-checkbox float-left">
                <input type="checkbox" class="custom-control-input" id="dp_viaje_trans" (click)="mostrarViajesTransfrontera()">
                <label class="custom-control-label" for="dp_viaje_trans">TRANSFRONTERA</label>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div>
        <div class="form-group form-check-inline">
          <input type="checkbox" class="form-check-input" id="ch_viaje_alerta" (change)="filtroUnidadAlert()">
          <label class="form-check-label label-check" style="color:#004680 ;" for="ch_viaje_alerta"> VIAJES
            CON ALERTAS</label>
        </div>
      </div>
      <div class="input-group input-group-sm">
        <button type="submit" class="btn btn-outline-light" style="font-size: small;" (click)="actualizarDatos()"><i
            class="fas fa-redo"></i> ACTUALIZAR DATOS</button>
      </div>
    </div>
    <!--/TOOLBAR-->
  </div>
  <div class="col-12 mt-5">
    <div *ngIf="mostrarViajesFrio">
      <ag-grid-angular #agGrid 
        class="ag-theme-alpine" 
        domLayout='autoHeight'
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef" 
        (cellDoubleClicked)="onCellDoubleClicked($event)" 
        [sideBar]="true"
        (cellKeyPress)="onCellKeyPress($event)" 
        [allowContextMenuWithControlKey]="true" 
        [getContextMenuItems]="getContextMenuItems" 
        [enterMovesDownAfterEdit]="true"
        (cellEditingStarted)="onCellEditingStarted($event)" 
        (cellEditingStopped)="onCellEditingStopped($event)"
        (columnMoved)="onColumnMoved($event)" 
        (firstDataRendered)="onFirstDataRendered($event)"
        [pagination]="true"
        [gridOptions]="gridOptions"
        [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests"
        [infiniteInitialRowCount]="infiniteInitialRowCount"
        (paginationChanged)="onPageChange($event)"
        (gridReady)="onGridReady($event)"
        (filterChanged)="onFilterChanged($event)">
      </ag-grid-angular>
    </div>
    <div *ngIf="mostrarViajesTransf">
      <app-viajes-trans></app-viajes-trans>
    </div>
  </div>
</main>
<!--Modal paradas intermedias -->
<div class="fade modal-tablero" id="mdl_par_int_viaje" tabindex="-1" role="dialog" aria-labelledby="mdl_par_int_viaje"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary d-block">
        <button type="button" class="close color-white float-right" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <button *ngIf="mdlViaje == false" type="button" class="maximizar color-white float-right"
          (click)="maximizar('mdl_par_int_viaje')">
          <span><i class="fas fa-external-link-alt"></i></span>
        </button>
        <button *ngIf="mdlViaje == true" type="button" class="maximizar color-white float-right"
          (click)="minimizar('mdl_par_int_viaje')">
          <span><i class="fas fa-minus"></i></span>
        </button>
        <h5 class="modal-title color-white text-center">DETALLE DEL VIAJE: {{this.no_pedido}} </h5>
      </div>
      <div class="modal-body" *ngIf="mdlViaje">
        <div class="container-fluid" *ngFor="let datos of this.datosDetalleViaje[0]">
          <form>
            <div class="form-row justify-content-start">
              <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                <label>Origen</label>
                <input id="no_oper" type="text" class="form-control" aria-label="Username"
                  aria-describedby="basic-addon1" autocomplete="off" value="{{datos.origen}}" disabled>

              </div>
              <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                <label>Destino</label>
                <input id="no_oper" type="text" class="form-control" aria-label="Username"
                  aria-describedby="basic-addon1" autocomplete="off" value="{{datos.destino}}" disabled>

              </div>
              <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                <label>Cliente</label>
                <input id="no_oper" type="text" class="form-control" aria-label="Username"
                  aria-describedby="basic-addon1" autocomplete="off" value="{{datos.nickname}}" disabled>

              </div>
              <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                <label>Tracto:</label>
                <input id="no_tracto" type="text" class="form-control" aria-label="Username"
                  aria-describedby="basic-addon1" autocomplete="off" value="{{datos.no_cdc || datos.no_trac}}" disabled>
              </div>
              <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                <label>Placas:</label>
                <input id="placas_trac" type="text" class="form-control" aria-label="Username"
                  aria-describedby="basic-addon1" autocomplete="off" value="{{datos.placas_tractor}}" disabled>
              </div>
              <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                <label>Caja:</label>
                <input id="no_caja" type="text" class="form-control" aria-label="Username"
                  aria-describedby="basic-addon1" autocomplete="off" value="{{datos.no_caja}}" disabled>
              </div>
              <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                <label>Placas:</label>
                <input id="placas_caja" type="text" class="form-control" aria-label="Username"
                  aria-describedby="basic-addon1" autocomplete="off" value="{{datos.placas_caja}}" disabled>
              </div>
              <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                <label>No.Operador</label>
                <input id="no_oper" type="text" class="form-control" aria-label="Username"
                  aria-describedby="basic-addon1" autocomplete="off" value="{{datos.no_oper}}" disabled>

              </div>
              <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                <label>Fecha carga</label>
                <input id="no_oper" type="text" class="form-control" aria-label="Username"
                  aria-describedby="basic-addon1" autocomplete="off" value="{{datos.fecha_carga}}" disabled>

              </div>
              <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                <label>Fecha descarga</label>
                <input id="no_oper" type="text" class="form-control" aria-label="Username"
                  aria-describedby="basic-addon1" autocomplete="off" value="{{datos.fecha_descarga}}" disabled>

              </div>
              <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                <label>Produto</label>
                <input id="no_oper" type="text" class="form-control" aria-label="Username"
                  aria-describedby="basic-addon1" autocomplete="off" value="{{datos.producto}}" disabled>

              </div>
              <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                <label>Flete frio</label>
                <input id="no_oper" type="text" class="form-control" aria-label="Username"
                  aria-describedby="basic-addon1" autocomplete="off" value="{{datos.flete_frio}}" disabled>

              </div>
              <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                <label>Flete americano</label>
                <input id="no_oper" type="text" class="form-control" aria-label="Username"
                  aria-describedby="basic-addon1" autocomplete="off" value="{{datos.flete_ext}}" disabled>

              </div>
              <div class="form-group form-inline col-12 col-md-6 col-lg-3 input-group-sm">
                <input class="form-check-input" type="checkbox" id="descargaAutorizada" disabled>
                <label>Descarga autorizada</label>
              </div>
              <div class="form-group form-inline col-12 col-md-6 col-lg-3 input-group-sm">
                <input class="form-check-input" type="checkbox" id="custodia" disabled>
                <label>Custodia</label>
              </div>
              <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                <label>Fecha Custodia:</label>
                <input id="fecha_custodia" type="text" class="form-control" aria-label="Username"
                  aria-describedby="basic-addon1" autocomplete="off" value="{{datos.fecha_custodia}}" disabled>
              </div>
              <div class="col-12 col-md-6 col-lg-3">
                <button type="button" class="btn btn-primary" style="margin: top 5px;" data-toggle="modal"
                  data-target="#comentarios">
                  Comentarios
                </button>
              </div>
              <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm" *ngIf="this.dato['parinter'] == 'SI'">
                <button type="button" class="btn btn-primary" style="margin: top 5px;" data-toggle="modal"
                  data-target="#paradasIntermedias">
                  Paradas intermedias
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Comentarios viaje-->
<div class="fade modal-tablero" id="comentarios" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document" style="width: auto; height: auto;">
    <div class="modal-content" *ngFor="let datos of this.datosDetalleViaje[0]">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">COMENTARIOS DEL VIAJE</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" id="comen-viaje-tab" data-toggle="tab" href="#comen_viaje" role="tab"
              aria-controls="viaje" aria-selected="true">Comentarios viajes</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="comen-ope-tab" data-toggle="tab" href="#comen_ope" role="tab" aria-controls="ope"
              aria-selected="true">Comentarios operaciones</a>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="comen_viaje" role="tabpanel" aria-labelledby="comen-viaje-tab">
            <fieldset disabled>
              <div class="col-12 col-md-8 col-lg-12 align-self-start">
                <div class="form-group text-center">
                  <label>Instrucciones seguridad:</label>
                  <textarea id="comentarios_custodia" class="form-control input-frio-caja"
                    rows="3">{{datos.comen_seguridad}}</textarea>
                </div>
              </div>
              <div class="col-12 col-md-8 col-lg-12 align-self-start">
                <div class="form-group text-center">
                  <label>Instrucciones operaciones:</label>
                  <textarea id="comentarios_viajes" class="form-control input-frio-caja"
                    rows="3">{{datos.comen_ope1}}</textarea>
                </div>
              </div>
              <div class="col-12 col-md-8 col-lg-12 align-self-start">
                <div class="form-group text-center">
                  <label>Instrucciones liquidaciones:</label>
                  <textarea id="comentarios_viajes" class="form-control input-frio-caja"
                    rows="3">{{datos.comen_liquidaciones}}</textarea>
                </div>
              </div>
            </fieldset>
          </div>
          <div class="tab-pane fade" id="comen_ope" role="tabpanel" aria-labelledby="comen-ope-tab">
            <fieldset disabled>
              <div class="col-12 col-md-8 col-lg-12 align-self-start">
                <div class="form-group text-center">
                  <label>Comentarios para Atencion a clientes:</label>
                  <textarea id="comentarios_custodia" class="form-control input-frio-caja"
                    rows="3">{{datos.comen_opasignacion}}</textarea>
                </div>
              </div>
              <div class="col-12 col-md-8 col-lg-12 align-self-start">
                <div class="form-group text-center">
                  <label>Comentarios operativos para el viaje:</label>
                  <textarea id="comentarios_viajes" class="form-control input-frio-caja"
                    rows="3">{{datos.comen_opviaje}}</textarea>
                </div>
              </div>
              <div class="col-12 col-md-8 col-lg-12 align-self-start">
                <div class="form-group text-center">
                  <label>Comentarios custodia:</label>
                  <textarea id="comentarios_viajes" class="form-control input-frio-caja"
                    rows="3">{{datos.lugar_custodia}}</textarea>
                </div>
              </div>
            </fieldset> 
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="fade modal-tablero" id="paradasIntermedias" tabindex="-1" role="dialog" aria-labelledby="paradasIntermedias"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width: auto; height:auto">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Paradas intermedias</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <label class="text-center">PARADAS INTERMEDIAS: </label>
          <div class="row align-middle">
            <div class="col-12 mt-2">
              <ag-grid-angular #agGrid_pradasInter class="ag-theme-alpine paradas-inter"
                style="height: 250px; width: 100%;" [columnDefs]="columnDefsParaInter" [defaultColDef]="defaultColDef"
                [rowData]="rowDataParadaInter" (cellClicked)="onCellClickedParInt($event)"
                (gridReady)="onGridReadyParadasInter($event)">
              </ag-grid-angular>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Modal Clave-->
<div class="fade modal-tablero" id="mdl_clave" tabindex="-1" role="dialog" aria-labelledby="mdl_clave"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-lg" role="document" style="max-width:1040px;">
    <div class="modal-content" *ngFor="let detalles of detallesParaInter">
      <div class="modal-header bg-primary d-block">
        <button type="button" class="close color-white float-right" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title color-white text-center" id="mdl_par_int_viaje">CLAVES</h5>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <form>
            <div class="row justify-content-start">
              <div class="col-6 align-self-start">
                <div class="form-group row">
                  <label>Nombre:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_nombre" type="text" class="form-control" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" value="{{detalles.nombre}}">
                  </div>
                </div>
                <div class="form-group row form-inline">
                  <label>CLAVE:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_clave" type="text" class="form-control" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" value="{{detalles.cve_cliente}}">
                  </div>
                  <label>No.CLIENTE</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_no_cliente" type="text" class="form-control" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" value="{{detalles.nocliente}}">
                  </div>
                </div>
                <div class="form-group row form-inline">
                  <label>STATUS:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_status" type="text" class="form-control" placeholder="activo" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" value="{{detalles.status}}">
                  </div>
                </div>
                <div class="form-group row form-inline">
                  <label>CONDICIONES DE PAGO:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_cond_pago" type="text" class="form-control" placeholder="contado"
                      aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                      value="{{detalles.cond_pago}}">
                  </div>
                </div>
                <div class="form-group row form-inline">
                  <label>CALLE Y NO.:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_cond_pago" type="text" class="form-control" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" value="{{detalles.direccion}}">
                  </div>
                </div>
                <div class="form-group row form-inline">
                  <label>COLONIA:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_cond_pago" type="text" class="form-control" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" value="{{detalles.colonia}}">
                  </div>
                </div>
                <div class="form-group row form-inline">
                  <label>CIUDAD:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_cond_pago" type="text" class="form-control" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" value="{{detalles.ciudad}}">
                  </div>
                </div>
                <div class="form-group row form-inline">
                  <label>ESTADO:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_cond_pago" type="text" class="form-control" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" value="{{detalles.estado}}">
                  </div>
                </div>
                <div class="form-group row form-inline">
                  <label>C.P.:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_cond_pago" type="text" class="form-control" placeholder="45645" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" value="{{detalles.cp}}">
                  </div>
                  <label>RFC:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_cond_pago" type="text" class="form-control" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" value="{{detalles.rfc}}">
                  </div>
                </div>
                <div class="form-group row form-inline">
                  <label>TEL.DIRECTO:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_cond_pago" type="text" class="form-control" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" value="{{detalles.tel_dire}}">
                  </div>
                  <label>TEL.FAX.:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_cond_pago" type="text" class="form-control" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" value="{{detalles.tel}}">
                  </div>
                </div>
                <div class="form-group row form-inline">
                  <label>TEL.OTRO:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_cond_pago" type="text" class="form-control" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off">
                  </div>
                </div>
                <div class="form-group row form-inline">
                  <label>CONTACTO:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_cond_pago" type="text" class="form-control" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" value="{{detalles.contacto}}">
                  </div>
                </div>
              </div>
              <div class="col-6 justify-content-around">
                <div class="form-group row">
                  <label>LATITUD:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_latitud" type="text" class="form-control" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" value="{{detalles.latitud}}">
                  </div>
                  <label>LONGITUD:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_longitud" type="text" class="form-control" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" value="{{detalles.longitud}}">
                  </div>
                </div>
                <div class="form-group row justify-content-around">
                  <div class="form-inline">
                    <div class="input-group input-group-sm col-sm">
                      <a href="{{this.url}}" target="_blank"><button type="button" class="btn btn-light"
                          (click)="posicion(detalles)"><i class="fas fa-map-marker-alt" style="color:#BF343F;"></i>
                          MAPA</button></a>
                    </div>
                  </div>
                </div>
                <div class="form-group text-center">
                  <label>REFERENCIAS GEOGRAFICAS </label>
                  <textarea id="txt_ref_geo" class="form-control" rows="4">{{detalles.ref_geo}}</textarea>
                </div>
                <div class="form-group text-center">
                  <label>ESPECIFICACIONES</label>
                  <textarea id="txt_especif" class="form-control inp_tipo_nota" rows="4">{{detalles.espec}}</textarea>
                </div>
                <div class="form-group text-center">
                  <label>COMENTARIOS</label>
                  <textarea id="txt_comentarios_cve" class="form-control inp_tipo_nota"
                    rows="4">{{detalles.comen}}</textarea>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Modal info caja-->
<div class="fade modal-tablero" id="mdl_info_caja" tabindex="-1" role="dialog" aria-labelledby="mdl_info_caja"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content" *ngFor="let caja of cajaInfo">
      <div class="modal-header bg-primary d-block">
        <button type="button" class="close color-white float-right" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title color-white text-center" id="mdl_info_cajas">CAJA</h5>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <form>
            <fieldset disabled>
              <div class="form-row">
                <div class="form-group col-12 col-md-6 col-lg-4 input-group-sm">
                  <label>Linea Transportista:</label>
                  <input id="txt_linea_trans" type="text" class="form-control input-frio-caja" value="{{caja.nombre}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-4 input-group-sm">
                  <label>Año:</label>
                  <input id="txt_año" type="text" class="form-control input-frio-caja" aria-label="Username"
                    aria-describedby="basic-addon1" autocomplete="off" value="{{caja.year_caja}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-4 input-group-sm">
                  <label>Medidas:</label>
                  <input id="txt_medida" type="text" class="form-control input-frio-caja" placeholder="contado"
                    aria-label="Username" aria-describedby="basic-addon1" autocomplete="off" value="{{caja.medida}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-4 input-group-sm">
                  <label>Serie:</label>
                  <input id="txt_serie" type="text" class="form-control input-frio-caja" placeholder="NACIONAL"
                    aria-label="Username" aria-describedby="basic-addon1" autocomplete="off" value="{{caja.no_serie}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-4 input-group-sm">
                  <label>Placas:</label>
                  <input id="txt_placa" type="text" class="form-control input-frio-caja" placeholder="contado"
                    aria-label="Username" aria-describedby="basic-addon1" autocomplete="off" value="{{caja.placas}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-4 input-group-sm">
                  <label>Marca de Thermos:</label>
                  <input id="txt_marca_term" type="text" class="form-control input-frio-caja" aria-label="Username"
                    aria-describedby="basic-addon1" autocomplete="off" value="{{caja.marca_termo}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-4 input-group-sm">
                  <label>No. Thermo:</label>
                  <input id="txt_no_thermo" type="text" class="form-control input-frio-caja" placeholder="contado"
                    aria-label="Username" aria-describedby="basic-addon1" autocomplete="off" value="{{caja.termo}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-4 input-group-sm">
                  <label>No.Caja: </label>
                  <input id="txt_no_caja" type="text" class="form-control input-frio-caja" aria-label="Username"
                    aria-describedby="basic-addon1" autocomplete="off" value="{{caja.no_caja}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-4 input-group-sm">
                  <label>Status:</label>
                  <input id="txt_status" type="text" class="form-control input-frio-caja" aria-label="Username"
                    aria-describedby="basic-addon1" autocomplete="off" value="{{caja.status}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-4 input-group-sm">
                  <label>Ult. Ubicación:</label>
                  <input id="txt_medida" type="text" class="form-control input-frio-caja" aria-label="Username"
                    aria-describedby="basic-addon1" autocomplete="off" value="{{caja.ubica}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-4 input-group-sm">
                  <label>Fec. Ubicación:</label>
                  <input id="txt_serie" type="text" class="form-control input-frio-caja" aria-label="Username"
                    aria-describedby="basic-addon1" autocomplete="off" value="{{caja.fech_ulubica}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-4 input-group-sm">
                  <label>Mes Verificación:</label>
                  <input id="txt_medida" type="text" class="form-control input-frio-caja" aria-label="Username"
                    aria-describedby="basic-addon1" autocomplete="off" value="{{caja.verificacion}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-4 input-group-sm">
                  <label>Poliza:</label>
                  <input id="txt_serie" type="text" class="form-control input-frio-caja" aria-label="Username"
                    aria-describedby="basic-addon1" autocomplete="off" value="{{caja.no_polseg}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-8 input-group-sm">
                  <label>Comentarios:</label>
                  <textarea id="txt_comentarios_caja" class="form-control input-frio-caja" rows="4"
                    value="{{caja.comen}}"></textarea>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!--INFO OPER-->
<div class="fade modal-tablero" id="mdl_info_oper" tabindex="-1" role="dialog" aria-labelledby="#mdl_info_oper"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-lg modal-dialog-centerd" role="document">
    <div class="modal-content" *ngFor="let ope of operData">
      <div class="modal-header bg-primary d-block">
        <button type="button" class="close color-white float-right" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title color-white text-center">OPERADOR</h5>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <form>
            <fieldset disabled>
              <div class="form-row align-items-center">
                <div class="col-12 col-md-6 col-lg-3" *ngIf="avatar">
                  <img src="../../../../../../../assets/general/img/avatar.png" />
                </div>
                <div class="col-12 col-md-6 col-lg-3" *ngIf="imagen">
                  <img style="width: auto; height: auto;" src="{{imagenOperador}}" />
                </div>
                <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                  <label>No. Operador:</label>
                  <input id="txt_no_oper" type="text" class="form-control input-frio-caja" placeholder="1548"
                      aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                      value="{{ope.driverno}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                  <label>Tractor:</label>
                  <input id="txt_tractor" type="text" class="form-control input-frio-caja" placeholder="3581"
                      aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                      value="{{ope.tractor}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                  <label>Status:</label>
                  <input id="txt_status" type="text" class="form-control input-frio-caja" placeholder="ACTIVO"
                      aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                      value="{{ope.status}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                  <label>Caja: </label>
                  <input id="txt_caja" type="text" class="form-control input-frio-caja" placeholder="3941"
                      aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                      value="{{ope.trailer}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                  <label>Tipo Operador:</label>
                  <input id="txt_tipo_oper" type="text" class="form-control input-frio-caja" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" value="{{ope.tipo_oper}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                  <label>Fecha Alta:</label>
                  <input id="txt_fecha_alt" type="text" class="form-control input-frio-caja"
                      placeholder="06/12/2017" aria-label="Username" aria-describedby="basic-addon1"
                      autocomplete="off" value="{{ope.fecha_alt}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                  <label>Nombre:</label>
                  <input id="txt_nombre_oper" type="text" class="form-control input-frio-caja"
                  placeholder="JOSE LEONARDO" aria-label="Username" aria-describedby="basic-addon1"
                  autocomplete="off" value="{{ope.nombreoperador}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                  <label>Nickname:</label>
                  <input id="txt_nickname" type="text" class="form-control input-frio-caja" aria-label="Username"
                  aria-describedby="basic-addon1" autocomplete="off" value="{{ope.nickname}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                  <label>Apellido Paterno:</label>
                  <input id="txt_ape_paterno" type="text" class="form-control input-frio-caja"
                      placeholder="GUERRERO" aria-label="Username" aria-describedby="basic-addon1"
                      autocomplete="off" value="{{ope.ape_p}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                  <label>Fec. Nacimiento:</label>
                  <input id="txt_fec_nac" type="text" class="form-control input-frio-caja"
                  placeholder="21/09/1961" aria-label="Username" aria-describedby="basic-addon1"
                  autocomplete="off" value="{{ope.fecha_nac}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                  <label>Apellido Materno:</label>
                  <input id="txt_ape_materno" type="text" class="form-control input-frio-caja" placeholder="GARZA"
                  aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                  value="{{ope.ape_m}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                  <label>RFC:</label>
                  <input id="txt_rfc" type="text" class="form-control input-frio-caja" placeholder="GUGL600921LJA"
                  aria-label="Username" aria-describedby="basic-addon1" autocomplete="off" value="{{ope.rfc}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                  <label>Jefe de Flota:</label>
                  <input id="txt_jefe_flota" type="text" class="form-control input-frio-caja"
                  placeholder="" aria-label="Username" aria-describedby="basic-addon1"
                  autocomplete="off" value="{{ope.nombref}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                  <label>Empresa</label>
                  <input id="txt_empres" type="text" class="form-control input-frio-caja"
                  placeholder="" aria-label="Username" aria-describedby="basic-addon1"
                  autocomplete="off" value="{{ope.razon_social}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                  <label>Calle y Número:</label>
                  <input id="txt_calle_num" type="text" class="form-control input-frio-caja"
                   aria-label="Username" aria-describedby="basic-addon1"
                  autocomplete="off" value="{{ope.calle_no}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                  <label>Colonia:</label>
                  <input id="txt_col" type="text" class="form-control input-frio-caja"
                      aria-label="Username" aria-describedby="basic-addon1" autocomplete="off" value="{{ope.colonia}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                  <label>Ciudad:</label>
                  <input id="txt_ciud" type="text" class="form-control input-frio-caja"
                  aria-label="Username" aria-describedby="basic-addon1" autocomplete="off" value="{{ope.ciudad}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                  <label>Estado:</label>
                  <input id="txt_edo" type="text" class="form-control input-frio-caja"
                      aria-label="Username" aria-describedby="basic-addon1" autocomplete="off" value="{{ope.edo}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                  <label>C.P:</label>
                  <input id="txt_cod_post" type="text" class="form-control input-frio-caja"
                  aria-label="Username" aria-describedby="basic-addon1"
                  autocomplete="off" value="{{ope.cp}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                  <label>Tel. Casa:</label>
                  <input id="txt_tel_casa" type="text" class="form-control input-frio-caja"
                     aria-label="Username" aria-describedby="basic-addon1"
                      autocomplete="off" value="{{ope.tel_casa}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                  <label>Tel. Familiar:</label>
                  <input id="txt_tel_fam" type="text" class="form-control input-frio-caja"
                  placeholder="JOSE LEONARDO" aria-label="Username" aria-describedby="basic-addon1"
                  autocomplete="off" value="{{ope.tel_fam}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                  <label>Tel.Movil:</label>
                  <input id="txt_movil" type="tel" class="form-control input-frio-caja"
                      placeholder="812-005-00-69" aria-label="Username" aria-describedby="basic-addon1"
                      autocomplete="off" value="{{ope.tel_movil}}">
                </div>
                <div class="form-group col-12 col-md-6 col-lg-3 input-group-sm">
                  <label>Tel.Movil 1:</label>
                  <input id="txt_movil_uno" type="tel" class="form-control input-frio-caja" aria-label="Username"
                  aria-describedby="basic-addon1" autocomplete="off">
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col">
                  <label>COMENTARIOS</label>
                  <textarea id="txt_comentarios_oper" class="form-control input-frio-caja" rows="5"
                      value="{{ope.comen}}"></textarea>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Modal Origen Destino-->
<div class="fade modal-tablero" id="mdl_info_clave" tabindex="-1" role="dialog" aria-labelledby="#mdl_info_tracto"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-xl modal-dialog-centerd" role="document">
    <div class="modal-content" *ngFor='let origenDest of datosOrigenDestino'>
      <div class="modal-header bg-primary d-block">
        <button type="button" class="close color-white float-right" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title color-white text-center">CLAVES</h5>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <form>
            <div class="row justify-content-start">
              <div class="col-6 align-self-start">
                <div class="form-group row form-inline">
                  <label>Nombre:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_nombre" type="text" class="form-control input-frio-caja" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" value="{{origenDest.nombre}}">
                  </div>
                </div>
                <div class="form-group row">
                  <div class="form-inline">
                    <label>Clave:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_clave" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{origenDest.claveoid}}">
                    </div>
                    <label>No.Cliente:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_no_client" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{origenDest.cve_cliente}}">
                    </div>
                    <label>Status:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_status" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{origenDest.status}}">
                    </div>
                  </div>
                </div>
                <div class="form-group row form-inline">
                  <label>Calle y No.:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_calle_no" type="text" class="form-control input-frio-caja" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" value="{{origenDest.direccion}}">
                  </div>
                </div>
                <div class="form-group row form-inline">
                  <label>Colonia:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_col" type="text" class="form-control input-frio-caja" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" value="{{origenDest.colonia}}">
                  </div>
                </div>
                <div class="form-group row justify-content-between">
                  <div class="form-inline">
                    <label>Ciudad:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_cid" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{origenDest.ciudad }}">
                    </div>
                    <div class="input-group input-group-sm col-sm-2"></div>
                    <label>Estado:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_edo" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{origenDest.estado}}">
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="form-inline">
                    <label>C.P.:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_cp" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{origenDest.cp}}">
                    </div>
                    <label>RFC:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_rfc" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{origenDest.rfc}}">
                    </div>
                    <label>Pago de Seguro:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_pago_seg" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{origenDest.pag_seguro}}">
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="form-inline">
                    <label>Tel. Directo:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_tel_dir" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{origenDest.tel_directo}}">
                    </div>
                    <label>Tel.Fax:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_tel_fax" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{origenDest.fax}}">
                    </div>
                    <label>Tel.(Otro):</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_tel_otro" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{origenDest.tel}}">
                    </div>
                  </div>
                </div>
                <div class="form-group row form-inline">
                  <label>Contacto:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_cont" type="text" class="form-control input-frio-caja col" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" value="{{origenDest.contacto}}">
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group row justify-content-around">
                  <div class="form-inline">
                    <label>Latitud:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_lat" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{origenDest.latitud}}">
                    </div>
                    <label>Longitud:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_long" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{origenDest.longitud}}">
                    </div>
                  </div>
                </div>
                <div class="form-group row justify-content-around">
                  <div class="form-inline">
                    <div class="input-group input-group-sm col-sm">
                      <a href="{{this.url}}" target="_blank"><button type="button" class="btn btn-light"
                          (click)="posicion(origenDest)"><i class="fas fa-map-marker-alt" style="color:#BF343F;"></i>
                          MAPA</button></a>
                    </div>
                  </div>
                </div>
                <div class="form-group text-center">
                  <label>ESPECIFICACIONES</label>
                  <textarea id="txt_especif" class="form-control input-frio-caja"
                    rows="4">{{origenDest.espec}}</textarea>
                </div>
                <div class="form-group text-center">
                  <label>COMENTARIOS</label>
                  <textarea id="txt_comentarios_origen" class="form-control input-frio-caja"
                    rows="4">{{origenDest.comen}}</textarea>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Modal info tractor-->
<div class="fade modal-tablero" id="mdl_info_tracto" tabindex="-1" role="dialog" aria-labelledby="#mdl_info_tracto"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-xl modal-dialog-centerd" role="document">
    <div class="modal-content" *ngFor="let tracto of tractoInfo">
      <div class="modal-header bg-primary d-block">
        <button type="button" class="close color-white float-right" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title color-white text-center">TRACTOR</h5>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <form>
            <fieldset disabled>
              <div class="form-group row justify-content-start">
                <div class="col-6">
                  <div class=" form-group row form-inline">
                    <label>No.Tractor:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_no_tracto" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{tracto.no_cdc}}">
                    </div>
                    <label>No.Tmc: </label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_no_tmc" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{tracto.no_tmc}}">
                    </div>
                  </div>
                  <div class="form-group row form-inline ">
                    <label>Linea Transportista:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_linea_trans" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{tracto.nombre}}">
                    </div>
                  </div>
                  <div class=" form-group row form-inline ">
                    <label>Año:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_year" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{tracto.year_trac}}">
                    </div>
                    <label>Modelo: </label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_mod" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{tracto.modelo}}">
                    </div>
                  </div>
                  <div class=" form-group row form-inline">
                    <label>No.Motor:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_no_motor" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{tracto.no_motor}}">
                    </div>
                    <label>Tarjeta IAVE:</label>
                    <div class="input-group input-group-sm col-sm-4">
                      <input id="txt_iave" type="text" class="form-control input-frio-caja" placeholder="activo"
                        aria-label="Username" aria-describedby="basic-addon1" autocomplete="off"
                        value="{{tracto.no_tiave}}">
                    </div>
                  </div>
                  <div class="form-group row form-inline">
                    <label>Placas:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_placa" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{tracto.placas}}">
                    </div>
                    <label>Tarjeta Zacatecas:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_tarjeta_zac" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{tracto.televia}}">
                    </div>
                  </div>
                  <div class="form-group row form-inline">
                    <label>Mes/Año:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_mes_ano" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{tracto.anio_verificacion}}">
                    </div>
                    <label>Verificación:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_verif" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{tracto.verificacion}}">
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class=" form-group row form-inline">
                    <label>Status:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_status" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{tracto.status}}">
                    </div>
                    <label>Marca:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_marca" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{tracto.marca}}">
                    </div>
                  </div>
                  <div class=" form-group row form-inline">
                    <label>No.Serie:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_serie" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off" value="{{tracto.no_serie}}">
                    </div>
                  </div>
                  <div class="form-group text-center">
                    <label>COMENTARIOS</label>
                    <div class="input-group align-self-end">
                      <textarea id="txt_coment" class="form-control input-frio-caja" rows="5"
                        value="{{tracto.comentarios}}"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="fade modal-tablero" id="mdl_info_clave" tabindex="-1" role="dialog" aria-labelledby="#mdl_info_tracto"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-xl modal-dialog-centerd" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary d-block">
        <button type="button" class="close color-white float-right" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title color-white text-center">CLAVE</h5>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <form>
            <fieldset disabled>
              <div class="row justify-content-start">
                <div class="col-6 align-self-start">
                  <div class="form-group row form-inline">
                    <label>Nombre:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_nombre" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off">
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="form-inline">
                      <label>Clave:</label>
                      <div class="input-group input-group-sm col-sm">
                        <input id="txt_clave" type="text" class="form-control input-frio-caja" aria-label="Username"
                          aria-describedby="basic-addon1" autocomplete="off">
                      </div>
                      <label>No.Cliente:</label>
                      <div class="input-group input-group-sm col-sm">
                        <input id="txt_no_client" type="text" class="form-control input-frio-caja" aria-label="Username"
                          aria-describedby="basic-addon1" autocomplete="off">
                      </div>
                      <label>Status:</label>
                      <div class="input-group input-group-sm col-sm">
                        <input id="txt_status" type="text" class="form-control input-frio-caja" aria-label="Username"
                          aria-describedby="basic-addon1" autocomplete="off">
                      </div>
                    </div>
                  </div>
                  <div class="form-group row form-inline">
                    <label>Calle y No.:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_calle_no" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off">
                    </div>
                  </div>
                  <div class="form-group row form-inline">
                    <label>Colonia:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_col" type="text" class="form-control input-frio-caja" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off">
                    </div>
                  </div>
                  <div class="form-group row justify-content-between">
                    <div class="form-inline">
                      <label>Ciudad:</label>
                      <div class="input-group input-group-sm col-sm">
                        <input id="txt_cid" type="text" class="form-control input-frio-caja" aria-label="Username"
                          aria-describedby="basic-addon1" autocomplete="off">
                      </div>
                      <div class="input-group input-group-sm col-sm-2"></div>
                      <label>Estado:</label>
                      <div class="input-group input-group-sm col-sm">
                        <input id="txt_edo" type="text" class="form-control input-frio-caja" aria-label="Username"
                          aria-describedby="basic-addon1" autocomplete="off">
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="form-inline">
                      <label>C.P.:</label>
                      <div class="input-group input-group-sm col-sm">
                        <input id="txt_cp" type="text" class="form-control input-frio-caja" aria-label="Username"
                          aria-describedby="basic-addon1" autocomplete="off">
                      </div>
                      <label>RFC:</label>
                      <div class="input-group input-group-sm col-sm">
                        <input id="txt_rfc" type="text" class="form-control input-frio-caja" aria-label="Username"
                          aria-describedby="basic-addon1" autocomplete="off">
                      </div>
                      <label>Pago de Seguro:</label>
                      <div class="input-group input-group-sm col-sm">
                        <input id="txt_pago_seg" type="text" class="form-control input-frio-caja" aria-label="Username"
                          aria-describedby="basic-addon1" autocomplete="off">
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="form-inline">
                      <label>Tel. Directo:</label>
                      <div class="input-group input-group-sm col-sm">
                        <input id="txt_tel_dir" type="text" class="form-control input-frio-caja" aria-label="Username"
                          aria-describedby="basic-addon1" autocomplete="off">
                      </div>
                      <label>Tel.Fax:</label>
                      <div class="input-group input-group-sm col-sm">
                        <input id="txt_tel_fax" type="text" class="form-control input-frio-caja" aria-label="Username"
                          aria-describedby="basic-addon1" autocomplete="off">
                      </div>
                      <label>Tel.(Otro):</label>
                      <div class="input-group input-group-sm col-sm">
                        <input id="txt_tel_otro" type="text" class="form-control input-frio-caja" aria-label="Username"
                          aria-describedby="basic-addon1" autocomplete="off">
                      </div>
                    </div>
                  </div>
                  <div class="form-group row form-inline">
                    <label>Contacto:</label>
                    <div class="input-group input-group-sm col-sm">
                      <input id="txt_cont" type="text" class="form-control input-frio-caja col" aria-label="Username"
                        aria-describedby="basic-addon1" autocomplete="off">
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group row justify-content-around">
                    <div class="form-inline">
                      <label>Latitud:</label>
                      <div class="input-group input-group-sm col-sm">
                        <input id="txt_lat" type="text" class="form-control input-frio-caja" aria-label="Username"
                          aria-describedby="basic-addon1" autocomplete="off">
                      </div>
                      <label>Longitud:</label>
                      <div class="input-group input-group-sm col-sm">
                        <input id="txt_long" type="text" class="form-control input-frio-caja" aria-label="Username"
                          aria-describedby="basic-addon1" autocomplete="off">
                      </div>
                    </div>
                  </div>
                  <div class="form-group row justify-content-around">
                    <div class="form-inline">
                      <div class="input-group input-group-sm col-sm">
                        <button type="button" class="btn btn-light"><i class="fas fa-map-marker-alt"
                            style="color:#BF343F;"></i>
                          MAPA</button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group text-center">
                    <label>ESPECIFICACIONES</label>
                    <textarea id="txt_especif" class="form-control input-frio-caja" rows="4"></textarea>
                  </div>
                  <div class="form-group text-center">
                    <label>COMENTARIOS</label>
                    <textarea id="txt_comentarios_cve2" class="form-control input-frio-caja" rows="4"></textarea>
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!--MODAL DE HISTORICOS-->
<div class="fade modal-tablero" id="mdl_historicos" tabindex="-1" role="dialog" aria-labelledby="mdl_historicos"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary d-block">
        <button type="button" class="close color-white float-right" (click)="modalCerrar()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title color-white text-center">{{this.modalTitle}}</h5>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <div class="input-group col-4 mb-3 form-inline"
            *ngIf="tipoHistorico == 'TRACTOR' || tipoHistorico == 'CAJA' || tipoHistorico == 'OPERADOR' || tipoHistorico == 'CLIENTE'">
            <label style="margin-right: 5px;">Ultimos: </label>
            <select class="custom-select " id="inputGroupSelect02" [(ngModel)]="noDias">
              <option value="10">10 días</option>
              <option value="25">25 días</option>
              <option value="50">50 días</option>
              <option value="100">100 días</option>
            </select>
            <div class="input-group-prepend">
              <button class="btn btn-outline-secondary" type="button"
                (click)="consultaDiasHistorico()">CONSULTAR</button>
            </div>
          </div>
          <div *ngIf="tipoHistorico == 'ORIGEN' || tipoHistorico == 'DESTINO' || tipoHistorico == 'RANGO_FECHAS'">
            <button type="button" class="btn btn-primary float-right" (click)="consultarHistorico()">consultar</button>
            <div class="form-inline">
              <label>FECHA INICIAL: </label>
              <div class="input-group input-group-sm col-4">
                <input [(ngModel)]="fecha_ini" id="fecha_ini" type="date" class="form-control" placeholder="DD-MM-YYYY"
                  aria-label="Username" aria-describedby="basic-addon1" autocomplete="off">
              </div>
              <label>FECHA FIN: </label>
              <div class="input-group input-group-sm col-4">
                <input [(ngModel)]="fecha_fin" id="fecha_fin" type="date" class="form-control" placeholder="DD-MM-YYYY"
                  aria-label="Username" aria-describedby="basic-addon1" autocomplete="off">
              </div>
            </div>
            <br>
          </div>
          <div class="col-12 mt-5">
            <div class="table-responsive" *ngIf="tablaHistoricos">
              <div [ngStyle]="style">
                <ag-grid-angular #agGrid_historicos class="ag-theme-alpine historicos"
                  [columnDefs]="columnDefsHistorico" [defaultColDef]="defaultColDef" [rowData]="rowDataHistorico"
                  (gridReady)="onGridReady($event)" (cellDoubleClicked)="onCellDoubleClicked($event)">
                </ag-grid-angular>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Modal Alta incidencia-->
<app-form-incidencia [informacionReporteIncidencia]="informacionReporteIncidencia" [accion]="accionIncidencia" *ngIf="mostrarFormularioIncidencia == true" (ocultarModal)="mostrarFormularioIncidencia = false;" [modalTitle]="modalTitle" ></app-form-incidencia>
<!-- <div class="fade modal-tablero" id="mdl_alta_sancion" tabindex="-1" role="dialog" aria-labelledby="mdl_info_caja"
  aria-hidden="true" data-backdrop="static" style="z-index: 10000;">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document" style="width: 100%;">
    <div class="modal-content">
      <div class="modal-header bg-primary d-block">
        <button type="button" class="close color-white float-right" data-dismiss="modal" (click)="cerrarModal()"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title color-white text-center" id="mdl_info_cajas">{{this.modalTitle}}</h5>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <form [formGroup]="formularioSancion">
            <div class="row">
              <div class="col-6">
                <div class="form-group row form-inline" *ngIf="this.modalTitle == 'ALTA DE INCIDENCIA'">
                  <label>INCIDENCIA:</label>
                  <div class="input-group input-group-sm col-sm">
                    <select class="custom-select form-control-sm" id="motivo_sancion" formControlName="motivo_sancion"
                      name="motivo_sancion" (change)="obtenerIdSancion()">
                      <option>SELECCIONAR SANCIÓN</option>
                      <option *ngFor="let sanciones of lista_sanciones">{{sanciones.nombre}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group row form-inline">
                  <label>VIAJE:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_viaje" type="text" class="form-control form-control-sm" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" formControlName="no_pedido" name="no_pedido"
                      [attr.disabled]="true">
                  </div>
                </div>
                <div class="form-group row form-inline">
                  <label>UNIDAD:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_unidad" type="text" class="form-control form-control-sm" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" formControlName="no_cdc" name="no_cdc"
                      [attr.disabled]="true">
                  </div>
                  <label>No.OPERADOR:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_no_oper" type="text" class="form-control form-control-sm" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" formControlName="no_oper" name="no_oper"
                      [attr.disabled]="true">
                  </div>
                </div>
                <div class="form-group row form-inline">
                  <label>OPERADOR:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_linea_trans" type="text" class="form-control form-control-sm" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" formControlName="nombre_operador" name="nombre_operador"
                      [attr.disabled]="true">
                  </div>
                </div>
                <div class="form-group row form-inline">
                  <label>FLOTA:</label>
                  <div class="input-group input-group-sm col-sm">
                    <input id="txt_nombre_flota" type="text" class="form-control form-control-sm" aria-label="Username"
                      aria-describedby="basic-addon1" autocomplete="off" formControlName="nombre_flota" name="nombre_flota"
                      [attr.disabled]="true">
                  </div>
                </div>
                <div class="form-group row form-inline" *ngIf="this.modalTitle == 'ALTA DE INCIDENCIA'">
                  <label class="label-alerta">NOTIFICAR A OTRA AREA</label>
                  <div class="input-group col-sm-4 input-group-sm">
                    <select class="custom-select" formControlName="notificar_area" name="notificar_area">
                      <option value="SI">SI</option>
                      <option value="NO">NO</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group text-center">
                  <label>REPORTE: </label>
                  <span class="text-danger animated fadeIn" *ngIf="this.formularioSancion.value.reporte == ''"><br>Campo obligatorio</span>
                  <textarea id="txt_reporte" class="form-control" rows="4" formControlName="reporte" name="reporte"></textarea>
                </div>
                <div class="row" *ngIf="this.modalTitle == 'ALTA DE INCIDENCIA' && this.formularioSancion.value.notificar_area == 'SI'">
                  <label class="label-alerta">NOTIFICAR A: </label>
                </div>
                <div class="row" *ngIf="this.formularioSancion.value.notificar_area == 'SI'">
                  <div class="col-8 row">
                    <div class="form-group form-check-inline">
                      <input type="checkbox" class="form-check-input" id="ch_atenc_oper" (change)="asignarCorreos()">
                      <label class="form-check-label" for="ch_atenc_oper">OPERACION
                        REFRIGERADO</label>
                    </div>
                    <div class="form-group form-check-inline">
                      <input type="checkbox" class="form-check-input" id="ch_atenc_oper_seco"
                        (change)="asignarCorreos()">
                      <label class="form-check-label" for="ch_atenc_oper_seco">OPERACION
                        SECO</label>
                    </div>
                    <div class="form-group form-check-inline">
                      <input type="checkbox" class="form-check-input" id="ch_atenc_clientes"
                        (change)="asignarCorreos()">
                      <label class="form-check-label" for="ch_atenc_clientes">ATENCION A
                        CLIENTES</label>
                    </div>
                  </div>
                  <div class="col-4 row">
                    <div class="form-group form-check-inline">
                      <input type="checkbox" class="form-check-input" id="ch_mantenimiento" (change)="asignarCorreos()">
                      <label class="form-check-label" for="ch_mantenimiento">MANTENIMIENTO</label>
                    </div>
                    <div class="form-group form-check-inline">
                      <input type="checkbox" class="form-check-input" id="ch_rh" (change)="asignarCorreos()">
                      <label class="form-check-label" for="ch_rh">RH</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer" *ngIf="this.modalTitle == 'ALTA DE INCIDENCIA'">
              <button type="button" class="btn btn-danger" data-dismiss="modal"
                (click)="cerrarModal()">CANCELAR</button>
              <a *ngIf="this.correoDestino != ''" href="mailto:{{this.correoSancion}}" target="_blank">
                <button type="button" class="btn btn-primary" (click)="saveReporteSancion()"
                [disabled]="!formularioSancion.valid">GUARDAR</button></a>
              <a *ngIf="this.correoDestino == ''" href="mailto:{{this.correoSancion}}" target="_blank">
                <button type="button" class="btn btn-primary" (click)="saveReporteSancion()"
                [disabled]="!formularioSancion.valid">GUARDAR</button></a>
            </div>
            <div class="modal-footer" *ngIf="this.modalTitle != 'ALTA DE INCIDENCIA'">
              <button type="button" class="btn btn-danger" data-dismiss="modal"
                (click)="cerrarModal()">CANCELAR</button>
              <a href="mailto:{{this.notificacion}}" target="_blank">
                <button type="button" class="btn btn-primary" (click)="saveReporteSancion()"
                  [disabled]="!formularioSancion.valid">GUARDAR</button></a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div> -->