import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/service/login/login.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertaService {

  constructor(private httpApi: HttpClient, private apiAuth: LoginService) { }

  getAlert():Observable<any>{
    return this.httpApi.get<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/alertas`)
  }

  updateAlert(body:any):Observable<any>{
    return this.httpApi.put<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/alertas`,body)
  }

  getCorreosAlertas():Observable<any>{
    return this.httpApi.get<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/correosAlertas`)
  }

  getAlertasAtendidas(fechaInicial, fechaFinal):Observable<any>{
    let httpParams = new HttpParams()
    .append('fecha_inicio', fechaInicial)
    .append('fecha_fin', fechaFinal)
    return this.httpApi.get<any>(`${environment.URL_SERVIDOR}/api-interna/tablero-monitoreo/obtenerAlertasAtendidas?${httpParams}`)
  }
  
}
